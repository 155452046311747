import React, { Component } from "react";

import { Modal, Form, Input, InputNumber, Button, Upload, Icon } from "antd";

import { httpUrl, imageType, serverUrl } from "../../api/httpClient";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import {
  showCreateConfirm,
  showUpdateCloseRefreshConfirm
} from "../common/AlertInfoModal";

import BasicImage from "../common/BasicImage";
import BannerRefSearchModal from "./BannerRefSearchModal";

const FormItem = Form.Item;
const BannerModal = Form.create()(
  class extends Component {
    state = {
      image: "",

      refFlag: false,
      refInfo: ""
    };

    // 정렬 대상 설정
    typeHandleChange = (value) => {
      const { form } = this.props;
      if (value !== form.getFieldsValue().type) {
        this.setState({ refInfo: "" });
        form.setFieldsValue({ refIdx: null });
      }
    };

    onOk = (e) => {
      const { form, edit, bannerInfo } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          let data = values;
          if (values.rank === undefined) data = { ...data, rank: 100 };
          if (values.memo === undefined) data = { ...data, memo: "" };
          // console.log(`values= ${JSON.stringify(data, null, 4)}`);

          if (!edit) {
            showCreateConfirm(
              "배너를 생성하시겠습니까?",
              httpUrl.bannerCreate,
              data,
              this.props.onOk,
              this.props.onCancel
            );
          } else {
            data = { ...data, idx: bannerInfo.idx };
            showUpdateCloseRefreshConfirm(
              "배너를 수정하시겠습니까?",
              httpUrl.bannerUpdate,
              data,
              this.props.onOk,
              this.props.onCancel
            );
          }
        }
      });
    };

    componentDidMount() {
      const { edit, bannerInfo, form } = this.props;

      if (edit) {
        this.setState({ refInfo: { title: bannerInfo.title } });
        form.setFieldsValue({
          ...bannerInfo
        });
      }
    }

    render() {
      const { visible, edit, onCancel, form } = this.props;
      const { getFieldDecorator } = this.props.form;
      const { refFlag, refInfo } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const uploadProps = {
        action: serverUrl + httpUrl.imageUpload + false,
        multiple: false,
        withCredentials: true,
        showUploadList: false,
        listType: "picture-card",
        beforeUpload: (file, fileList) => {
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 형식을 확인해주세요.</span>
            });
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            form.setFieldsValue({ photo: file.data.fileId });
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <Modal
          style={{ minWidth: "800px" }}
          visible={visible}
          title={edit ? "배너 상세" : "배너 생성"}
          okText={edit ? stringTitle.modify : stringTitle.create}
          onOk={this.onOk}
          cancelText={stringTitle.cancel}
          onCancel={onCancel}
        >
          {refFlag && (
            <BannerRefSearchModal
              visible={refFlag}
              type={form.getFieldsValue().type}
              onOk={(refInfo) => {
                // console.log(`refInfo - ${JSON.stringify(refInfo, null, 4)}`);
                form.setFieldsValue({ refIdx: refInfo.idx });
                this.setState({ refInfo });
              }}
              onCancel={() => {
                this.setState({ refFlag: false });
              }}
            />
          )}
          <Form {...formItemLayout} onSubmit={this.onOk}>
            <FormItem label={<span>{stringTitle.type}&nbsp;</span>}>
              {getFieldDecorator("type", {
                rules: [
                  {
                    required: false,
                    message: "url을 입력해주세요!"
                  }
                ]
              })(
                <SelectBox
                  disabled={edit ? true : false}
                  style={{ width: 200 }}
                  placeholder="선택해주세요"
                  code={string.bannerTypeString}
                  codeString={string.bannerTypeString}
                  onChange={this.typeHandleChange}
                />
              )}
            </FormItem>
            {(form.getFieldsValue().type === "GOODS" ||
              form.getFieldsValue().type === "PLATFORM_SERVICE") && (
              <FormItem label={<span>{stringTitle.search}&nbsp;</span>}>
                {getFieldDecorator("refIdx", {
                  rules: [
                    {
                      required: false,
                      message: "url을 입력해주세요!"
                    }
                  ]
                })(
                  <div>
                    {refInfo !== "" && refInfo.title}
                    {!edit && (
                      <Button
                        style={{ marginLeft: refInfo !== "" ? 10 : 0 }}
                        onClick={() => {
                          this.setState({ refFlag: true });
                        }}
                      >
                        {refInfo !== ""
                          ? stringTitle.modify
                          : stringTitle.search}
                      </Button>
                    )}
                  </div>
                )}
              </FormItem>
            )}
            <FormItem label={<span>위치&nbsp;</span>}>
              {getFieldDecorator("position", {
                rules: [
                  {
                    required: true,
                    message: "위치를 입력해주세요!"
                  }
                ]
              })(
                <InputNumber
                  placeholder="0"
                  min={1}
                  style={{ width: "200px" }}
                />
              )}
              <div>
                <span className="m-l-10" style={{ color: "blue" }}>
                  1 - 메인
                </span>
              </div>
            </FormItem>
            <FormItem label={<span>{stringTitle.image}&nbsp;</span>}>
              {getFieldDecorator("photo", {
                rules: [
                  {
                    required: true,
                    message: "이미지를 추가하세요."
                  }
                ]
              })(
                <Upload {...uploadProps}>
                  {form.getFieldsValue().photo ? (
                    <BasicImage
                      image={form.getFieldsValue().photo}
                      src={
                        serverUrl +
                        httpUrl.getImage +
                        form.getFieldsValue().photo
                      }
                      style={{ width: "150px", height: "150px" }}
                    />
                  ) : (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">{stringTitle.add}</div>
                    </div>
                  )}
                </Upload>
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.memo}&nbsp;</span>}>
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: false,
                    message: "메모를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="https://www.google.kr" />)}
            </FormItem>

            <FormItem label={<span>{stringTitle.rank}&nbsp;</span>}>
              {getFieldDecorator("rank", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <InputNumber
                  style={{ width: "380px" }}
                  // value={selectData.price}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default BannerModal;
