import React, { Component } from "react";

import { Button, Input, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { checkData, formatDate, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { showDeletePageNumConfirm } from "../../components/common/AlertInfoModal";

class GoodsKeyword extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    type: "WORD",
    orderBy: "DESC",
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getGoodsKeywordList({ pageNum: pager.current });
  };

  // 정렬 순서 설정
  typeHandleChange = (value) => {
    this.setState({
      type: value
    });
  };

  // 정렬 순서 설정
  orderByHandleChange = (value) => {
    this.setState({
      orderBy: value
    });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 삭제
  onDelete = (row) => {
    const { post, deleted, pagination, list } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && !post && row.deleted === 0
        ? list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    showDeletePageNumConfirm(
      `${row.word}을 ${
        row.deleted ? stringTitle.restore : stringTitle.delete
      }하시겠습니까?`,
      httpUrl.keywordDelete,
      row,
      this.getGoodsKeywordList,
      pageNum
    );
  };

  getGoodsKeywordList = (params = {}) => {
    const { type, orderBy, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getGoodsKeywordList,
      [10, pageNum, type, orderBy, searchText],
      {}
    )
      .then((res) => {
        // console.log(`keyword data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getGoodsKeywordList();
  }

  render() {
    const { type, orderBy, list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.updateDate,
        dataIndex: "updateDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.word,
        dataIndex: "word",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: stringTitle.cnt,
        dataIndex: "cnt",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: stringTitle.deleted,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== row.deleted) {
                this.onDelete(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <div>
        <div className="title">검색 키워드 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            <SelectBox
              style={{ width: 150 }}
              value={string.keywordString[type]}
              code={string.keywordCode}
              codeString={string.keywordString}
              onChange={this.typeHandleChange}
            />
          </label>
          <label className="m-r-10">
            <SelectBox
              style={{ width: 150 }}
              value={string.orderByTypeString[orderBy]}
              code={string.orderByTypeCode}
              codeString={string.orderByTypeString}
              onChange={this.orderByHandleChange}
            />
          </label>
          <Input
            allowClear
            placeholder="검색어를 입력하세요."
            style={{ marginRight: 10, width: 200 }}
            onChange={(e) => {
              this.searchTextHandelChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getGoodsKeywordList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default GoodsKeyword;
