import React, { Component } from "react";

import { Table, DatePicker, Button } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl } from "../../api/httpClient";

import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const { RangePicker } = DatePicker;
class AdminLoginLog extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 1
    },
    startDate: "",
    endDate: "",
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getAdminLoginLog({ pageNum: pager.current });
    }
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  getAdminLoginLog = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getAdminLoginLog,
      [10, pageNum, this.state.startDate, this.state.endDate],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getAdminLoginLog();
  }

  render() {
    const statisticsColumns = [
      {
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.id,
        dataIndex: "adminId",
        className: "text-center"
      },
      {
        title: "성공여부",
        dataIndex: "result",
        className: "text-center",
        render: (data) => <div>{string.loginString[data]}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">관리자 로그인 이력</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
          <label>{stringTitle.searchDate}&nbsp;</label>

          <RangePicker
            locale={locale}
            allowClear={true}
            onChange={(date) => {
              this.handleChangeDate(date);
            }}
          />
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => {
              this.getAdminLoginLog();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          columns={statisticsColumns}
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default AdminLoginLog;
