import React, { Component } from "react";

import { Table, Button } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";

import _const from "../../const";
import stringTitle from "../../stringTitle";
import SelectCategoryList from "../../components/goods/SelectCategoryList";
import CategoryModal from "../../components/goods/CategoryModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showDeleteIdxConfirm } from "../../components/common/AlertInfoModal";

class GoodsCategoryList extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },

    clickFlag: false,
    visible: false
  };

  // 삭제
  onDelete = (params) => {
    let idx = params.idx;
    // console.log(`idx = ${JSON.stringify(idx, null, 4)}`);
    showDeleteIdxConfirm(
      stringTitle.deleteQuest,
      httpUrl.categoryDelete,
      idx,
      this.getCategoryParentIdxList
    );
  };

  // 리스트
  getCategoryParentIdxList = (params = {}) => {
    httpGet(httpUrl.getCategoryParentIdxList, [0], {})
      .then((res) => {
        // console.log(`categoryList = ${JSON.stringify(res, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getCategoryParentIdxList();
  }

  render() {
    const { clickFlag, visible, type, selectIdx, selectData, list } =
      this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.rank,
        dataIndex: "rank",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.categoryName,
        dataIndex: "categoryName",
        className: "text-center"
      },
      _const.subCategoryFlag
        ? {
            title: stringTitle.categoryDepth,
            dataIndex: "child",
            className: "text-center",
            render: (data, row) => (
              <Button
                // disabled={data.length === 0 ? true : false}
                onClick={() => {
                  this.setState({
                    clickFlag: true,
                    selectIdx: row.idx
                  });
                }}
              >
                {stringTitle.viewDetail}
              </Button>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      {
        title: "",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  type: "update",
                  visible: true,
                  selectData: row
                });
              }}
            >
              {stringTitle.update}
            </Button>
            <Button
              onClick={() => {
                this.onDelete({ idx: row.idx });
              }}
            >
              {stringTitle.delete}
            </Button>
          </div>
        )
      }
    ];

    return (
      <div>
        {clickFlag && (
          <SelectCategoryList
            visible={clickFlag}
            parentIdx={selectIdx}
            refresh={this.getCategoryParentIdxList}
            onCancel={() => {
              this.setState({ clickFlag: false });
            }}
          />
        )}
        {visible && (
          <CategoryModal
            type={type}
            visible={visible}
            selectData={selectData}
            refresh={this.getCategoryParentIdxList}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        <div className="title">상품 카테고리 관리</div>
        <Button
          onClick={() => {
            this.setState({ type: "create", visible: true });
          }}
        >
          {stringTitle.create}
        </Button>
        <div style={{ clear: "both", marginBottom: 10 }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default GoodsCategoryList;
