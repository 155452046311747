import React, { Component } from "react";

import { Select } from "antd";

const Option = Select.Option;
export default class CategorySelectBox extends Component {
  state = {};

  renderOption = () => {
    const { code } = this.props;

    let result = [];

    for (let i = 0; i < code.length; i++) {
      result.push(
        <Option value={code[i].idx} key={`${code[i]}${i}`}>
          {code[i].categoryName}
        </Option>
      );
    }

    return result;
  };

  render() {
    const { size, mode, style, placeholder, value, onChange, type } =
      this.props;
    return (
      <Select
        {...this.props}
        size={size}
        mode={mode}
        style={{ width: "120px", ...style }}
        placeholder={placeholder}
        value={value}
        onChange={(value) => {
          onChange(value, type);
        }}
      >
        {this.renderOption()}
      </Select>
    );
  }
}
