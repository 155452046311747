import React, { Component } from "react";

import { Button, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showDeleteConfirm } from "../common/AlertInfoModal";

import SelectBox from "../common/SelectBox";
import GoodsOptionModal from "./GoodsOptionModal";

class GoodsOption extends Component {
  state = {
    list: [],

    selectData: "",
    selectedRow: ""
  };

  onDelete = (row) => {
    showDeleteConfirm(
      `${row.title.content}을 ${
        row.deleted ? stringTitle.restore : stringTitle.delete
      }하시겠습니까?`,
      httpUrl.goodsOptionDelete,
      row,
      this.getGoodsOptionList
    );
  };

  getGoodsOptionList = (params = {}) => {
    httpGet(httpUrl.getGoodsOptionList, [this.props.idx], {})
      .then((res) => {
        // console.log(`optionList = ${JSON.stringify(res, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getGoodsOptionList();
  }

  render() {
    const { visible, onCancel, paymentInfo } = this.props;
    const { optionFlag, selectData, type, list } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.rank,
        dataIndex: "rank",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.optionName,
        dataIndex: "title",
        className: "text-center",
        render: (data) => <div>{data.content}</div>
      },
      {
        title: () => (
          <div>
            <div>옵션 추가판매가</div>
            <div>
              ({stringTitle.price} + {stringTitle.point})
            </div>
          </div>
        ),
        dataIndex: "payment",
        className: "text-center",
        render: (data, row) => (
          <div>
            <b>{numberFormat(row.price + row.point)}</b>
            <div>
              {stringTitle.price}&nbsp;:&nbsp;{numberFormat(row.price)}
              &nbsp;/&nbsp;{stringTitle.point}&nbsp;:&nbsp;
              {numberFormat(row.point)}
            </div>
          </div>
        )
      },
      {
        title: stringTitle.stock,
        dataIndex: "quantity",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.update,
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                type: "edit",
                optionFlag: true,
                selectData: { ...row, title_content: row.title.content }
              });
            }}
          >
            {stringTitle.updateFlag}
          </Button>
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== data) {
                this.onDelete(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="옵션 상세보기"
        footer={false}
        style={{ minWidth: "1000px" }}
        maskClosable={false}
      >
        {/* 옵션 추가 수정 */}
        {optionFlag && (
          <GoodsOptionModal
            type={type}
            visible={optionFlag}
            selectData={selectData}
            paymentInfo={paymentInfo}
            refIdx={this.props.idx}
            refresh={this.getGoodsOptionList}
            onCancel={() => {
              this.setState({ optionFlag: false });
            }}
          />
        )}
        <div style={{ marginBottom: 10 }}>
          <Button
            onClick={() => {
              this.setState({ optionFlag: true, type: "add", selectData: {} });
            }}
          >
            {stringTitle.add}
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default GoodsOption;
