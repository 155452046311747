import React, { Component } from "react";
import CategorySelectBox from "../common/CategorySelectBox";

class GoodsCategorySelect extends Component {
  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const {
      topCategory,
      topCategoryList,
      subCategory,
      subCategoryList,
      lastCategory,
      lastCategoryList,
      onChange
    } = this.props;
    return (
      <div>
        <CategorySelectBox
          type="top"
          code={topCategoryList}
          value={topCategory}
          style={{ width: "150px", marginRight: 5 }}
          onChange={(value) => {
            onChange(value, "top");
          }}
        />
        {subCategoryList.length > 1 && (
          <CategorySelectBox
            type="sub"
            code={subCategoryList}
            value={subCategory}
            style={{ width: "150px", marginRight: 5 }}
            onChange={(value) => {
              onChange(value, "sub");
            }}
          />
        )}
        {lastCategoryList.length > 1 && (
          <CategorySelectBox
            type="last"
            code={lastCategoryList}
            value={lastCategory}
            style={{ width: "150px", marginRight: 10 }}
            onChange={(value) => {
              onChange(value, "last");
            }}
          />
        )}
      </div>
    );
  }
}

export default GoodsCategorySelect;
