import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  amountDivideFormat,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import OrderTtitle from "../../components/order/OrderTtitle";

const { RangePicker } = DatePicker;
class PointPurchase extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let pointList = reactLocalStorage.getObject("pointList");
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      pointType: pointList[0].pointType,
      type: "ALL",
      searchText: ""
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getPointPurchaseList({
      pageNum: pager.current
    });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 포인트 타입 검색
  handleChangePointType = (value) => {
    this.setState({
      pointType: value
    });
  };

  // 타입 검색
  onHandleChangeType = (value) => {
    this.setState({
      type: value
    });
  };

  getPointPurchaseList = (params = {}) => {
    const { startDate, endDate, pointType, type, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPointPurchaseList,
      [10, pageNum, startDate, endDate, pointType, type, searchText],
      {}
    )
      .then((res) => {
        // console.log(`point purchase = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getPointPurchaseList();
  }

  render() {
    const { startDate, endDate, type, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      // {
      //   key: "refIdx",
      //   title: stringTitle.idx,
      //   dataIndex: "refIdx",
      //   className: "text-center",
      //   render: (data) => <div>{numberFormat(data)}</div>
      // },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.id,
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data} ({row.name})
          </div>
        )
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data, row) => (
          <OrderTtitle
            titleData={row}
            onClick={() => {
              this.setState({ visible: true, selectedData: row });
            }}
          />
        )
      },
      {
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => string.pointPurchageTypeString[data]
      },
      {
        title: stringTitle.point,
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      }
    ];

    return (
      <div>
        <div className="title">포인트 사용 내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            {stringTitle.searchDate}&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={true}
            />
          </label>
          {/* <label className="m-r-10">
            {stringTitle.point}&nbsp;
            <PointSelectBox
              pointList={pointList}
              value={pointType}
              onChange={this.handleChangePointType}
            />
          </label> */}
          <label className="m-r-10">
            {stringTitle.type}&nbsp;
            <SelectBox
              style={{ width: "150px" }}
              code={string.pointPurchageType}
              value={string.pointPurchageTypeSearch[type]}
              codeString={string.pointPurchageTypeSearch}
              onChange={this.onHandleChangeType}
            />
          </label>
          <Input
            style={{ width: "250px", marginRight: 10 }}
            placeholder="상품명"
            allowClear
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.getPointPurchaseList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.refIdx + record.type}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PointPurchase;
