import { Modal } from "antd";

import { httpPost, httpPut } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import {
  showAlert,
  showPageNumAlert,
  showIdxAlert,
  showCloseAlert,
  showCloseRefreshAlert,
  showLoginAlert,
  showAdminAlert
} from "./AlertModal";
import { ShowErrorAlert } from "./ErrorAlertModal";

const { confirm } = Modal;
// 생성 - create
export function showCreateConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.create,
    onOk() {
      httpPost(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseAlert("create", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showRefreshConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.create,
    onOk() {
      httpPost(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseRefreshAlert("create", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

// 수정 - update
export function showUpdateConfirm(title, url, data, refresh) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.update,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          if (res.data.result === "SUCCESS") {
            showAlert("update", refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showUpdateIdxConfirm(title, url, data, refresh, idx) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.update,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`;
          if (res.data.result === "SUCCESS") {
            showIdxAlert("update", refresh, idx);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showUpdateCloseRefreshConfirm(
  title,
  url,
  data,
  refresh,
  close
) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.update,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseRefreshAlert("update", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showModifyConfirm(title, url, data, refresh) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.modify,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`;
          if (res.data.result === "SUCCESS") {
            showAlert("modify", refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showUpdateRefreshConfirm(title, url, data, refresh) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            data.status === "ACCEPT"
              ? showPageNumAlert("accept", refresh)
              : showPageNumAlert("reject", refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

// add - 추가
export function showAddConfirm(title, url, data, type, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: type === "add" ? stringTitle.add : stringTitle.update,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            if (type === "add") showCloseAlert("add", refresh, close);
            if (type === "edit") showCloseAlert("update", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showAddPostConfirm(title, url, data, type, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: type === "add" ? stringTitle.add : stringTitle.update,
    onOk() {
      httpPost(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            if (type === "add") showCloseAlert("add", refresh, close);
            if (type === "edit") showCloseAlert("update", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

// delete - 삭제
export function showDeleteConfirm(title, url, data, refresh) {
  confirm({
    width: 500,
    title: title,
    okText: data.deleted ? stringTitle.restore : stringTitle.delete,
    onOk() {
      httpPut(url, [data.idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            data.deleted
              ? showAlert("restore", refresh)
              : showAlert("delete", refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showDeletePageNumConfirm(title, url, data, refresh, pageNum) {
  confirm({
    width: 500,
    title: title,
    okText: data.deleted ? stringTitle.restore : stringTitle.delete,
    onOk() {
      httpPut(url, [data.idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            data.deleted
              ? showPageNumAlert("restore", refresh, pageNum)
              : showPageNumAlert("delete", refresh, pageNum);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showDeleteIdxConfirm(title, url, data, refresh, pageNum) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.delete,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showPageNumAlert("delete", refresh, pageNum);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showDeleteIdxPageNumConfirm(title, url, idx, refresh, pageNum) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showPageNumAlert("delete", refresh, pageNum);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

// post
export function showPostConfirm(title, url, data, refresh) {
  confirm({
    width: 500,
    title: title,
    okText: data.postFlag ? stringTitle.unPost : stringTitle.post,
    onOk() {
      httpPut(url, [data.idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            data.postFlag
              ? showAlert("unPost", refresh)
              : showAlert("post", refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

// delivery - 운송장
export function showDeliveryConfirm(
  title,
  url,
  data,
  orderData,
  refresh,
  close
) {
  confirm({
    width: 500,
    title: title,
    okText:
      orderData.logistics === null ? stringTitle.regist : stringTitle.modify,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            orderData.logistics === null
              ? showCloseRefreshAlert("regist", refresh, close)
              : showCloseRefreshAlert("modify", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
// 충전 - charge
export function showChagreConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.charge,
    onOk() {
      httpPost(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseAlert("charge", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

export function showOfferConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseAlert("offer", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
// 승격
export function showLevelConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseAlert("level", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
// 정산
export function showRewardConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseRefreshAlert("reward", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

export function LoginConfirm(title, url, data, refresh, state) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          if (res.data.result === "SUCCESS") {
            showLoginAlert("update", refresh, data, state);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

export function AdminConfirm(title, url, data, refresh, cancel, login) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showAdminAlert("update", refresh, cancel, login, data);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
