import React, { Component } from "react";

import { Table, Checkbox, Input } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat, formatDate, amountDivideFormat } from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import UserInfo from "../../components/user/UserInfo";
import UserDetail from "../../components/user/UserDetail";

const Search = Input.Search;
class MemberList extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    deleted: false,
    searchText: "",

    visible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getUserList({ pageNum: pager.current });
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getUserList();
      }
    );
  };

  // 회원 정보 리스트
  getUserList = (params = {}) => {
    const { deleted, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(httpUrl.getUserList, [10, pageNum, deleted, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    // const userInfo = reactLocalStorage.getObject("adminUser");
    // if (userInfo.authList) {
    // }
    this.getUserList();
  }

  render() {
    const { visible, userIdx, userData, list, pagination } = this.state;
    const columns = [
      {
        key: "user.idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            pointer="pointer"
            type="user"
            userData={row.user}
            onClick={() => {
              this.setState({
                userIdx: row.user.idx,
                userData: row,
                visible: true
              });
            }}
          />
        )
      },
      {
        title: stringTitle.recommender,
        dataIndex: "recommender.recommendCode",
        className: "text-center",
        render: (data, row) =>
          row.recommender.name === null ? (
            data
          ) : (
            <UserInfo userData={row.recommender} />
          )
      },
      {
        title: stringTitle.point,
        dataIndex: "pointBalance",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      },
      {
        title: stringTitle.serviceName,
        dataIndex: "service",
        className: "text-center",
        render: (data) =>
          data.map((item, index) => (
            <div key={index}>
              {item.name} : {item.count}
              <br />
            </div>
          ))
      },
      {
        title: stringTitle.manager,
        dataIndex: "manager.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.manager} />
      },
      {
        title: stringTitle.franchise,
        dataIndex: "franchise.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.franchise} />
      },
      {
        title: stringTitle.joinDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data) => string.deleteString[data]
      }
    ];

    return (
      <div className="flex-row flex-column">
        {/* 회원정보 */}
        {visible && (
          <UserDetail
            page="user"
            visible={visible}
            userIdx={userIdx}
            userData={userData}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            refresh={this.getUserList}
          />
        )}
        <div className="title">회원 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label htmlFor="" style={{ marginRight: "10px" }}>
            {stringTitle.searchRequire}&nbsp;&nbsp;&nbsp;
            <Checkbox onChange={this.deletedHandleChange}>
              {stringTitle.deletedInclude}
            </Checkbox>
          </label>
          <Search
            placeholder={`검색어를 입력해주세요.`}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 300 }}
          />
        </div>
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default MemberList;
