import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Button } from "antd";
import { login } from "../../actions/loginAction";
import { httpUrl, httpGet } from "../../api/httpClient";
import { numberFormat, formatDate, checkData } from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showDeleteIdxPageNumConfirm } from "../../components/common/AlertInfoModal";

import AdminDetail from "../../components/system/AdminDetail";
import AuthDetail from "./../../components/system/AuthDetail";
import AdminRegistryModal from "./../../components/system/AdminRegistryModal";
import SellerSearchModal from "../../components/common/SellerSearchModal";

const Search = Input.Search;
class Admin extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchText: "",

    createVisible: false,
    selectedRow: "",
    selectData: {},
    prevData: {},

    type: "",
    authVisible: false,
    authIdx: 0,
    authList: [],
    authCoinList: [],
    userInfo: reactLocalStorage.getObject("adminUser")
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getAdminList({ pageNum: pager.current });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => this.getAdminList()
    );
  };

  onDelete = (row) => {
    const { list, pagination, userInfo } = this.state;

    let pageNum =
      list.length === 1
        ? pagination.current === 1
          ? pagination.current
          : pagination.current - 1
        : pagination.current;

    if (row.idx === userInfo.idx) {
      alert("삭제가 불가합니다.");
    } else {
      showDeleteIdxPageNumConfirm(
        `${row.userId}을 ${stringTitle.deleteQuest}`,
        httpUrl.adminDelete,
        row.idx,
        this.getAdminList,
        pageNum
      );
    }
  };

  // 관리자 목록 조회
  getAdminList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    let searchText = this.state.searchText;

    httpGet(httpUrl.adminList, [10, pageNum, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getAdminList();
  }

  render() {
    const {
      createVisible,
      detailVisible,
      detailData,
      sellerVisible,
      adminIdx,
      authVisible,
      authIdx,
      userInfo,
      pagination,
      list
    } = this.state;

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: stringTitle.name,
        dataIndex: "name",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: stringTitle.id,
        dataIndex: "userId",
        className: "text-center",
        render: (data, row, index) => (
          <div
            className="pointer"
            onClick={() => {
              if (userInfo.superAdmin === 1 || row.idx === userInfo.idx)
                this.setState({ detailVisible: true, detailData: row });
              else alert("본인 또는 수퍼관리자 외 수정 할 수 없습니다");
            }}
          >
            {data}
          </div>
        )
      },
      {
        title: stringTitle.admin + stringTitle.class,
        dataIndex: "superAdmin",
        className: "text-center",
        render: (data) => (
          <div>{string.adminString[string.toggleCode[data]]}</div>
        )
      },
      {
        title: stringTitle.seller,
        dataIndex: "seller",
        className: "text-center",
        render: (data, row) =>
          row.superAdmin ? (
            "-"
          ) : (
            <Button
              onClick={() => {
                this.setState({ sellerVisible: true, adminIdx: row.idx });
              }}
            >
              {stringTitle.seller}
              {stringTitle.view}
            </Button>
          )
      },
      {
        title: stringTitle.auth,
        dataIndex: "auth",
        className: "text-center",
        render: (data, row, index) =>
          row.superAdmin ? (
            "-"
          ) : (
            <Button
              onClick={() => {
                this.setState({
                  authVisible: true,
                  authIdx: row.idx
                });
              }}
            >
              {stringTitle.auth}
              {stringTitle.view}
            </Button>
          )
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) =>
          row.superAdmin ? (
            "-"
          ) : (
            <Button
              onClick={(value) => {
                if (userInfo.superAdmin === 1 || row.idx === userInfo.idx)
                  this.onDelete(row);
                else alert("본인이외는 삭제 할 수 없습니다");
              }}
            >
              {stringTitle.deleteFlag}
            </Button>
          )
      }
    ];

    return (
      <div>
        <div className="title">사용자 {stringTitle.management}</div>
        <div className="wrapper mt-35 width-1240" />
        {/* 어드민 생성 */}
        {createVisible && (
          <AdminRegistryModal
            visible={createVisible}
            onCancel={() => {
              this.setState({
                createVisible: false
              });
            }}
            refresh={this.getAdminList}
          />
        )}
        {/* 어드민 상세 */}
        {detailVisible && (
          <AdminDetail
            visible={detailVisible}
            detailData={detailData}
            onCancel={() => {
              this.setState({ detailVisible: false, detailData: {} });
            }}
            onOk={() => {
              this.getAdminList({
                pageNum: pagination.current
              });
            }}
          />
        )}
        {/* 판매자 보기 */}
        {sellerVisible && (
          <SellerSearchModal
            page="admin"
            visible={sellerVisible}
            adminIdx={adminIdx}
            reverseFlag={false}
            onCancel={() => {
              this.setState({ sellerVisible: false });
            }}
          />
        )}
        {/* 권한보기 */}
        {authVisible && (
          <AuthDetail
            visible={authVisible}
            authIdx={authIdx}
            onCancel={() => {
              this.setState({
                authVisible: false
              });
            }}
          />
        )}
        {userInfo.superAdmin === 1 && (
          <div style={{ float: "left", marginBottom: "10px" }}>
            <Button
              onClick={() => {
                this.setState({ createVisible: true });
              }}
            >
              {stringTitle.create}
            </Button>
          </div>
        )}
        <label className="flex-row flex-center flex-end m-b-10">
          {stringTitle.searchRequire}&nbsp;&nbsp;
          <Search
            placeholder="이름을 입력하세요"
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "360px" }}
          />
        </label>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData,
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Admin);
