import { reactLocalStorage } from "reactjs-localstorage";
import BigNumber from "bignumber.js";

const checkData = (data) => {
  if (data === null || data === "") return "-";
  return data;
};

const checkLogisticsData = (data) => {
  const logisticsList = reactLocalStorage.getObject("logisticsList");

  if (data === null || data === "") return "-";
  if (logisticsList.find((item) => item.code === data))
    return logisticsList.find((item) => item.code === data).name;
};

const formatDateNumber = (num) => {
  num += "";
  return num.length < 2 ? "0" + num : num;
};

const bigNum = (Num) => {
  // console.log(`Num: ${Num}`);
  let num = new BigNumber(removeComma(Num));
  if (num.isNaN() || !num.isFinite()) {
    num = "0";
  } else {
    num = num.toFormat();
  }
  return num;
};

const removeComma = (str) => {
  if (str === undefined) {
    return "";
  }
  if (typeof str === "number") {
    // console.log(`str:${str} str Type: ${typeof str}`);
    str = str.toString();
  }
  return str.split(",").reduce((acc, item, index, array) => {
    return acc + item;
  }, "");
};

const numberFormat = (Num) => {
  if (Num === null || Num === 0) return 0;
  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = Num + "";
  // if (Num > 0) {
  // }
  while (reg.test(n)) n = n.replace(reg, `$1,$2`);

  return n;
};

const amountDivideFormat = (Num) => {
  if (Num === null || Num === 0) return 0;

  let n = Num;
  return n;
};

const amountMultiFormat = (Num) => {
  if (Num === null || Num === 0) return 0;

  let n = Num;
  return n;
};

const phoneFormat = (Num) => {
  if (Num === null || Num === 0) return 0;

  var n = Num + "";
  return n.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

const mobileFormat = (Num) => {
  if (Num === null || Num === 0) return 0;

  var n = Num + "";
  return n.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
};

const formatDate = (date) => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  );
};

const dayFormat = (date) => {
  if (!date) return null;
  // console.log(date);
  for (let i = 0; i < date.length; i++) {
    // console.log(date);
    let year = date.substr(0, 4);
    let month = date.substr(4, 2);
    let day = date.substr(6, 2);
    // return new Date(year, month, day);
    let time = year + "-" + month + "-" + day;
    return time;
  }
};

const dateFormat = (date) => {
  if (!date) return null;
  let hour = parseInt(date / 60);
  let minute = date % 60;
  return hour === 0
    ? minute + "분"
    : minute === 0
    ? hour + "시간"
    : hour + "시간 " + minute + "분";
};

const startMonthBeforeFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = newDate.getMonth();
  let day = newDate.getDate();

  if (month === 0) {
    month = 12;
    year = year - 1;
  }

  if (month === 2) if (day <= 29) day = 28;
  if (month === 4 || month === 6 || month === 9 || month === 11)
    if (day <= 31) day = 30;

  return year + "-" + month + "-" + day + " 00:00:00";
};

const startDateFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " 00:00:00"
  );
};

const endDateFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " 23:59:59"
  );
};

const statMonthBeforeFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = newDate.getMonth();
  let day = newDate.getDate();

  if (month === 0) {
    month = 12;
    year = year - 1;
  }

  if (month === 2) if (day <= 29) day = 28;
  if (month === 4 || month === 6 || month === 9 || month === 11)
    if (day <= 31) day = 30;

  return year + month + day;
};

const statFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    formatDateNumber(newDate.getMonth() + 1) +
    formatDateNumber(newDate.getDate())
  );
};

const excelFormat = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate())
  );
};

export {
  bigNum,
  removeComma,
  checkData,
  checkLogisticsData,
  numberFormat,
  amountDivideFormat,
  amountMultiFormat,
  phoneFormat,
  mobileFormat,
  formatDate,
  dayFormat,
  dateFormat,
  startDateFormat,
  endDateFormat,
  startMonthBeforeFormat,
  statMonthBeforeFormat,
  statFormat,
  excelFormat
};
