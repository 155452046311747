import React, { Component } from "react";

import color from "../../color";
import stringTitle from "../../stringTitle";

class UserInfo extends Component {
  render() {
    const { className, type, userData, onClick, pointer } = this.props;
    return (
      <div className={className}>
        {type && (
          <div
            style={{
              width: 80,
              padding: 10,
              border: "1px solid",
              color:
                userData.level === 10
                  ? color.red
                  : userData.level === 5
                  ? color.diamond
                  : color.lightgray
            }}
          >
            {userData.level === 10
              ? stringTitle.manager
              : userData.level === 5
              ? stringTitle.franchise
              : stringTitle.user}
          </div>
        )}
        <div style={{ minWidth: 200 }} onClick={onClick}>
          <div className={pointer}>{userData.id}</div>
          {userData.name} ( {userData.recommendCode} )
        </div>
      </div>
    );
  }
}

export default UserInfo;
