import React, { Component } from "react";
import { Modal } from "antd";

import { httpUrl, serverUrl } from "../../api/httpClient";

import BasicImage from "./BasicImage";

class ImagePrevModal extends Component {
  render() {
    const { visible, onCancel, data } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={false}
        title="이미지 미리보기"
        maskClosable={false}
        style={{ maxWidth: 800 }}
      >
        <BasicImage
          style={{ width: "100%" }}
          image={data}
          src={serverUrl + httpUrl.getImage + data}
        />
      </Modal>
    );
  }
}

export default ImagePrevModal;
