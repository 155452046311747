import React, { Component } from "react";

import {
  Button,
  Descriptions,
  Modal,
  Icon,
  Input,
  InputNumber,
  Upload
} from "antd";

import { httpGet, httpUrl, imageType, serverUrl } from "../../api/httpClient";

import _const from "../../const";
import stringTitle from "../../stringTitle";
import {
  amountDivideFormat,
  amountMultiFormat,
  checkData,
  formatDate,
  numberFormat
} from "../../util";
import {
  ShowErrorAlert,
  ShowErrorUploadAlert
} from "../common/ErrorAlertModal";
import { showUpdateCloseRefreshConfirm } from "../common/AlertInfoModal";

import GoodsImage from "./GoodsImage";
import GoodsOption from "./GoodsOption";
import BasicImage from "../common/BasicImage";
import GoodsRequireInfo from "./GoodsRequireInfo";
import GoodsCategorySelect from "./GoodsCategorySelect";
import ImagePrevModal from "../common/ImagePrevModal";

const Ditems = Descriptions.Item;
class GoodsDetail extends Component {
  state = {
    categoryList: [],

    isModify: false,
    selectData: {},
    topCategoryList: [],
    subCategoryList: [],
    lastCategoryList: [],

    optionFlag: false,
    mainChange: {
      deleteImages: [],
      images: []
    },
    titleChange: {
      deleteImages: [],
      images: []
    },
    contentChange: {
      deleteImages: [],
      images: []
    },

    prevFlag: false,
    imageData: ""
  };

  // 카테고리 변경
  categoryHandleChange = (value, type) => {
    // console.log(`value = ${JSON.stringify(value)}`);
    // console.log(`type = ${JSON.stringify(type)}`);
    let selectData = this.state.selectData;
    if (type === "top")
      if (selectData.topCategory !== value) {
        this.setState({
          selectData: {
            ...selectData,
            topCategory: value,
            subCategory: 0,
            lastCategory: 0
          },
          subCategoryList: [],
          lastCategoryList: []
        });
        _const.subCategoryFlag &&
          this.getCategoryParentIdxList({ idx: value, type: "sub" });
      }
    if (type === "sub")
      if (selectData.subCategory !== value) {
        this.setState({
          selectData: {
            ...selectData,
            subCategory: value,
            lastCategory: 0
          },
          lastCategoryList: []
        });
        this.getCategoryParentIdxList({ idx: value, type: "last" });
      }
    if (type === "last")
      if (selectData.lastCategory !== value) {
        this.setState({
          selectData: {
            ...selectData,
            lastCategory: value
          }
        });
      }
  };

  // 값 변경
  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    data[type] = value;

    if (type === "orgPrice") {
      data.payment = 0;
      data.discountRate = 0;
    }

    if (type === "payment") {
      data.discountRate = Math.round((1 - value / data.orgPrice) * 100);
    }
    if (type === "discountRate") {
      data.payment =
        Math.floor(((1 - (data.discountRate * 1) / 100) * data.orgPrice) / 10) *
        10;
    }

    this.setState({
      selectData: data
    });

    // console.log(`data: ${JSON.stringify(data, null, 4)}`);
  };

  // 이미지 랭크 수정
  onChangeRankImage = (value, index, list, type) => {
    const { selectData, titleChange, contentChange } = this.state;
    let images = [];
    let chageFlag = true;

    if (index !== -1)
      list[index] = {
        ...list[index],
        rank: value
      };

    let changeData = {
      ...list[index],
      rank: value === "" ? null : value
    };

    if (type === "GOODS_TITLE") {
      images = titleChange.images;

      if (images.length === 0) {
        chageFlag = false;
        images.push(changeData);
      }
      if (images.length !== 0) {
        // 값이 중복 추가되는거 잡기 위해서 설정
        if (chageFlag) {
          images.forEach((item, index) => {
            // 이미지 랭크 수정시 이미 있는 경우 삭제
            if (item.photo === changeData.photo) {
              images.splice(index, 1);
              if (images.length === 0) images.push(changeData);
            }
          });
          images.push(changeData);
        }
      }
      // console.log(`images - ${JSON.stringify(images, null, 4)}`);

      this.setState({
        selectData: { ...selectData, titleImages: list },
        titleChange: { ...titleChange, images }
      });
    }

    if (type === "GOODS_CONTENT") {
      images = contentChange.images;

      if (images.length === 0) {
        chageFlag = false;
        images.push(changeData);
      }
      if (images.length !== 0) {
        // 값이 중복 추가되는거 잡기 위해서 설정
        if (chageFlag) {
          images.forEach((item, index) => {
            if (item.photo === changeData.photo) {
              // 이미지 랭크 수정시 이미 있는 경우 삭제하고 다시 넣어주는 조건
              images.splice(index, 1);
              if (images.length === 0) images.push(changeData);
            }
          });
          images.push(changeData);
        }
      }

      this.setState({
        selectData: { ...selectData, contentImages: list },
        contentChange: { ...contentChange, images }
      });
    }
  };

  // 이미지 삭제
  onDeleteImage = (data, index, selectImages, type) => {
    const { titleChange, contentChange } = this.state;
    let deleteImages = [];

    // 대표 이미지 삭제
    if (type === "GOODS_TITLE") {
      selectImages.splice(index, 1);
      // 이미 존재했던 이미지만 삭제내역으로 추가
      if (data.idx !== 0)
        deleteImages = [...titleChange.deleteImages, data.idx];

      // 새로 추가하는 이미지를 지우는 경우
      if (data.idx === 0) {
        titleChange.images.forEach((item) => {
          if (item.photo === data.photo) titleChange.images.splice(data, 1);
        });
      }

      this.setState({
        titleChange: {
          ...titleChange,
          deleteImages: deleteImages
        },
        selectData: { ...this.state.selectData, titleImages: selectImages }
      });
    }
    // 상세이미지 삭제
    if (type === "GOODS_CONTENT") {
      selectImages.splice(index, 1);
      // 이미 존재했던 이미지만 삭제내역으로 추가
      if (data.idx !== 0)
        deleteImages = [...contentChange.deleteImages, data.idx];

      // 새로 추가하는 이미지를 지우는 경우
      if (data.idx === 0) {
        contentChange.images.forEach((item) => {
          if (item.photo === data.photo) contentChange.images.splice(data, 1);
        });
      }

      this.setState({
        contentChange: {
          ...contentChange,
          deleteImages: deleteImages
        },
        selectData: { ...this.state.selectData, contentImages: selectImages }
      });
    }
  };

  // 업데이트
  onUpdate = () => {
    const { selectData, mainChange, titleChange, contentChange } = this.state;

    // 기본정보 업데이트
    let categoryIdx = selectData.topCategory;
    if (selectData.subCategory !== 0) categoryIdx = selectData.subCategory;
    if (selectData.lastCategory !== 0) categoryIdx = selectData.lastCategory;

    let price = Math.floor(
      selectData.payment - amountMultiFormat(selectData.point)
    );
    let updateData = {
      ...selectData,
      point: amountMultiFormat(selectData.point),
      title: {
        content: selectData.title_content,
        idx: selectData.title.idx
      },
      content: {
        content: selectData.content_content || "",
        idx: selectData.content !== null ? selectData.content.idx : 0
      },
      categoryIdx,
      price: price,
      mainImage: mainChange,
      titleImage: titleChange,
      contentImage: contentChange
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    showUpdateCloseRefreshConfirm(
      stringTitle.updateQuest,
      httpUrl.goodsUpdate,
      updateData,
      this.props.refresh,
      this.props.onCancel
    );
  };

  // 카테고리 리스트
  getCategoryParentIdxList = (params = {}) => {
    let idx = params.idx || 0;
    httpGet(httpUrl.getCategoryParentIdxList, [idx], {})
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        if (params.type === "top") {
          // console.log(`category top List = ${JSON.stringify(res, null, 4)}`);
          this.setState({ topCategoryList: res.data });
        }
        if (params.type === "sub") {
          // console.log(`category sub List = ${JSON.stringify(res, null, 4)}`);
          this.setState({
            subCategoryList:
              res.data.length === 0
                ? []
                : [{ idx: 0, categoryName: "선택안함" }, ...res.data]
          });
        }
        if (params.type === "last") {
          // console.log(`category last List = ${JSON.stringify(res, null, 4)}`);
          this.setState({
            lastCategoryList:
              res.data.length === 0
                ? []
                : [{ idx: 0, categoryName: "선택안함" }, ...res.data]
          });
        }
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 카테고리 리스트
  getCategoryList = (params = {}) => {
    // console.log(`params = ${JSON.stringify(params, null, 4)}`);
    httpGet(httpUrl.getCategoryList, [], {})
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        let top = [];
        let sub = [{ idx: 0, categoryName: "선택안함" }];
        let last = [{ idx: 0, categoryName: "선택안함" }];
        res.data.forEach((item) => {
          if (item.parentIdx === 0) top.push(item);
          if (
            params.subParentIdx !== 0 &&
            params.subParentIdx === item.parentIdx
          )
            sub.push(item);
          if (
            params.lastParentIdx !== 0 &&
            params.lastParentIdx === item.parentIdx
          )
            last.push(item);
        });
        // console.log(`top = ${JSON.stringify(top, null, 4)}`);
        // console.log(`sub = ${JSON.stringify(sub, null, 4)}`);
        // console.log(`last = ${JSON.stringify(last, null, 4)}`);
        this.setState({
          topCategoryList: top,
          subCategoryList: sub,
          lastCategoryList: last
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const { visible, onCancel, detailData } = this.props;
    const {
      prevFlag,
      imageData,
      isModify,
      selectData,
      topCategoryList,
      subCategoryList,
      lastCategoryList,
      optionFlag,
      requireInfoFlag,
      selectIdx,
      titleChange,
      contentChange
    } = this.state;

    // 썸네일이미지
    const props = {
      action: serverUrl + httpUrl.imageUpload + true,
      multiple: false,
      withCredentials: true,
      showUploadList: false,
      listType: "picture-card",
      beforeUpload: (file) => {
        // console.log(`main before = ${JSON.stringify(file, null, 4)}`);
        if (file.size > _const.imageSize) {
          ShowErrorUploadAlert("파일 크기를 확인해주세요.");
          return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
        }
        if (!imageType.find((type) => type === file.type)) {
          ShowErrorUploadAlert("파일 형식을 확인해주세요.");
          return false;
        }
      },
      onSuccess: (file) => {
        // console.log(`main success = ${JSON.stringify(file, null, 4)}`);
        if (file.data.result) {
          Modal.info({
            title: "업로드 결과",
            content: "파일 업로드 성공"
          });
          this.setState({
            selectData: {
              ...selectData,
              mainImage: {
                idx: 0,
                photo: file.data.fileId,
                subPhoto: file.data.thumbnailId
              }
            },
            mainChange: {
              deleteImages: [detailData.mainImage.idx],
              images: [
                {
                  idx: 0,
                  photo: file.data.fileId,
                  subPhoto: file.data.thumbnailId
                }
              ]
            }
          });
        }
      },
      onError(err) {
        Modal.error({
          title: "업로드 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    // 대표이미지
    const titleUpload = {
      action: serverUrl + httpUrl.imageUpload + false,
      multiple: false,
      withCredentials: true,
      className: "upload-list-inline",
      FileList: false,
      beforeUpload: (file) => {
        // console.log(`title before = ${JSON.stringify(file, null, 4)}`);
        if (file.size > _const.imageSize) {
          ShowErrorUploadAlert("파일 크기를 확인해주세요.");
          return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
        }
        if (!imageType.find((type) => type === file.type)) {
          ShowErrorUploadAlert("파일 형식을 확인해주세요.");
          return false;
        }
      },
      onSuccess: (file) => {
        // console.log(`title success = ${JSON.stringify(file, null, 4)}`);
        if (file.data.result) {
          Modal.info({
            title: "업로드 결과",
            content: "파일 업로드 성공"
          });
          this.setState({
            selectData: {
              ...selectData,
              titleImages: [
                ...selectData.titleImages,
                { idx: 0, photo: file.data.fileId, subPhoto: null, rank: null }
              ]
            },
            titleChange: {
              ...titleChange,
              images: [
                ...titleChange.images,
                { idx: 0, photo: file.data.fileId, subPhoto: null, rank: null }
              ]
            }
          });
        }
      },
      onError(err) {
        Modal.error({
          title: "업로드 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    // 상세이미지
    const contentUpload = {
      action: serverUrl + httpUrl.imageUpload + false,
      multiple: false,
      withCredentials: true,
      className: "upload-list-inline",
      FileList: false,
      beforeUpload: (file) => {
        // console.log(`content before = ${JSON.stringify(file, null, 4)}`);
        if (file.size > _const.imageSize) {
          ShowErrorUploadAlert("파일 크기를 확인해주세요.");
          return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
        }
        if (!imageType.find((type) => type === file.type)) {
          ShowErrorUploadAlert("파일 형식을 확인해주세요.");
          return false;
        }
      },
      onSuccess: (file) => {
        // console.log(`content success = ${JSON.stringify(file, null, 4)}`);
        if (file.data.result) {
          Modal.info({
            title: "업로드 결과",
            content: "파일 업로드 성공"
          });
          this.setState({
            selectData: {
              ...selectData,
              contentImages: [
                ...selectData.contentImages,
                { idx: 0, photo: file.data.fileId, subPhoto: null, rank: null }
              ]
            },
            contentChange: {
              ...contentChange,
              images: [
                ...contentChange.images,
                { idx: 0, photo: file.data.fileId, subPhoto: null, rank: null }
              ]
            }
          });
        }
      },
      onError(err) {
        Modal.error({
          title: "업로드 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    return (
      <Modal
        visible={visible}
        title={stringTitle.viewDetail}
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}
        footer={false}
        maskClosable={false}
      >
        {prevFlag && (
          <ImagePrevModal
            visible={prevFlag}
            data={imageData}
            onCancel={() => {
              this.setState({ prevFlag: false, imageData: "" });
            }}
          />
        )}
        {/* 옵션 상세정보 */}
        {optionFlag && (
          <GoodsOption
            visible={optionFlag}
            idx={selectIdx}
            paymentInfo={{
              payment: detailData.price + amountDivideFormat(detailData.point),
              price: detailData.price,
              point: amountDivideFormat(detailData.point)
            }}
            onCancel={() => {
              this.setState({ optionFlag: false });
            }}
          />
        )}
        {/* 필수표기정보 */}
        {requireInfoFlag && (
          <GoodsRequireInfo
            type="edit"
            visible={requireInfoFlag}
            idx={selectIdx}
            onCancel={() => {
              this.setState({ requireInfoFlag: false });
            }}
          />
        )}
        {/* 상품 기본정보 */}
        <Descriptions key={detailData.idx} bordered column={2}>
          <Ditems label={stringTitle.createDate} key={detailData.idx} span={2}>
            {formatDate(detailData.createDate)}
          </Ditems>
          <Ditems label={stringTitle.rank}>
            {!isModify ? (
              numberFormat(detailData.rank)
            ) : (
              <InputNumber
                value={selectData.rank}
                style={{ width: "150px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  this.onChangeModal(value, "rank");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.modelName}>
            {!isModify ? (
              checkData(detailData.modelName)
            ) : (
              <Input
                value={selectData.modelName}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "modelName");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.category} span={2}>
            {!isModify ? (
              !detailData.category.child ? (
                detailData.category.categoryName
              ) : !detailData.category.child[0].child ? (
                `${detailData.category.categoryName} > ${detailData.category.child[0].categoryName}`
              ) : (
                `${detailData.category.categoryName} > ${detailData.category.child[0].categoryName} > ${detailData.category.child[0].child[0].categoryName}`
              )
            ) : (
              <GoodsCategorySelect
                topCategory={selectData.topCategory}
                topCategoryList={topCategoryList}
                subCategory={selectData.subCategory}
                subCategoryList={subCategoryList}
                lastCategory={selectData.lastCategory}
                lastCategoryList={lastCategoryList}
                onChange={this.categoryHandleChange}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.title} span={2}>
            {!isModify ? (
              detailData.title.content
            ) : (
              <Input
                style={{ maxWidth: 700 }}
                value={selectData.title_content}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "title_content");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.content} span={2}>
            {!isModify ? (
              detailData.content !== null ? (
                <div style={{ maxWidth: 700 }}>
                  {detailData.content.content.split("\n").map((line, index) => {
                    return (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </div>
              ) : (
                "-"
              )
            ) : (
              <Input.TextArea
                rows={6}
                style={{ whiteSpace: "pre-line", maxWidth: 700 }}
                value={selectData.content_content}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "content_content");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.orgPrice}>
            {!isModify ? (
              numberFormat(detailData.orgPrice)
            ) : (
              <InputNumber
                style={{ width: "150px" }}
                value={selectData.orgPrice}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => this.onChangeModal(value, "orgPrice")}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.discountRate}>
            {!isModify ? (
              numberFormat(detailData.discountRate)
            ) : (
              <InputNumber
                min={0}
                max={100}
                style={{ width: "150px" }}
                value={selectData.discountRate}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => this.onChangeModal(value, "discountRate")}
              />
            )}
          </Ditems>
          <Ditems
            label={
              <div>
                <span>{stringTitle.payment}</span>
                <div />
                {stringTitle.price} / {stringTitle.point}
              </div>
            }
          >
            {!isModify ? (
              <div>
                {numberFormat(
                  Math.floor(
                    ((1 - (detailData.discountRate * 1) / 100) *
                      detailData.orgPrice) /
                      10
                  ) * 10
                )}
                <div />
                <span>{stringTitle.price} : </span>
                {numberFormat(detailData.price)} /{" "}
                <span>{stringTitle.point} : </span>
                {numberFormat(amountDivideFormat(detailData.point))}
              </div>
            ) : (
              <div>
                <InputNumber
                  min={0}
                  style={{ width: "150px" }}
                  value={selectData.payment}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) => this.onChangeModal(value, "payment")}
                />
                <div style={{ clear: "both" }} />
                <label>
                  <span className="m-r-10">{stringTitle.price} : </span>
                  {numberFormat(
                    Math.round(
                      selectData.payment - amountMultiFormat(selectData.point)
                    )
                  )}
                  <span className="m-r-10"> / {stringTitle.point} : </span>
                  <InputNumber
                    value={selectData.point}
                    max={amountDivideFormat(
                      Math.floor(
                        (((1 - (selectData.discountRate * 1) / 100) *
                          selectData.orgPrice) /
                          10) *
                          10
                      )
                    )}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => this.onChangeModal(value, "point")}
                  />
                </label>
              </div>
            )}
          </Ditems>
          <Ditems label={stringTitle.deliveryCharge}>
            {!isModify ? (
              <div style={{ width: 150 }}>
                {numberFormat(detailData.deliveryCharge)}
              </div>
            ) : (
              <InputNumber
                style={{ width: "150px" }}
                value={selectData.deliveryCharge}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) =>
                  this.onChangeModal(value, "deliveryCharge")
                }
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.mainImage} span={2}>
            {!isModify ? (
              <BasicImage
                image={detailData.mainImage.subPhoto}
                src={
                  serverUrl + httpUrl.getImage + detailData.mainImage.subPhoto
                }
                style={{
                  cursor: "pointer",
                  width: "150px",
                  height: "150px"
                }}
                onClick={() => {
                  this.setState({
                    prevFlag: true,
                    imageData: detailData.mainImage.subPhoto
                  });
                }}
              />
            ) : (
              <Upload {...props}>
                <BasicImage
                  image={selectData.mainImage.subPhoto}
                  src={
                    serverUrl + httpUrl.getImage + selectData.mainImage.subPhoto
                  }
                  style={{ width: "150px", height: "150px" }}
                />
              </Upload>
            )}
          </Ditems>
          <Ditems label={stringTitle.titleImage} span={2}>
            {!isModify ? (
              <div
                className="flex-row"
                style={{ overflow: "auto", maxWidth: 750, paddingBottom: 10 }}
              >
                {detailData.titleImages.map((item, index) => (
                  <BasicImage
                    key={index}
                    image={item.photo}
                    src={serverUrl + httpUrl.getImage + item.photo}
                    style={{
                      cursor: "pointer",
                      width: "150px",
                      height: "150px",
                      marginRight:
                        index - 1 === detailData.titleImages.length ? 0 : 5
                    }}
                    onClick={() => {
                      this.setState({
                        prevFlag: true,
                        imageData: item.photo
                      });
                    }}
                  />
                ))}
              </div>
            ) : (
              <div>
                <Upload {...titleUpload} fileList={false} className="m-b-10">
                  <Button className="m-b-10">
                    <Icon type="upload" /> {stringTitle.titleImage}{" "}
                    {stringTitle.add}
                  </Button>
                </Upload>
                <div
                  className="flex-row"
                  style={{ overflow: "auto", maxWidth: 750, paddingBottom: 10 }}
                >
                  {selectData.titleImages.map((item, index) => (
                    <GoodsImage
                      key={index}
                      index={index}
                      item={item}
                      list={selectData.titleImages}
                      type="GOODS_TITLE"
                      onDeleteImage={this.onDeleteImage}
                      onChangeRankImage={this.onChangeRankImage}
                    />
                  ))}
                </div>
              </div>
            )}
          </Ditems>
          <Ditems label={stringTitle.contentImage}>
            {!isModify ? (
              <div
                className="flex-row"
                style={{ overflow: "auto", maxWidth: 750, paddingBottom: 10 }}
              >
                {detailData.contentImages.map((item, index) => (
                  <BasicImage
                    key={index}
                    image={item.photo}
                    src={serverUrl + httpUrl.getImage + item.photo}
                    style={{
                      cursor: "pointer",
                      width: "150px",
                      height: "150px",
                      marginRight:
                        index - 1 === detailData.contentImages.length ? 0 : 5
                    }}
                    onClick={() => {
                      this.setState({
                        prevFlag: true,
                        imageData: item.photo
                      });
                    }}
                  />
                ))}
              </div>
            ) : (
              <div>
                <Upload {...contentUpload} fileList={false} className="m-b-10">
                  <Button className="m-b-10">
                    <Icon type="upload" /> {stringTitle.contentImage}{" "}
                    {stringTitle.add}
                  </Button>
                </Upload>
                <div
                  className="flex-row"
                  style={{ overflow: "auto", maxWidth: 750, paddingBottom: 10 }}
                >
                  {selectData.contentImages.map((item, index) => (
                    <GoodsImage
                      key={index}
                      index={index}
                      item={item}
                      list={selectData.contentImages}
                      type="GOODS_CONTENT"
                      onDeleteImage={this.onDeleteImage}
                      onChangeRankImage={this.onChangeRankImage}
                    />
                  ))}
                </div>
              </div>
            )}
          </Ditems>
        </Descriptions>
        <div
          className={
            isModify
              ? "flex-row flex-center flex-end m-t-10 m-b-10"
              : "flex-row flex-center flex-sb m-t-10 m-b-10"
          }
        >
          {!isModify && (
            <div className="flex-row flex-center">
              <Button
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    optionFlag: true,
                    selectIdx: detailData.idx
                  });
                }}
              >
                {stringTitle.option} {stringTitle.view}
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    requireInfoFlag: true,
                    selectIdx: detailData.idx
                  });
                }}
              >
                {stringTitle.requrieInfo} {stringTitle.view}
              </Button>
            </div>
          )}
          <div className="flex-row flex-center">
            {!isModify ? (
              <Button
                onClick={() => {
                  this.setState({
                    isModify: true,
                    selectData: {
                      ...detailData,
                      payment:
                        Math.floor(
                          ((1 - (detailData.discountRate * 1) / 100) *
                            detailData.orgPrice) /
                            10
                        ) * 10,
                      point: parseFloat(amountDivideFormat(detailData.point)),
                      title_content: detailData.title.content,
                      content_content:
                        detailData.content !== null
                          ? detailData.content.content
                          : "",
                      topCategory: detailData.category.idx,
                      subCategory:
                        detailData.category.child !== null
                          ? detailData.category.child[0].idx
                          : 0,
                      lastCategory:
                        detailData.category.child !== null
                          ? detailData.category.child[0].child !== null
                            ? detailData.category.child[0].child[0].idx
                            : 0
                          : 0
                    }
                  });
                  this.getCategoryList({
                    topCategory: detailData.category.idx,
                    subParentIdx:
                      detailData.category.child !== null
                        ? detailData.category.child[0].parentIdx
                        : 0,
                    lastParentIdx:
                      detailData.category.child !== null
                        ? detailData.category.child[0].child !== null
                          ? detailData.category.child[0].child[0].parentIdx
                          : 0
                        : 0
                  });
                }}
              >
                {stringTitle.updateFlag}
              </Button>
            ) : (
              <div>
                <Button
                  className="m-r-10"
                  onClick={() => {
                    this.onUpdate();
                  }}
                >
                  {stringTitle.update}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      isModify: false,
                      mainChange: {
                        deleteImages: [],
                        images: []
                      },
                      titleChange: {
                        deleteImages: [],
                        images: []
                      },
                      contentChange: {
                        deleteImages: [],
                        images: []
                      }
                    });
                  }}
                >
                  {stringTitle.cancel}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default GoodsDetail;
