import React, { Component } from "react";
import crypto from "crypto";

import { Button, Modal, Input, Form, TreeSelect } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import SellerSearchModal from "../common/SellerSearchModal";
import { showCreateConfirm } from "../common/AlertInfoModal";

const FormItem = Form.Item;
const { TreeNode, SHOW_PARENT } = TreeSelect;
const AdminRegistryModal = Form.create()(
  class extends Component {
    state = {
      confirmDirty: false,
      registryData: { superAdmin: 0 },
      authList: [],
      authSelect: [],
      sellerFlag: false
    };

    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    };

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue("password")) {
        callback("비밀번호가 다릅니다!");
      } else {
        callback();
        this.onSelect(value, "password");
      }
    };

    handleConfirmBlur = (e) => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    onSelect = (value, type) => {
      var data = this.state.registryData;

      data[type] = value;
      this.setState({
        registryData: data
      });
      // console.log(JSON.stringify(data, null, 4));
    };

    // 권한 설정
    authSelect = (value, row) => {
      let data = [...this.state.authSelect];

      if (value.includes(data.idx)) {
        // delete value;
        data.filter((idx) => idx !== value);
      } else {
        // 부모가 선택됬을때 자식에게 기능 부여
        data = [...value];
        this.state.authList.forEach((list) => {
          if (
            data.includes(list.idx) &&
            list.children &&
            list.children !== null
          ) {
            // console.log("true", list);
            list.children.forEach((subList) => {
              if (subList.children && list.children !== null) {
                subList.children.forEach((thirdList) => {
                  data.push(thirdList.idx);
                });
              } else data.push(subList.idx);
            });
          }
        });
      }
      this.setState({ authSelect: data });
      // console.log("data", data);
    };

    // 생성
    onRegistry = () => {
      const { authSelect, authIdxList } = this.state;
      const authParent = this.state.authParent;

      this.props.form.validateFields((err, values) => {
        // console.log(`valuse = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let superAdmin = parseInt(values.superAdmin) === 0 ? false : true;
          let password = values.password + values.id;
          let hashedPassword = crypto
            .createHash("sha256")
            .update(password, "utf8")
            .digest()
            .toString("hex");

          let createData = {
            ...values,
            password: hashedPassword,
            superAdmin: superAdmin
          };

          let menu = [...authSelect];
          authParent.forEach((list) => {
            if (authSelect.includes(list.idx)) {
              if (!menu.includes(list.parentIdx)) menu.push(list.parentIdx);
            }
          });
          let authRequest = {};
          if (!superAdmin) {
            authRequest = {
              authList: authIdxList.map((list) => {
                if (menu.includes(list.idx)) {
                  return {
                    menuIdx: list.idx,
                    auth: 1
                  };
                } else {
                  return {
                    menuIdx: list.idx,
                    auth: 0
                  };
                }
              }),
              idx: 0
            };

            createData = {
              ...createData,
              authRequest: authRequest
            };
          }

          // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
          showCreateConfirm(
            stringTitle.createQuest,
            httpUrl.adminCreate,
            createData,
            this.props.refresh,
            this.onCancel
          );
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    // 메뉴 권한 보기
    getAuthList = (params = {}) => {
      httpGet(httpUrl.getAuthList, [0], {})
        .then((res) => {
          // console.log(JSON.stringify(res, null, 4));
          let menu = [];
          let parent = [];
          let authIdx = [];
          res.data.forEach((list) => {
            let menuObj = { ...list };
            delete menuObj.subMenu;
            delete menuObj.nameKr;
            if (list.subMenu === null || list.subMenu.length === 0) {
              menu.push({
                ...menuObj,
                children: null,
                title: list.nameKr,
                value: list.idx
              });
              authIdx.push({ idx: list.idx });
            } else {
              let subMenu = [];
              list.subMenu.forEach((subList) => {
                let subMenuObj = {
                  ...subList
                };
                delete subMenuObj.subMenu;
                delete subMenuObj.nameKr;
                if (subList.subMenu === null || subList.subMenu.length === 0) {
                  // 주문관리 + G포인트 제외처리
                  if (
                    subList.path !== "/order/order" &&
                    subList.path !== "/order/pointGoods"
                  ) {
                    subMenu.push({
                      ...subMenuObj,
                      children: null,
                      title: subList.nameKr,
                      value: subList.idx
                    });
                    parent.push({
                      idx: subList.idx,
                      parentIdx: parseInt(subList.parentIdx)
                    });
                    authIdx.push({ idx: subList.idx });
                  }
                } else {
                  let thirdMenu = [];
                  subList.subMenu.forEach((thridList) => {
                    let thirdMenuObj = { ...thridList };
                    delete subMenuObj.subMenu;
                    delete subMenuObj.nameKr;
                    if (
                      thridList.subMenu === null ||
                      thridList.subMenu.length === 0
                    ) {
                      thirdMenu.push({
                        ...thirdMenuObj,
                        children: null,
                        title: thridList.nameKr,
                        value: thridList.idx
                      });
                      parent.push({
                        idx: thridList.idx,
                        parentIdx: parseInt(thridList.parentIdx)
                      });
                      authIdx.push({ idx: thridList.idx });
                    }
                  });
                  subMenu.push({
                    ...subMenuObj,
                    children: thirdMenu,
                    title: subList.nameKr,
                    value: subList.idx
                  });
                  parent.push({
                    idx: subList.idx,
                    parentIdx: parseInt(subList.parentIdx)
                  });
                  authIdx.push({ idx: subList.idx });
                }
              });
              menu.push({
                ...menuObj,
                children: subMenu,
                title: list.nameKr,
                value: list.idx
              });
              authIdx.push({ idx: list.idx });
            }
          });
          this.setState({
            authList: menu,
            authParent: parent,
            authIdxList: authIdx
          });
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    };

    componentDidMount() {
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
      this.getAuthList();
    }

    renderOption = () => {
      const { authList } = this.state;
      let result = [];
      authList.forEach((list) => {
        if (list.subMenu === null) {
          result.push(
            <TreeNode
              value={list.idx}
              key={`${list.idx}}`}
              title={list.nameKr}
            ></TreeNode>
          );
        } else {
          result.push(
            <TreeNode value={list.idx} key={`${list.idx}}`} title={list.nameKr}>
              {list.subMenu.map((subList) => {
                if (subList.subMenu === null) {
                  return (
                    <TreeNode
                      value={subList.idx}
                      key={`${subList.idx}}`}
                      title={subList.nameKr}
                    />
                  );
                } else {
                  return (
                    <TreeNode
                      value={subList.idx}
                      key={`${subList.idx}}`}
                      title={subList.nameKr}
                    >
                      {subList.subMenu.map((thirdList) => {
                        return (
                          <TreeNode
                            value={thirdList.idx}
                            key={`${thirdList.idx}}`}
                            title={thirdList.nameKr}
                          />
                        );
                      })}
                    </TreeNode>
                  );
                }
              })}
            </TreeNode>
          );
        }
      });
      return result;
    };

    render() {
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      const { registryData, sellerFlag } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const authData = {
        treeCheckable: true,
        onChange: (value, title, row) => {
          this.authSelect(value, row);
        },
        // value: this.state.authSelect,
        treeData: this.state.authList,
        showCheckedStrategy: SHOW_PARENT,
        searchPlaceholder: "선택해주세요"
      };

      return (
        <Modal
          visible={visible}
          title={stringTitle.admin + stringTitle.create}
          okText={stringTitle.create}
          cancelText={stringTitle.cancel}
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}
          maskClosable={false}
        >
          {sellerFlag && (
            <SellerSearchModal
              page="admin"
              type="create"
              visible={sellerFlag}
              reverseFlag={false}
              adminIdx={0}
              onCancel={() => {
                this.setState({ sellerFlag: false });
              }}
              onOk={(sellerInfo) => {
                this.setState({ sellerList: sellerInfo });
                let arr = [];
                sellerInfo.forEach((item) => {
                  arr.push(item.idx);
                });
                form.setFieldsValue({ sellerIdxs: arr });
              }}
            />
          )}
          {/* <div>{JSON.stringify(this.state.registryData, null, 4)}</div> */}
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            <FormItem label={<span>{stringTitle.name}&nbsp;</span>}>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: false,
                    message: "이름을 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear
                  placeholder="홍길동"
                  onChange={(e) => {
                    this.onSelect(e.target.value, "name");
                  }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.id}&nbsp;</span>}>
              {getFieldDecorator("id", {
                rules: [
                  {
                    required: true,
                    message: "아이디를 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear
                  placeholder="kildong"
                  onChange={(e) => {
                    this.onSelect(e.target.value, "id");
                  }}
                />
              )}
            </FormItem>
            <FormItem
              hasFeedback
              label={<span>{stringTitle.password}&nbsp;</span>}
            >
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "비밀번호를 입력해주세요!"
                  },
                  {
                    validator: this.validateToNextPassword
                  }
                ]
              })(<Input.Password />)}
            </FormItem>
            <FormItem
              hasFeedback
              label={
                <span>
                  {stringTitle.password} {stringTitle.check}&nbsp;
                </span>
              }
            >
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "비밀번호를 다시 한번 입력해주세요!"
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
            </FormItem>
            <FormItem
              label={
                <span>
                  {stringTitle.ip} {stringTitle.address}&nbsp;
                </span>
              }
            >
              {getFieldDecorator("ipAddress", {
                rules: [
                  {
                    required: true,
                    message: "IP 주소를 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear
                  placeholder="123.456.0.0"
                  onChange={(e) => {
                    this.onSelect(e.target.value, "ipAddress");
                  }}
                />
              )}
              <div
                style={{
                  fontSize: "14px",
                  color: "blue"
                }}
              >
                <span>*</span>을 입력하시면 모든 곳에서 접속이 가능합니다.
              </div>
            </FormItem>
            <FormItem
              label={
                <span>
                  {stringTitle.admin} {stringTitle.class}&nbsp;
                </span>
              }
            >
              {getFieldDecorator("superAdmin", {
                rules: [
                  {
                    required: true,
                    message: "등급을 설정해주세요!"
                  }
                ]
              })(
                <SelectBox
                  placeholder="선택하세요"
                  style={{ width: "160px" }}
                  code={string.toggleCode}
                  codeString={string.adminString}
                  onChange={(value) => {
                    this.onSelect(value, "superAdmin");
                  }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.email}&nbsp;</span>}>
              {getFieldDecorator(
                "email",
                {}
              )(
                <Input
                  allowClear
                  placeholder="kildong@naver.com"
                  onChange={(e) => {
                    this.onSelect(e.target.value, "email");
                  }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.mobile}&nbsp;</span>}>
              {getFieldDecorator(
                "mobile",
                {}
              )(
                <Input
                  style={{ width: "200px" }}
                  placeholder="01012345678"
                  onChange={(e) => {
                    this.onSelect(e.target.value, "mobile");
                  }}
                />
              )}
            </FormItem>
            {parseInt(registryData.superAdmin) === 0 && (
              <FormItem label={stringTitle.auth + stringTitle.setting}>
                {getFieldDecorator("auth", {
                  rules: [
                    {
                      required: parseInt(registryData.superAdmin) === 0 && true,
                      message: "권한을 설정해주세요.",
                      type: "array"
                    }
                  ]
                })(<TreeSelect {...authData} />)}
              </FormItem>
            )}
            {parseInt(registryData.superAdmin) === 0 && (
              <FormItem label={stringTitle.seller + stringTitle.select}>
                {getFieldDecorator("sellerIdxs", {
                  rules: [
                    {
                      required: parseInt(registryData.superAdmin) === 0 && true,
                      message: "판매자를 설정하세요.",
                      type: "array"
                    }
                  ]
                })(
                  <Button
                    onClick={() => {
                      this.setState({ sellerFlag: true });
                    }}
                  >
                    {stringTitle.selectFlag}
                  </Button>
                )}
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default AdminRegistryModal;
