const stringTitle = {
  // 기본
  management: "관리",
  info: "정보",
  idx: "순번",
  title: "제목",
  content: "내용",
  view: "보기",
  preView: "미리보기",
  viewDetail: "상세보기",
  type: "구분",
  excelDownload: "엑셀 다운로드",

  // 검색 조건
  search: "검색",
  searchRequire: "검색조건",
  deletedInclude: "삭제 포함",
  searchDate: "검색기간",

  // 상품
  rank: "우선순위",
  category: "카테고리",
  orgPrice: "소비자가",
  price: "현금",
  money: "금액",
  point: "G포인트",
  discountRate: "할인율(%)",
  payment: "판매가",
  deliveryCharge: "배송비",
  stock: "재고",
  postFlag: "게시여부",
  modelName: "모델명",
  requrieInfo: "필수표기정보",
  requireInfoText: "상품 상세페이지 참조",
  //상품 옵션
  option: "옵션",
  optionName: "옵션명",
  //상품이미지
  mainImage: "썸네일이미지",
  titleImage: "대표이미지",
  contentImage: "상세이미지",
  //category
  categoryName: "카테고리 명",
  categoryDepth: "상세 카테고리",
  //keyword
  word: "단어",
  cnt: "검색량",

  // 판매자
  seller: "판매자",
  businessName: "사업자명",
  businessNum: "사업자번호",
  owner: "사업주",
  mobNum: "통신판매신고번호",

  // 회원
  user: "회원",
  joinDate: "가입일",
  id: "아이디",
  name: "이름",
  recommendCode: "추천코드",
  tel: "전화번호",
  address: "주소",
  email: "이메일",
  recommender: "추천인",
  memo: "메모",
  pointType: "G포인트 종류",
  amount: "수량",
  charge: "충전",
  use: "사용",
  friend: "친구",
  manager: "총판",
  franchise: "대리점",

  levelup_franchise: "승격리워드_대리점",
  levelup_manager: "승격리워드_총판",
  goods_franchise: "구매리워드_대리점",
  goods_franchise_rec: "구매리워드_추천인",
  goods_manager: "구매리워드_총판",

  // 사업자
  levelUp: "승격",
  manager_all: "총판",
  franchise_rec: "대리점의 추천인",
  totalReward: "누적 리워드",
  totalAdjustment: "누적 정산",
  todayReward: "오늘 받을 리워드",
  todayRecReward: "추천 리워드",
  todayOrderReward: "구매 리워드",
  notAdjustment: "미정산",
  preRecReward: "현재기간",
  rewardDate: "지급일",
  adjustmentDate: "정산일",
  todayAdjustment: "오늘 받은 정산",
  reward: "정산",
  rewardMoney: "정산금",
  rewardFlag: "정산하기",
  adjustmentFlag: "정산내역",
  identityNumber: "주민번호",
  bankName: "계좌 입금주",
  idCard: "신분증",
  bankBook: "통장사본",

  // 포인트
  airdrop: "에어드랍",
  refund: "환불",
  retrun: "반품",
  exchange: "교환",
  point_charge: "G포인트 구매",
  point_charge_rec: "G포인트 구매시 추천인 지급",
  point_sign_up: "회원가입 이벤트",
  point_sign_up_rec: "회원가입 이벤트 추천인 지급",
  point_order: "상품 구매",
  point_order_rec: "상품 구매시 추천인 지급",
  point_order_platform: "플랫폼 서비스 구매",
  point_return_platform: "플랫폼 서비스 반품",
  point_return: "포인트 회수",
  point_return_rec: "추천인 포인트 회수",

  // 주문
  order: "주문",
  orderFlag: "주문상태",
  orderDate: "주문일",
  orderId: "주문번호",
  orderUser: "주문자",
  billingType: "결제 방법",
  billingPrice: "결제 금액",
  billingPoint: "결제 G포인트",
  deliveryAddress: "배송지",
  goodsName: "상품명",
  quantity: "수량",
  logistics: "택배사",
  invoice: "운송장번호",
  zipCode: "우편번호",
  receiver: "수령인",
  returnBankInfo: "환불 계좌 정보",
  bank: "은행",
  bankAccount: "계좌번호",
  requestDate: "요청일",

  // 주문 결제
  card: "카드",
  virtual_account: "가상계좌",
  transfer_account: "계좌이체",
  // 주문 결제 상태
  pay_ready: "결제 대기",
  pay_completed: "결제 완료",
  pay_cancel: "결제 취소",
  order_ready: "주문 대기",
  order_completed: "주문 완료",
  preparation: "상품 준비중",
  shipping: "배송중",
  delivery_completed: "배송 완료",
  order_confirm: "구매확정",
  cancel_request: "주문 취소 요청중",
  cancel_completed: "주문 취소 완료",
  partial_cancel_request: "부분 취소 요청중",
  partial_cancel_completed: "부분 취소 완료",
  return_request: "반품 요청중",
  return_ing: "반품중",
  return_completed: "반품 완료",
  replace_request: "교환 요청중",
  replace_ing: "교환중",
  replace_completed: "교환 완료",
  replace: "교환",
  ing: "진행중",
  moneyReturn: "돈을 환불해주는 겁니다!!!",
  order_goods_histroy: "주문 히스토리",

  // 플랫폼
  image: "이미지",
  serviceName: "플랫폼 서비스",

  // 배너
  goods: "상품",
  url: "URL",
  position: "위치",

  // 커뮤니티
  note: "공지사항",
  news: "소식",
  evnt: "이벤트",
  read: "조회수",
  division: "구분",
  mainCheck: "메인 노출",

  // 관리자
  admin: "관리자",
  password: "비밀번호",
  ip: "IP",
  mobile: "핸드폰번호",
  class: "등급",
  auth: "권한",
  setting: "설정",
  area: "영역",
  service: "서비스",
  action: "활동",
  message: "메세지",

  // 내정보
  myInfo: "마이페이지",
  otp: "OTP",
  across: "접근",
  nowPwd: "현재 비밀번호를 입력해주세요",
  samePwd: "현재 비밀번호와 같습니다. 다시 입력해주세요",

  // 정렬
  orderBy: "정렬",
  all: "전체",
  asc: "정순",
  desc: "역순",

  // toggle
  withdraw: "탈퇴",
  post: "게시",
  unPost: "미게시",
  generalManager: "일반관리자",
  superAdmin: "슈퍼관리자",
  possible: "가능",
  impossible: "불가능",
  certify: "인증",
  unCerity: "미인증",

  // 상태
  status: "상태",
  select: "선택",
  check: "확인",
  create: "생성",
  regist: "등록",
  update: "수정",
  modify: "변경",
  cancel: "취소",
  add: "추가",
  delete: "삭제",
  selectFlag: "선택하기",
  createFlag: "생성하기",
  updateFlag: "수정하기",
  deleteFlag: "삭제하기",
  deleted: "삭제 여부",
  restore: "복구",
  wait: "대기",
  request: "요청",
  pending: "전송 중",
  success: "성공",
  fail: "실패",
  reject: "거절",
  accept: "승인",
  complete: "완료",
  result: "결과",
  offer: "신청",

  // 요일
  createDate: "생성일",
  updateDate: "갱신일",
  date: "날짜",
  day: "일별",
  week: "주별",
  month: "월별",

  // question
  createQuest: "생성하시겠습니까?",
  registQuest: "등록하시겠습니까?",
  updateQuest: "수정하시겠습니까?",
  modifyQuest: "변경하시겠습니까?",
  deleteQuest: "삭제하시겠습니까?",
  addQuest: "추가하시겠습니까?",
  requestQuest: "신청하시겠습니까?",
  chargeQuest: "충전하시겠습니까?",
  levelQuest: "승격하시겠습니까?",
  rewardQuest: "정산하시겠습니까?"
};
export default stringTitle;
