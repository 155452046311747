import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Input, Icon, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  amountDivideFormat,
  checkData,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showModifyConfirm } from "../../components/common/AlertInfoModal";

import OrderDeliverTitle from "../../components/order/OrderDeliverTitle";
import SellerSearchModal from "../../components/common/SellerSearchModal";
import OrderDeliveryModal from "../../components/order/OrderDeliveryModal";
import OrderStatusTitle from "../../components/order/OrderStatusTitle";
import OrderGoodsHistoryModal from "../../components/order/OrderGoodsHistoryModal";

const { RangePicker } = DatePicker;
class OrderRequestList extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      sellerIdx: 0,
      status: "ALL",
      billingType: "ALL",
      searchType: "TITLE",
      searchText: "",

      sellerFlag: false,
      selectSeller: {},
      deliveryFlag: false,
      historyFlag: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getOrderRequestList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 결제 상태 변경
  onHandleChangeSeller = (value) => {
    this.setState({
      sellerIdx: value
    });
  };

  // 주문 상태 변경
  onHandleChangeStatus = (value) => {
    this.setState({
      status: value
    });
  };

  // 결제 상태 변경
  onHandleChangebillingType = (value) => {
    this.setState({
      billingType: value
    });
  };

  // 검색 조건 변경
  onHandleChangeSearchType = (value) => {
    this.setState({
      searchType: value
    });
  };

  // 주문 상태 변경
  goodsOrderStatusUpdate = (data, status) => {
    let updateData = {
      idx: data.idx,
      status: status
    };

    showModifyConfirm(
      ` ${status === "CANCEL" ? stringTitle.moneyReturn : ""} ${
        stringTitle.orderId
      } - ${data.orderId}의 주문상태를 변경하시겠습니까?`,
      httpUrl.orderGoodsStatusUpdate,
      updateData,
      this.getOrderRequestList
    );
  };

  // 주문 상태 목록
  getOrderRequestList = (params = {}) => {
    const {
      startDate,
      endDate,
      sellerIdx,
      status,
      billingType,
      searchType,
      searchText
    } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getOrderRequestList,
      [
        10,
        pageNum,
        startDate,
        endDate,
        sellerIdx,
        status,
        billingType,
        searchType,
        searchText
      ],
      {}
    )
      .then((res) => {
        // console.log(`order goods data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getOrderRequestList();
  }

  render() {
    const {
      sellerFlag,
      selectSeller,
      deliveryFlag,
      orderData,
      historyFlag,
      orderIdx,
      startDate,
      endDate,
      status,
      billingType,
      searchType,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";
    const adminInfo = reactLocalStorage.getObject("adminUser");

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: stringTitle.requestDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.orderId,
        dataIndex: "orderId",
        className: "text-center"
      },
      {
        title: stringTitle.orderUser,
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data}
            <br /> ({row.name})
          </div>
        )
      },
      {
        width: 200,
        title: stringTitle.goodsName,
        dataIndex: "goodsTitle",
        className: "text-center",
        render: (data) => <div className="text-wordWrap-200">{data}</div>
      },
      {
        title: stringTitle.optionName,
        dataIndex: "optionTitle",
        className: "text-center"
      },
      {
        title: stringTitle.quantity,
        dataIndex: "quantity",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.billingType,
        dataIndex: "billingType",
        className: "text-center",
        render: (data) => <div>{string.billingTypeString[data]}</div>
      },
      {
        title: stringTitle.billingPoint,
        dataIndex: "point",
        className: "text-center",
        render: (data, row) => (
          <div>{numberFormat(amountDivideFormat(data) * row.quantity)}</div>
        )
      },
      {
        title: stringTitle.billingPrice,
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data * row.quantity)}</div>
      },
      {
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center"
      },
      {
        title: stringTitle.logistics,
        dataIndex: "logistics",
        className: "text-center",
        render: (data, row) => (
          /* 운송장 번호 입력이 가능한 경우 
            교환 요청중(REPLACE_REQUESTED) /  교환중 (REPLACE_ING)
          */
          <OrderDeliverTitle
            page="order"
            data={row}
            className={
              row.status === "REPLACE_REQUESTED" || row.status === "REPLACE_ING"
                ? "pointer"
                : ""
            }
            disabled={
              row.status === "REPLACE_REQUESTED" || row.status === "REPLACE_ING"
                ? false
                : true
            }
            onClick={() => {
              // console.log(`-- click ${row.status} --`);
              row.status === "REPLACE_REQUESTED" || row.status === "REPLACE_ING"
                ? this.setState({ deliveryFlag: true, orderData: row })
                : this.setState({});
            }}
          />
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.orderStatus[data]}</div>
      },
      {
        // title: stringTitle.status + stringTitle.modify,
        dataIndex: "",
        className: "text-center",
        render: (data, row) =>
          /* REQUESTED - 취소, 교환, 반품 / ING - 교환, 반품 => 다음 상태로 넘어감 */
          row.status !== "CANCEL_COMPLETED" &&
          row.status !== "RETURN_COMPLETED" &&
          row.status !== "REPLACE_COMPLETED" && (
            <OrderStatusTitle
              status={row.status}
              onClick={() => {
                this.goodsOrderStatusUpdate(row, "NAN");
              }}
            />
          )
      }
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: stringTitle.zipCode,
          dataIndex: "orderAddress.zipCode",
          className: "text-center"
        },
        {
          title: stringTitle.address,
          dataIndex: "orderAddress.address",
          className: "text-center",
          render: (data, row) =>
            row.orderAddress.addressDetail === "" ? (
              data
            ) : (
              <div>
                {data}, {row.orderAddress.addressDetail}
              </div>
            )
        },
        {
          title: stringTitle.receiver,
          dataIndex: "orderAddress.receiver",
          className: "text-center"
        },
        {
          title: stringTitle.receiver + stringTitle.mobile,
          dataIndex: "orderAddress.receiverPhone",
          className: "text-center"
        },
        {
          title: stringTitle.memo,
          dataIndex: "orderAddress.memo",
          className: "text-center",
          render: (data) => checkData(data)
        },
        adminInfo.superAdmin === 1
          ? {
              dataIndex: "",
              className: "text-center",
              render: (data, row) =>
                row.status !== "CANCEL_REQUESTED" &&
                row.status !== "CANCEL_COMPLETED" &&
                row.status !== "ORDER_CONFIRM" && (
                  /* 주문 취소 불가인 경우 - 취소요청중, 취소완료, 구매확정 */
                  <Button
                    onClick={() => {
                      this.goodsOrderStatusUpdate(row, "CANCEL");
                    }}
                  >
                    {stringTitle.order + stringTitle.cancel}
                  </Button>
                )
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0
                  }
                };
              }
            }
      ];

      const bankColumns = [
        {
          title: stringTitle.bank,
          dataIndex: "bank",
          className: "text-center"
        },
        {
          title: stringTitle.bankAccount,
          dataIndex: "bankAccount",
          className: "text-center"
        }
      ];

      return (
        <div>
          <Button
            className="m-b-10"
            onClick={() => {
              this.setState({ historyFlag: true, orderIdx: record.idx });
            }}
          >
            {stringTitle.order_goods_histroy}
          </Button>
          <Table
            rowKey={(record) => `record: ${record.idx}`}
            columns={dropColumns}
            dataSource={[record]}
            pagination={false}
            style={{ backgroundColor: "#fff" }}
          />
          {record.billingType === "VIRTUAL_ACCOUNT" &&
            record.returnBankInfo !== null && (
              <div style={{ marginTop: 20 }}>
                <b style={{ fontSize: 18 }}>{stringTitle.returnBankInfo}</b>
                <Table
                  rowKey={(record) => `record: ${record.idx}`}
                  columns={bankColumns}
                  dataSource={[record.returnBankInfo]}
                  pagination={false}
                  style={{ backgroundColor: "#fff" }}
                />
              </div>
            )}
        </div>
      );
    };

    return (
      <div>
        {/* 판매자 목록 */}
        {sellerFlag && (
          <SellerSearchModal
            page="order"
            visible={sellerFlag}
            onCancel={() => {
              this.setState({ sellerFlag: false });
            }}
            onOk={(info) => {
              // console.log(`sellerInfo = ${JSON.stringify(info, null, 4)}`);
              this.setState({ selectSeller: info, sellerIdx: info.idx }, () => {
                this.getOrderRequestList();
              });
            }}
          />
        )}
        {/* 운송장 번호 입력 */}
        {deliveryFlag && (
          <OrderDeliveryModal
            visible={deliveryFlag}
            orderData={orderData}
            onCancel={() => {
              this.setState({ deliveryFlag: false });
            }}
            refresh={this.getOrderRequestList}
          />
        )}
        {/* 주문상태변경 히스토리 */}
        {historyFlag && (
          <OrderGoodsHistoryModal
            visible={historyFlag}
            orderIdx={orderIdx}
            onCancel={() => {
              this.setState({ historyFlag: false });
            }}
          />
        )}
        <div className="title">주문 취소/교환/반품 요청 관리</div>
        <div style={{ clear: "both" }} />
        <div className="flex-row flex-center flex-sb m-b-10">
          {/* 판매자 선택 */}
          <div className="flex-row flex-center">
            <Button
              onClick={() => {
                this.setState({ sellerFlag: true });
              }}
            >
              {stringTitle.seller + stringTitle.select}
            </Button>
            {selectSeller.idx !== undefined && (
              <div className="m-l-10">
                {selectSeller.businessName}&nbsp;
                <Icon
                  type="close"
                  onClick={() => {
                    this.setState({ selectSeller: {}, sellerIdx: 0 }, () =>
                      this.getOrderRequestList()
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex-row flex-center flex-end">
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            <label>
              {stringTitle.status}&nbsp;
              <SelectBox
                style={{ width: "150px", marginRight: 10 }}
                value={string.orderRequestString[status]}
                code={string.orderRequestString}
                codeString={string.orderRequestString}
                onChange={this.onHandleChangeStatus}
              />
            </label>
            <label>
              {stringTitle.billingType}&nbsp;
              <SelectBox
                style={{ width: "150px", marginRight: 10 }}
                value={string.billingTypeString[billingType]}
                code={string.billingType}
                codeString={string.billingTypeString}
                onChange={this.onHandleChangebillingType}
              />
            </label>
            <label>
              {stringTitle.searchRequire}&nbsp;
              <SelectBox
                style={{ width: "150px", marginRight: 10 }}
                value={string.orderSearchTypeString[searchType]}
                code={string.orderSearchTypeString}
                codeString={string.orderSearchTypeString}
                onChange={this.onHandleChangeSearchType}
              />
            </label>
            <Input
              style={{ width: "250px", marginRight: 10 }}
              placeholder={`${stringTitle.title} / ${stringTitle.orderId} / ${stringTitle.orderUser}`}
              allowClear
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getOrderRequestList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default OrderRequestList;
