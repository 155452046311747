import React, { Component } from "react";

import {
  Button,
  Descriptions,
  Modal,
  Icon,
  Input,
  InputNumber,
  Upload,
  Table
} from "antd";

import { httpGet, httpUrl, imageType, serverUrl } from "../../api/httpClient";

import {
  amountDivideFormat,
  amountMultiFormat,
  numberFormat
} from "../../util";
import _const from "../../const";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showUpdateIdxConfirm } from "../../components/common/AlertInfoModal";

import BasicImage from "../../components/common/BasicImage";
import GoodsImage from "../../components/goods/GoodsImage";
import PlatformSelectBox from "../../components/common/PlatformSelectBox";

const Ditems = Descriptions.Item;
class PlatformServiceList extends Component {
  state = {
    platformList: [],
    platformIdx: 0,
    platformData: { images: [] },

    isModify: false,
    changeImages: { images: [], deleteImages: [] },
    changeOptions: { options: [], deleteOptions: [] }
  };

  onHandelChangePlatformIdx = (value) => {
    this.setState({
      platformIdx: value,
      platformData: { images: [] },
      changeImages: { images: [], deleteImages: [] },
      changeOptions: { options: [], deleteOptions: [] }
    });
    this.getPlatformServiceDetail({ idx: value });
  };

  // 이미지 삭제
  onDeleteImage = (value, index, list, type) => {
    const { platformData, changeImages } = this.state;

    list.splice(index, 1);

    // 리스트에 존재했던 이미지만 삭제내역으로 추가
    if (value.idx !== 0) changeImages.deleteImages.push(value.idx);
    // 새로 추가하는 이미지를 지우는 경우
    if (value.idx === 0) {
      changeImages.images.forEach((item) => {
        if (item.photo === value.photo) changeImages.images.splice(value, 1);
      });
    }

    this.setState({
      platformData: { ...platformData, images: list },
      changeImages
    });
  };

  // 이미지 랭크 수정
  onChangeRankImage = (value, index, list, type) => {
    // console.log(`list = ${JSON.stringify(list, null, 4)}`);
    const { platformData, changeImages } = this.state;

    // 랭크 수정
    if (index !== -1)
      list[index] = {
        ...list[index],
        rank: value
      };

    let changeData = {
      ...list[index],
      rank: value === "" ? null : value
    };

    let images = changeImages.images;
    let chageFlag = true;
    if (images.length === 0) {
      chageFlag = false;
      images.push(changeData);
    }
    if (images.length !== 0) {
      // 값이 중복 추가되는거 잡기 위해서 설정
      if (chageFlag) {
        images.forEach((item, iIndex) => {
          // 이미지 랭크 수정 시 이미 있는 경우 삭제
          if (item.photo === changeData.photo) {
            images.splice(iIndex, 1);
            // 바뀐 이미지가 앞에 조건에 의해 삭제 되서 배열의 값이 없는 경우
            if (images.length === 0) images.push(changeData);
          }
        });
        images.push(changeData);
      }
    }
    // console.log(`images - ${JSON.stringify(images, null, 4)}`);

    this.setState({
      platformData: { ...platformData, images: list },
      // changeImages
      changeImages: { ...changeImages, images }
    });
  };

  // 옵션 삭제
  onDeleteOption = (value, index) => {
    const { platformData, changeOptions } = this.state;

    platformData.options.map(
      (item) => item.idx === value.idx && platformData.options.pop(value)
    );
    // 리스트에 존재했던 옵션 삭제내역으로 추가
    if (value.idx !== 0) changeOptions.deleteOptions.push(value.idx);

    this.setState({
      platformData: platformData,
      changeOptions
    });
  };

  // 옵션 수정
  onChangeOption = (value, index, list, type) => {
    const { platformData, changeOptions } = this.state;
    var changeOption = Object.assign({}, list);

    if (type === "title") {
      changeOption = { ...list, title: { ...list.title, content: value } };
    } else if (type === "point")
      changeOption = { ...list, point: amountMultiFormat(value) };
    else changeOption[type] = value;

    let optionList = platformData.options;
    let arr = [];

    optionList.forEach((item) => {
      // 기존에 있던 옵션인 경우
      if (changeOption.idx !== 0)
        if (item.idx === changeOption.idx) {
          arr.push(changeOption);
        } else arr.push(item);
      else {
        // 옵션을 새로 추가한 경우
        if (item.addIdx === changeOption.addIdx) arr.push(changeOption);
        else arr.push(item);
      }
    });

    // 변경되는 데이터만 모으는 작업
    if (changeOptions.options.length === 0)
      changeOptions.options.push(changeOption);
    else
      changeOptions.options.forEach((item, optionIndex) => {
        // 옵션 수정 시 이미 있는 경우 삭제하고 다시 넣어주는 조건
        if (changeOption.idx !== 0)
          if (item.idx === changeOption.idx) {
            changeOptions.options.splice(optionIndex, 1);
            // 바뀐 옵션이 앞에 조건에 의해 삭제 되서 배열의 값이 없는 경우
            if (changeOptions.options.length === 0)
              changeOptions.options.push(changeOption);
          } else changeOptions.options.push(changeOption);
        else {
          // 새로 옵션을 추가한 경우의 옵션을 수정 했을 때
          if (item.addIdx === changeOption.addIdx) {
            changeOptions.options.splice(optionIndex, 1);
            // 바뀐 옵션이 앞에 조건에 의해 삭제 되서 배열의 값이 없는 경우
            if (changeOptions.options.length === 0)
              changeOptions.options.push(changeOption);
          } else changeOptions.options.push(changeOption);
        }
      });
    // console.log(`changeOptions = ${JSON.stringify(changeOptions, null, 4)}`);

    this.setState({
      platformData: {
        ...platformData,
        options: arr
      },
      changeOptions
    });
  };

  // 플랫폼 업데이트
  onUpdate = () => {
    let updateData = {
      idx: this.state.platformData.idx,
      image: this.state.changeImages,
      option: this.state.changeOptions
    };
    // console.log(`update = ${JSON.stringify(updateData, null, 4)}`);

    showUpdateIdxConfirm(
      stringTitle.updateQuest,
      httpUrl.platfromServiceUpdate,
      updateData,
      this.getPlatformServiceDetail,
      this.state.platformIdx
    );
  };

  // 플랫폼 상세 불러기오
  getPlatformServiceDetail = (params = {}) => {
    httpGet(httpUrl.getPlatformServiceDetail, [params.idx], {})
      .then((res) => {
        // console.log(`detail = ${JSON.stringify(res.data.options, null, 4)}`);
        this.setState({
          platformData: res.data,
          isModify: false,
          changeImages: { images: [], deleteImages: [] },
          changeOptions: { options: [], deleteOptions: [] }
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  getPlatformServiceList = (params = {}) => {
    httpGet(httpUrl.getPlatformServiceList, [], {})
      .then((res) => {
        // console.log(`list = ${JSON.stringify(res, null, 4)}`);
        this.setState({ platformList: res.data, platformIdx: res.data[0].idx });
        this.getPlatformServiceDetail({ idx: res.data[0].idx });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getPlatformServiceList();
  }

  render() {
    const { platformList, platformIdx, platformData, changeImages, isModify } =
      this.state;

    const props = {
      action: serverUrl + httpUrl.imageUpload + false,
      multiple: false,
      withCredentials: true,
      className: "upload-list-inline",
      FileList: false,
      beforeUpload: (file) => {
        // console.log(`title before = ${JSON.stringify(file, null, 4)}`);
        if (file.size > _const.imageSize) {
          Modal.error({
            title: "파일 업로드 실패",
            content: <span>파일 크기를 확인해주세요.</span>
          });
          return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
        }
        if (!imageType.find((type) => type === file.type)) {
          Modal.error({
            title: "파일 업로드 실패",
            content: <span>파일 형식을 확인해주세요.</span>
          });
          return false;
        }
      },
      onSuccess: (file) => {
        // console.log(`title success = ${JSON.stringify(file, null, 4)}`);
        if (file.data.result) {
          Modal.info({
            title: "업로드 결과",
            content: "파일 업로드 성공"
          });
          this.setState({
            platformData: {
              ...platformData,
              images: [
                ...platformData.images,
                { idx: 0, photo: file.data.fileId, subPhoto: null }
              ]
            },
            changeImages: {
              ...changeImages,
              images: [
                ...changeImages.images,
                { idx: 0, photo: file.data.fileId, subPhoto: null }
              ]
            }
          });
        }
      },
      onError(err) {
        Modal.error({
          title: "업로드 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    const columns = [
      {
        title: stringTitle.optionName,
        dataIndex: "title",
        className: "text-center",
        render: (data, row, index) =>
          isModify ? (
            <Input
              value={data.content}
              onChange={(e) => {
                this.onChangeOption(e.target.value, index, row, "title");
              }}
            />
          ) : (
            data.content
          )
      },
      {
        title: stringTitle.price,
        dataIndex: "price",
        className: "text-center",
        render: (data, row, index) =>
          isModify ? (
            <InputNumber
              value={data}
              style={{ width: 300 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => {
                this.onChangeOption(value, index, row, "price");
              }}
            />
          ) : (
            numberFormat(data)
          )
      },
      {
        title: stringTitle.point,
        dataIndex: "point",
        className: "text-center",
        render: (data, row, index) =>
          isModify ? (
            <InputNumber
              style={{ width: 300 }}
              value={amountDivideFormat(data)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => {
                this.onChangeOption(value, index, row, "point");
              }}
            />
          ) : (
            numberFormat(amountDivideFormat(data))
          )
      },
      isModify
        ? {
            title: stringTitle.delete,
            dataIndex: "idx",
            className: "text-center",
            render: (data, row, index) => (
              <Button
                onClick={() => {
                  this.onDeleteOption(row, index);
                }}
              >
                {stringTitle.delete}
              </Button>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
    ];

    return (
      <div>
        <div className="title">플랫폼 서비스 관리</div>
        <div style={{ clear: "both" }} />
        <PlatformSelectBox
          list={platformList}
          value={platformIdx}
          className="m-b-10"
          onChange={this.onHandelChangePlatformIdx}
        />

        <Descriptions key={platformData.idx} bordered column={2}>
          <Ditems label={stringTitle.image} span={2}>
            {!isModify ? (
              platformData.images.map((item, index) => (
                <BasicImage
                  key={index}
                  image={item.photo}
                  src={serverUrl + httpUrl.getImage + item.photo}
                  style={{
                    width: "150px",
                    height: "150px",
                    marginRight: index - 1 ? 5 : 0
                  }}
                />
              ))
            ) : (
              <div>
                <Upload {...props} fileList={false} className="m-b-10">
                  <Button className="m-b-10">
                    <Icon type="upload" /> {stringTitle.image + stringTitle.add}
                  </Button>
                </Upload>
                <div style={{ clear: "both" }} />
                <div
                  className="flex-row"
                  style={{
                    overflow: "auto",
                    maxWidth: 1400,
                    paddingBottom: 10
                  }}
                >
                  {platformData.images.map((item, index) => (
                    <GoodsImage
                      key={index}
                      index={index}
                      item={item}
                      list={platformData.images}
                      type="GOODS_CONTENT"
                      onDeleteImage={this.onDeleteImage}
                      onChangeRankImage={this.onChangeRankImage}
                    />
                  ))}
                </div>
              </div>
            )}
          </Ditems>
          <Ditems label={stringTitle.option} span={2}>
            {isModify && (
              <Button
                className="m-b-10 m-t-10"
                onClick={() => {
                  this.setState({
                    platformData: {
                      ...platformData,
                      options: [
                        ...platformData.options,
                        {
                          idx: 0,
                          point: 0,
                          price: 0,
                          addIdx: platformData.options.length,
                          refIdx: platformIdx,
                          title: {
                            content: "",
                            idx: 0
                          }
                        }
                      ]
                    }
                  });
                }}
              >
                {stringTitle.option + stringTitle.add}
              </Button>
            )}
            <Table
              style={{ maxWidth: 1400 }}
              rowKey={(record, index) => index}
              columns={columns}
              dataSource={platformData.options}
              pagination={false}
            />
          </Ditems>
        </Descriptions>
        <div className="flex-row flex-center flex-end m-t-10">
          {!isModify ? (
            <Button
              onClick={() => {
                this.setState({
                  isModify: true
                });
              }}
            >
              {stringTitle.updateFlag}
            </Button>
          ) : (
            <div>
              <Button
                className="m-r-10"
                onClick={() => {
                  this.onUpdate();
                }}
              >
                {stringTitle.update}
              </Button>
              <Button
                onClick={() => {
                  this.setState({ isModify: false });
                }}
              >
                {stringTitle.cancel}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PlatformServiceList;
