import React, { Component } from "react";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  amountDivideFormat,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { showModifyConfirm } from "../../components/common/AlertInfoModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import OrderGoodsModal from "../../components/order/OrderGoodsModal";
import OrderTtitle from "../../components/order/OrderTtitle";

const { RangePicker } = DatePicker;
class OrderList extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      status: "ALL",
      billingType: "ALL",
      searchType: "TITLE",
      searchText: ""
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getOrderList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 주문 상태 변경
  onHandleChangeStatus = (value) => {
    this.setState({
      status: value
    });
  };

  // 결제 상태 변경
  onHandleChangebillingType = (value) => {
    this.setState({
      billingType: value
    });
  };

  // 검색 조건 변경
  onHandleChangeSearchType = (value) => {
    this.setState({
      searchType: value
    });
  };

  // 결제 대기 상태 변경
  goodsStatusUpdate = (data) => {
    let updateDate = {
      idx: data.idx,
      status: "NAN"
    };

    showModifyConfirm(
      `${stringTitle.orderId} - ${data.orderId}의 주문상태를 변경하시겠습니까?`,
      httpUrl.orderStatusUpdate,
      updateDate,
      this.getOrderList
    );
  };

  // 주문 목록
  getOrderList = (params = {}) => {
    const { startDate, endDate, status, billingType, searchType, searchText } =
      this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getOrderList,
      [
        10,
        pageNum,
        startDate,
        endDate,
        status,
        billingType,
        searchType,
        searchText
      ],
      {}
    )
      .then((res) => {
        // console.log(`order data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getOrderList();
  }

  render() {
    const {
      visible,
      selectedData,
      startDate,
      endDate,
      status,
      billingType,
      searchType,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.orderDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.orderId,
        dataIndex: "orderId",
        className: "text-center"
      },
      {
        title: stringTitle.orderUser,
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data}
            <br /> ({row.name})
          </div>
        )
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center pointer",
        render: (data, row) => (
          <OrderTtitle
            titleData={row}
            onClick={() => {
              this.setState({ visible: true, selectedData: row });
            }}
          />
        )
      },
      {
        title: stringTitle.billingType,
        dataIndex: "billingType",
        className: "text-center",
        render: (data) => <div>{string.billingTypeString[data]}</div>
      },
      {
        title: stringTitle.billingPoint,
        dataIndex: "billingPoint",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      },
      {
        title: stringTitle.billingPrice,
        dataIndex: "billingPrice",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.orderStatus[data]}</div>
      },
      {
        dataIndex: "",
        className: "text-center",
        render: (data, row) =>
          row.status === "PAY_READY" ? (
            <Button
              onClick={() => {
                this.goodsStatusUpdate(row);
              }}
            >
              {string.orderStatus["PAY_COMPLETED"]}
            </Button>
          ) : (
            "-"
          )
      }
    ];

    return (
      <div>
        {visible && (
          <OrderGoodsModal
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            selectedData={selectedData}
            refresh={this.getOrderList}
          />
        )}
        <div className="title">주문 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            {stringTitle.searchDate}&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={true}
            />
          </label>
          <label>
            {stringTitle.status}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.orderStatusString[status]}
              code={string.orderStatusCode}
              codeString={string.orderStatusString}
              onChange={this.onHandleChangeStatus}
            />
          </label>
          <label>
            {stringTitle.billingType}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.billingTypeString[billingType]}
              code={string.billingType}
              codeString={string.billingTypeString}
              onChange={this.onHandleChangebillingType}
            />
          </label>
          <label>
            {stringTitle.searchRequire}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.orderSearchTypeString[searchType]}
              code={string.orderSearchTypeString}
              codeString={string.orderSearchTypeString}
              onChange={this.onHandleChangeSearchType}
            />
          </label>
          <Input
            style={{ width: "250px", marginRight: 10 }}
            placeholder={`${stringTitle.title} / ${stringTitle.orderId} / ${stringTitle.orderUser}`}
            allowClear
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.getOrderList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default OrderList;
