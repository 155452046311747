import React, { Component } from "react";

import { Table, Button } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat, checkData } from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showDeletePageNumConfirm } from "../../components/common/AlertInfoModal";

import BannerModal from "../../components/banner/BannerModal";

class BannerList extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    type: "ALL",
    position: 0,

    visible: false,
    edit: false,
    selectedRow: {}
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getBannerList({ pageNum: pager.current });
  };

  // 정렬 대상 설정
  typeHandleChange = (value) => {
    this.setState({
      type: value
    });
  };

  // 삭제 및 복원
  onDelete = (row) => {
    const { pagination, list } = this.state;

    let pageNum =
      pagination.current > 1 && row.deleted === 0
        ? list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    showDeletePageNumConfirm(
      `${stringTitle.position} ${
        row.position === 1 ? "메인" : row.position
      } 배너를 ${
        row.deleted ? stringTitle.restore : stringTitle.delete
      }하시겠습니까?`,
      httpUrl.bannerDelete,
      row,
      this.getBannerList,
      pageNum
    );
  };

  // 배너 목록 조회
  getBannerList = (params = {}) => {
    const { type, position } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(httpUrl.getBannerList, [10, pageNum, type, position], {})
      .then((res) => {
        // console.log(`banner data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getBannerList();
  }

  render() {
    const { visible, edit, selectedRow, type, list, pagination } = this.state;

    const columns = [
      {
        width: 80,
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.rank,
        dataIndex: "rank",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => string.bannerString[data]
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data) => checkData(data)
      },
      {
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center",
        render: (data, row) => <div>{row.type === "GOODS" ? data : "-"}</div>
      },
      {
        title: stringTitle.position,
        dataIndex: "position",
        className: "text-center",
        render: (data) => <div>{data === 1 ? "메인" : data}</div>
      },
      {
        title: "memo",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => checkData(data)
      },
      {
        dataIndex: "modify",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({ visible: true, edit: true, selectedRow: row });
            }}
          >
            {stringTitle.updateFlag}
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {/* 배너 생성 / 수정 */}
        {visible && (
          <BannerModal
            edit={edit}
            visible={visible}
            bannerInfo={selectedRow}
            onCancel={() => {
              this.setState({ visible: false, edit: false, selectedRow: {} });
            }}
            onOk={this.getBannerList}
          />
        )}
        <div className="title">배너관리</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true, edit: false });
            }}
          >
            {stringTitle.create}
          </Button>
          <div>
            <label className="m-r-10">
              {stringTitle.searchRequire}&nbsp;&nbsp;&nbsp;
              <SelectBox
                value={string.bannerString[type]}
                code={string.bannerString}
                codeString={string.bannerString}
                onChange={this.typeHandleChange}
              />
            </label>
            <Button
              onClick={() => {
                this.getBannerList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }
}

export default BannerList;
