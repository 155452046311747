import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  amountDivideFormat,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import PointChargeModal from "../../components/point/PointChargeModal";

const { RangePicker } = DatePicker;
class PointCharge extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let pointList = reactLocalStorage.getObject("pointList");
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      pointType: pointList[0].pointType,
      type: "ALL",
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      searchText: "",

      visible: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getPointChargeList({
      pageNum: pager.current
    });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 포인트 타입 검색
  handleChangePointType = (value) => {
    this.setState({
      pointType: value
    });
  };

  // 타입 검색
  onHandleChangeType = (value) => {
    this.setState({
      type: value
    });
  };

  getPointChargeList = (params = {}) => {
    const { pointType, type, startDate, endDate, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPointChargeList,
      [10, pageNum, pointType, type, startDate, endDate, searchText],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getPointChargeList();
  }

  render() {
    const { visible, startDate, endDate, type, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => formatDate(data)
      },
      {
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => string.pointChargeTypeString[data]
      },
      {
        title: stringTitle.id,
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: stringTitle.name,
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: stringTitle.point,
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) =>
          row.type.includes("RETURN") ? (
            <div> -{numberFormat(amountDivideFormat(data))}</div>
          ) : (
            <div>{numberFormat(amountDivideFormat(data))}</div>
          )
      }
    ];

    return (
      <div>
        {visible && (
          <PointChargeModal
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.getPointChargeList}
          />
        )}
        <div className="title">포인트 충전 목록</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true });
            }}
          >
            {stringTitle.charge}
          </Button>
          <div className="flex-row flex-center">
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            {/* <label className="m-r-10">
              {stringTitle.point}&nbsp;
              <PointSelectBox
                pointList={pointList}
                value={pointType}
                style={{ width: "150px" }}
                onChange={this.handleChangePointType}
              />
            </label> */}
            <label className="m-r-10">
              {stringTitle.type}&nbsp;
              <SelectBox
                style={{ width: "250px" }}
                value={string.pointChargeTypeString[type]}
                code={string.pointChargeTypeString}
                codeString={string.pointChargeTypeString}
                onChange={this.onHandleChangeType}
              />
            </label>
            <Input
              style={{ width: "250px", marginRight: 10 }}
              placeholder="아이디 / 이름"
              allowClear
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getPointChargeList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PointCharge;
