import React, { Component } from "react";

import { Modal, Form, Input, InputNumber } from "antd";

import { httpUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import {
  showCreateConfirm,
  showUpdateCloseRefreshConfirm
} from "../common/AlertInfoModal";

const FormItem = Form.Item;
const CategoryModal = Form.create()(
  class extends Component {
    onUpdate = () => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          let idx = this.props.selectData.idx || 0;
          let data = {
            idx,
            rank: values.rank,
            categoryName: values.categoryName
          };
          // console.log(`updateData = ${JSON.stringify(data, null, 4)}`);
          showUpdateCloseRefreshConfirm(
            stringTitle.updateQuest,
            httpUrl.categoryUpdate,
            data,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    onCreate = () => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          let parentIdx = this.props.parentIdx || 0;
          let data = {
            parentIdx,
            rank: values.rank,
            categoryName: values.categoryName
          };
          // console.log(`createData = ${JSON.stringify(data, null, 4)}`);
          showCreateConfirm(
            stringTitle.createQuest,
            httpUrl.categoryCreate,
            data,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    onSubmit = () => {
      const { type } = this.props;

      if (type === "create") this.onCreate();
      if (type === "update") this.onUpdate();
    };

    componentDidMount() {
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
      const { type, form, selectData } = this.props;

      if (type === "update")
        form.setFieldsValue({
          rank: selectData.rank,
          categoryName: selectData.categoryName
        });
    }

    render() {
      const { visible, onCancel, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={<span>카테고리 정보</span>}
          okText="확인"
          cancelText="취소"
          onOk={this.onSubmit}
          onCancel={onCancel}
          style={{ minWidth: "600px" }}
          maskClosable={false}
        >
          <Form {...formItemLayout} onSubmit={this.onSubmit}>
            <FormItem label={<span>순번&nbsp;</span>}>
              {getFieldDecorator("rank", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(<InputNumber placeholder="ex) 1" style={{ width: 350 }} />)}
            </FormItem>
            <FormItem label={<span>카테고리 이름&nbsp;</span>}>
              {getFieldDecorator("categoryName", {
                rules: [
                  {
                    required: true,
                    message: "카테고리 이름을 입력하게요"
                  }
                ]
              })(<Input placeholder="ex) 뷰티" />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default CategoryModal;
