export const GET_START = "async/GET_START";
export const GET_SUCCESS = "async/GET_SUCCESS";
export const GET_REJECTED = "async/GET_REJECTED";

export const fetchStart = (bool) => {
  return {
    type: GET_START,
    payload: bool
  };
};

export const fetchSuccess = (data) => {
  return {
    type: GET_SUCCESS,
    fetchData: data,
    loading: false
  };
};

export const fetchRejected = (error) => {
  return {
    type: GET_REJECTED,
    payload: error,
    loading: false
  };
};
