import React, { Component } from "react";

import { Form, InputNumber, Modal } from "antd";
import { httpUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import { numberFormat } from "../../util";
import { showRewardConfirm } from "../common/AlertInfoModal";

const FormItem = Form.Item;
const RewardModal = Form.create()(
  class extends Component {
    onSumbit = (e) => {
      const { form, rewardInfo } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          let data = { userIdx: rewardInfo.user.idx, price: values.price };
          // console.log(`data = ${JSON.stringify(data, null, 4)}`);

          showRewardConfirm(
            stringTitle.rewardQuest,
            httpUrl.rewardAdjustment,
            data,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    componentDidMount() {}

    render() {
      const { form, visible, onCancel, rewardInfo } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={stringTitle.reward}
          onOk={this.onSumbit}
          okText={stringTitle.reward}
          onCancel={onCancel}
          cancelText={stringTitle.cancel}
          style={{ minWidth: 600 }}
        >
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.notAdjustment}&nbsp;</span>}>
              <b>{numberFormat(rewardInfo.notAdjustment)}</b>
            </FormItem>
            <FormItem label={<span>{stringTitle.reward}&nbsp;</span>}>
              {getFieldDecorator("price", {
                rules: [
                  {
                    required: true,
                    message: "정산 금액을 입력해주세요!"
                  }
                ]
              })(
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  style={{ width: "200px" }}
                  max={rewardInfo.notAdjustment}
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default RewardModal;
