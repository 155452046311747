import React, { Component } from "react";

import { Button, Input, Table } from "antd";
import { httpGet, httpUrl, serverUrl } from "../../api/httpClient";

import { formatDate, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import UserInfo from "../../components/user/UserInfo";
import BasicImage from "../../components/common/BasicImage";
import { showUpdateRefreshConfirm } from "../../components/common/AlertInfoModal";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import ImagePrevModal from "../../components/common/ImagePrevModal";

class RecommendAccountRequstList extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    status: "ALL",
    searchText: "",

    prevFlag: false,
    imageData: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getRecommendAccountRequstList({ pageNum: pager.current });
  };

  // 정렬 대상 설정
  statusHandleChange = (value) => {
    this.setState({
      status: value
    });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  onStatusUpdate = (data, status) => {
    let updateData = { idx: data.idx, status };
    // console.log(`updateData: ${JSON.stringify(updateData, null, 4)}`);
    showUpdateRefreshConfirm(
      status === "ACCEPT"
        ? "계좌 등록을 승인하시겠습니까?"
        : "계좌 등록을 거절하시겠습니까?",
      httpUrl.recommendAccountUpdate,
      updateData,
      this.getRecommendAccountRequstList
    );
  };

  getRecommendAccountRequstList = (params = {}) => {
    const { status, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getRecommendAccountRequstList,
      [10, pageNum, status, searchText],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getRecommendAccountRequstList();
  }

  render() {
    const { prevFlag, imageData, status, list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.requestDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.user} />
      },
      {
        title: stringTitle.identityNumber,
        dataIndex: "identityNumber",
        className: "text-center"
      },
      {
        title: stringTitle.bankName,
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: stringTitle.bank,
        dataIndex: "bank",
        className: "text-center"
      },
      {
        title: stringTitle.bankAccount,
        dataIndex: "bankAccount",
        className: "text-center"
      },
      {
        title: stringTitle.idCard,
        dataIndex: "idCard",
        className: "text-center",
        render: (data) =>
          data === null ? (
            "-"
          ) : (
            <BasicImage
              image={data}
              src={serverUrl + httpUrl.getImage + data.photo}
              style={{
                cursor: "pointer",
                width: "50px",
                height: "50px"
              }}
              onClick={() => {
                this.setState({ prevFlag: true, imageData: data.photo });
              }}
            />
          )
      },
      {
        title: stringTitle.bankBook,
        dataIndex: "bankBook",
        className: "text-center",
        render: (data) =>
          data === null ? (
            "-"
          ) : (
            <BasicImage
              image={data}
              src={serverUrl + httpUrl.getImage + data.photo}
              style={{
                cursor: "pointer",
                width: "50px",
                height: "50px"
              }}
              onClick={() => {
                this.setState({ prevFlag: true, imageData: data.photo });
              }}
            />
          )
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data, row) =>
          data === "REQUEST" ? (
            <div>
              <Button
                className="m-r-10"
                onClick={() => {
                  this.onStatusUpdate(row, "REJECT");
                }}
              >
                {string.recommenderAccountString["REJECT"]}
              </Button>
              <Button
                onClick={() => {
                  this.onStatusUpdate(row, "ACCEPT");
                }}
              >
                {string.recommenderAccountString["ACCEPT"]}
              </Button>
            </div>
          ) : (
            string.recommenderAccountString[data]
          )
      }
    ];

    return (
      <div>
        {prevFlag && (
          <ImagePrevModal
            visible={prevFlag}
            data={imageData}
            onCancel={() => {
              this.setState({ prevFlag: false, imageData: "" });
            }}
          />
        )}
        <div className="title">정산 계좌 등록 신청 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <SelectBox
            style={{ width: "150px", marginRight: 10 }}
            value={string.recommenderAccountString[status]}
            code={string.recommenderAccountString}
            codeString={string.recommenderAccountString}
            onChange={this.statusHandleChange}
          />
          <Input
            allowClear
            placeholder="검색어를 입력하세요."
            style={{ marginRight: 10, width: 200 }}
            onChange={(e) => {
              this.searchTextHandelChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getRecommendAccountRequstList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default RecommendAccountRequstList;
