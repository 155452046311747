import React, { Component } from "react";

import {
  Button,
  Modal,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Col,
  Tabs,
  Upload
} from "antd";

import { httpGet, httpUrl, imageType, serverUrl } from "../../api/httpClient";

import _const from "../../const";
import {
  amountDivideFormat,
  amountMultiFormat,
  numberFormat
} from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showCreateConfirm } from "../common/AlertInfoModal";

import GoodsCategorySelect from "./GoodsCategorySelect";
import SellerSearchModal from "../common/SellerSearchModal";
import GoodsOptionModal from "./GoodsOptionModal";
import GoodsRequireInfo from "./GoodsRequireInfo";
import BasicImage from "./../common/BasicImage";
import GoodsImage from "./GoodsImage";
import OptionTags from "./OptionTags";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const GoodsModal = Form.create()(
  class extends Component {
    state = {
      topCategory: 0,
      subCategory: 0,
      lastCategory: 0,
      topCategoryList: [{ idx: 0, categoryName: "선택안함" }],
      subCategoryList: [],
      lastCategoryList: [],

      sellerInfo: {},
      tabSelect: "payment",
      optionInfo: [],
      requiredInfo: [],
      requiredIdx: 0,

      mainImage: "",
      titleImages: [],
      contentImages: []
    };

    onSubmit = (e) => {
      const { form } = this.props;
      const {
        tabSelect,
        optionInfo,
        requiredInfo,
        mainImage,
        titleImages,
        contentImages
      } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        let payment =
          tabSelect === "payment"
            ? values.payment
            : Math.floor(
                ((1 - (values.discountRate * 1) / 100) * values.orgPrice) / 10
              ) * 10;

        let price = Math.floor(payment - amountMultiFormat(values.point));

        let discountRate =
          tabSelect !== "payment"
            ? values.discountRate
            : Math.ceil((1 - payment / values.orgPrice) * 100);

        let data = {
          ...values,
          price: price,
          payment: payment,
          discountRate: discountRate,
          point: amountMultiFormat(values.point),
          title: { idx: 0, content: values.title_content },
          content: { idx: 0, content: values.content_content || "" },
          options: optionInfo,
          info: requiredInfo,
          mainImage: mainImage,
          titleImage: titleImages,
          contentImage: contentImages
        };
        // console.log(`data = ${JSON.stringify(data, null, 4)}`);
        if (!err) {
          showCreateConfirm(
            stringTitle.createQuest,
            httpUrl.goodsCreate,
            data,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    // 카테고리 변경
    categoryHandleChange = (value, type) => {
      this.props.form.setFieldsValue({ categoryIdx: value });
      if (type === "top") {
        this.setState({
          topCategory: value,
          subCategory: 0,
          lastCategory: 0,
          subCategoryList: [],
          lastCategoryList: []
        });
        _const.subCategoryFlag &&
          this.getCategoryParentIdxList({ idx: value, type: "sub" });
      }
      if (type === "sub") {
        this.setState({
          categoryIdx: value,
          subCategory: value,
          lastCategory: 0,
          lastCategoryList: []
        });
        this.getCategoryParentIdxList({ idx: value, type: "last" });
      }
      if (type === "last") {
        this.setState({
          categoryIdx: value,
          lastCategory: value
        });
      }
    };

    // 카테고리 리스트
    getCategoryParentIdxList = (params = {}) => {
      httpGet(httpUrl.getCategoryParentIdxList, [params.idx], {})
        .then((res) => {
          if (params.type === "top") {
            // console.log(`category top List = ${JSON.stringify(res, null, 4)}`);
            this.setState({
              topCategoryList: [
                { idx: 0, categoryName: "선택안함" },
                ...res.data
              ]
            });
          }
          if (params.type === "sub") {
            // console.log(`category sub List = ${JSON.stringify(res, null, 4)}`);
            this.setState({
              subCategoryList:
                res.data.length === 0
                  ? []
                  : [{ idx: 0, categoryName: "선택안함" }, ...res.data]
            });
          }
          if (params.type === "last") {
            // console.log(`category last List = ${JSON.stringify(res, null, 4)}`);
            this.setState({
              lastCategoryList:
                res.data.length === 0
                  ? []
                  : [{ idx: 0, categoryName: "선택안함" }, ...res.data]
            });
          }
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    };

    componentDidMount() {
      this.getCategoryParentIdxList({ idx: 0, type: "top" });
      this.props.form.setFieldsValue({
        discountRate: 0,
        orgPrice: 0,
        price: 0,
        payment: 0,
        point: 0,
        deliveryCharge: 0
      });
    }

    render() {
      const { visible, onCancel, form } = this.props;
      const {
        sellerFlag,
        optionFlag,
        optionInfo,
        selectData,
        tabSelect,
        requrieInfoFlag,
        requiredInfo,
        requiredIdx
      } = this.state;

      return (
        <Modal
          visible={visible}
          title="상품 생성"
          style={{ minWidth: 800 }}
          okText={stringTitle.create}
          onOk={this.onSubmit}
          cancelText={stringTitle.cancel}
          onCancel={onCancel}
          maskClosable={false}
        >
          {/* 판매자 선택 */}
          {sellerFlag && (
            <SellerSearchModal
              page="goods"
              visible={sellerFlag}
              onCancel={() => {
                this.setState({ sellerFlag: false });
              }}
              onOk={(sellerInfo) => {
                // console.log(`seller = ${JSON.stringify(sellerInfo, null, 4)}`);
                this.setState({ sellerInfo });
                this.props.form.setFieldsValue({ sellerIdx: sellerInfo.idx });
              }}
            />
          )}
          {/* 옵션 추가 */}
          {optionFlag && (
            <GoodsOptionModal
              type="create"
              selectData={selectData}
              optionInfo={optionInfo}
              visible={optionFlag}
              paymentInfo={{
                payment: form.getFieldsValue().payment,
                price:
                  tabSelect === "payment"
                    ? Math.round(
                        form.getFieldsValue().payment -
                          amountMultiFormat(form.getFieldsValue().point)
                      )
                    : Math.floor(
                        ((1 - (form.getFieldsValue().discountRate * 1) / 100) *
                          form.getFieldsValue().orgPrice) /
                          10
                      ) * 10,
                point: form.getFieldsValue().point
              }}
              onCancel={() => {
                this.setState({ optionFlag: false });
              }}
              onOk={(data) => {
                // console.log(`optionList = ${JSON.stringify(data, null, 4)}`);
                this.setState({ optionInfo: data });
                this.props.form.setFieldsValue({ options: data[0] });
              }}
            />
          )}
          {/* 필수표기정보 */}
          {requrieInfoFlag && (
            <GoodsRequireInfo
              type="create"
              visible={requrieInfoFlag}
              requiredInfo={requiredInfo}
              requiredIdx={requiredIdx}
              onCancel={() => {
                this.setState({ requrieInfoFlag: false });
              }}
              onOk={(info, idx) => {
                this.setState({ requiredInfo: info, requiredIdx: idx });
                this.props.form.setFieldsValue({ info: info[0] });
              }}
            />
          )}
          {this.renderForm()}
        </Modal>
      );
    }

    renderForm() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const {
        topCategory,
        subCategory,
        lastCategory,
        topCategoryList,
        subCategoryList,
        lastCategoryList,
        sellerInfo,
        optionInfo,
        requiredInfo
      } = this.state;
      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Form {...formItemLayout} onSubmit={this.onSubmit}>
          <FormItem label={<span>{stringTitle.category}&nbsp;</span>}>
            {getFieldDecorator("categoryIdx", {
              rules: [
                {
                  required: true,
                  message: "카테고리를 선택해주세요!"
                }
              ]
            })(
              <GoodsCategorySelect
                topCategory={topCategory}
                topCategoryList={topCategoryList}
                subCategory={subCategory}
                subCategoryList={subCategoryList}
                lastCategory={lastCategory}
                lastCategoryList={lastCategoryList}
                onChange={this.categoryHandleChange}
              />
            )}
          </FormItem>
          <FormItem label={<span>{stringTitle.modelName}&nbsp;</span>}>
            {getFieldDecorator("modelName", {
              rules: [
                {
                  required: false,
                  message: "모델명을 입력하세요."
                }
              ]
            })(<Input style={{ width: "450px" }} />)}
          </FormItem>
          <FormItem label={<span>{stringTitle.title}&nbsp;</span>}>
            {getFieldDecorator("title_content", {
              rules: [
                {
                  required: true,
                  message: "제목을 입력하세요."
                }
              ]
            })(<Input style={{ width: "450px" }} />)}
          </FormItem>
          <FormItem label={<span>{stringTitle.content}&nbsp;</span>}>
            {getFieldDecorator("content_content", {
              rules: [
                {
                  required: false,
                  message: "내용을 입력하세요."
                }
              ]
            })(<Input.TextArea rows={4} style={{ width: "450px" }} />)}
          </FormItem>
          <FormItem label={<span>{stringTitle.seller}&nbsp;</span>}>
            {getFieldDecorator("sellerIdx", {
              rules: [
                {
                  required: true,
                  message: "판매자를 선택하세요."
                }
              ]
            })(
              <div>
                {sellerInfo.idx !== undefined && (
                  <span className="m-r-10">
                    {sellerInfo.businessName} / {sellerInfo.owner}
                  </span>
                )}
                <Button
                  onClick={() => {
                    this.setState({ sellerFlag: true });
                  }}
                >
                  {stringTitle.seller}
                  {sellerInfo.idx === undefined
                    ? stringTitle.select
                    : stringTitle.modify}
                </Button>
              </div>
            )}
          </FormItem>
          <FormItem label={<span>{stringTitle.orgPrice}&nbsp;</span>}>
            {getFieldDecorator("orgPrice", {
              rules: [
                {
                  required: true,
                  message: "정가을 입력하세요."
                }
              ]
            })(
              <InputNumber
                style={{ width: "450px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                // onChange={(value) => {
                //   this.onChangeModal(value, "price");
                // }}
              />
            )}
          </FormItem>
          <FormItem label={<span>&nbsp;</span>} colon={false}>
            <Tabs
              defaultActiveKey="payment"
              onChange={(value) => {
                form.setFieldsValue({
                  payment: 0,
                  point: 0,
                  discountRate: 0
                });
                this.setState({ tabSelect: value });
              }}
            >
              <TabPane tab={stringTitle.payment} key="payment">
                <Row>
                  <Col>
                    <label style={{ width: 450 }} className="m-r-10">
                      <span>{stringTitle.payment}&nbsp; : &nbsp;</span>
                      {getFieldDecorator("payment", {
                        rules: [
                          {
                            required: true,
                            message: "판매가격을 입력하세요."
                          }
                        ]
                      })(
                        <InputNumber
                          style={{ width: "150px" }}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      )}
                    </label>
                    <label style={{ width: 450 }}>
                      <span>{stringTitle.discountRate} &nbsp; : &nbsp;</span>
                      {numberFormat(
                        Math.ceil(
                          (1 -
                            form.getFieldsValue().payment /
                              form.getFieldsValue().orgPrice) *
                            100
                        ) || 0
                      )}
                    </label>
                  </Col>
                </Row>
                <Row className="m-t-10">
                  <Col>
                    <label className="m-r-10">
                      <span>{stringTitle.point}&nbsp; : &nbsp;</span>
                      {getFieldDecorator("point", {
                        rules: [
                          {
                            required: true,
                            message: "포인트를 입력하세요."
                          }
                        ]
                      })(
                        <InputNumber
                          style={{ width: "150px" }}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          // onChange={(value) => {
                          //   this.onChangeModal(value, "point");
                          // }}
                        />
                      )}
                    </label>
                    <label>
                      <span>{stringTitle.price}&nbsp; : &nbsp;</span>
                      {numberFormat(
                        Math.round(
                          form.getFieldsValue().payment -
                            amountMultiFormat(form.getFieldsValue().point)
                        )
                      )}
                    </label>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={stringTitle.discountRate} key="discount">
                <Row>
                  <Col>
                    <label className="m-r-10">
                      <span> {stringTitle.discountRate} &nbsp; : &nbsp;</span>
                      {getFieldDecorator("discountRate", {
                        rules: [
                          {
                            required: true,
                            message: "할인율을 입력하세요."
                          }
                        ]
                      })(
                        <InputNumber
                          style={{ width: "150px" }}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      )}
                    </label>
                    <label>
                      <span> {stringTitle.payment} &nbsp; : &nbsp;</span>
                      {numberFormat(
                        Math.floor(
                          ((1 -
                            (form.getFieldsValue().discountRate * 1) / 100) *
                            form.getFieldsValue().orgPrice) /
                            10
                        ) * 10
                      )}
                    </label>
                  </Col>
                </Row>
                <Row className="m-t-10">
                  <Col>
                    <label className="m-r-10">
                      <span>{stringTitle.point}&nbsp; : &nbsp;</span>
                      {getFieldDecorator("point", {
                        rules: [
                          {
                            required: true,
                            message: "포인트를 입력하세요."
                          }
                        ]
                      })(
                        <InputNumber
                          style={{ width: "150px" }}
                          max={amountDivideFormat(
                            Math.floor(
                              (((1 -
                                (form.getFieldsValue().discountRate * 1) /
                                  100) *
                                form.getFieldsValue().orgPrice) /
                                10) *
                                10
                            )
                          )}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          // onChange={(value) => {
                          //   this.onChangeModal(value, "point");
                          // }}
                        />
                      )}
                    </label>
                    <label>
                      <span>{stringTitle.price}&nbsp; : &nbsp;</span>
                      {numberFormat(
                        Math.floor(
                          ((1 -
                            (form.getFieldsValue().discountRate * 1) / 100) *
                            form.getFieldsValue().orgPrice) /
                            10
                        ) *
                          10 -
                          amountMultiFormat(form.getFieldsValue().point)
                      )}
                    </label>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </FormItem>
          <FormItem label={<span>{stringTitle.deliveryCharge}&nbsp;</span>}>
            {getFieldDecorator("deliveryCharge", {
              rules: [
                {
                  required: true,
                  message: "배달료를 입력하세요."
                }
              ]
            })(
              <InputNumber
                style={{ width: "450px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                // onChange={(value) => {
                //   this.onChangeModal(value, "businessNum");
                // }}
              />
            )}
          </FormItem>
          {this.renderImage()}
          <FormItem label={<span>{stringTitle.option}&nbsp;</span>}>
            {getFieldDecorator("options", {
              rules: [
                {
                  required: true,
                  message: "옵션을 추가하세요."
                }
              ]
            })(
              <div className="flex-column">
                <Button
                  onClick={() => {
                    this.setState({ optionFlag: true, selectData: {} });
                  }}
                >
                  {stringTitle.option} {stringTitle.add}
                </Button>
                <div style={{ clear: "both" }} />
                {optionInfo.length !== 0 && <OptionTags list={optionInfo} />}
              </div>
            )}
          </FormItem>
          <FormItem label={<span>{stringTitle.requrieInfo}&nbsp;</span>}>
            {getFieldDecorator("info", {
              rules: [
                {
                  required: true,
                  message: "필수표기정보를 추가하세요."
                }
              ]
            })(
              <Button
                onClick={() => {
                  this.setState({ requrieInfoFlag: true });
                }}
              >
                {stringTitle.requrieInfo}
                {requiredInfo.length === 0
                  ? stringTitle.add
                  : stringTitle.modify}
              </Button>
            )}
          </FormItem>
        </Form>
      );
    }

    // 이미지 업로드
    renderImage() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { mainImage, titleImages, contentImages } = this.state;

      const props = {
        action: serverUrl + httpUrl.imageUpload + true,
        multiple: false,
        withCredentials: true,
        showUploadList: false,
        listType: "picture-card",
        beforeUpload: (file) => {
          // console.log(`main before = ${JSON.stringify(file, null, 4)}`);
          if (file.size > _const.imageSize) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 크기를 확인해주세요.</span>
            });
            return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
          }
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 형식을 확인해주세요.</span>
            });
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`main success = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            this.setState({
              mainImage: {
                idx: 0,
                photo: file.data.fileId,
                subPhoto: file.data.thumbnailId
              }
            });
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const titleUpload = {
        action: serverUrl + httpUrl.imageUpload + false,
        multiple: false,
        withCredentials: true,
        className: "upload-list-inline",
        FileList: false,
        beforeUpload: (file) => {
          // console.log(`title before = ${JSON.stringify(file, null, 4)}`);
          if (file.size > _const.imageSize) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 크기를 확인해주세요.</span>
            });
            return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
          }
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 형식을 확인해주세요.</span>
            });
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`title success = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            this.setState({
              titleImages: [
                ...titleImages,
                { idx: 0, photo: file.data.fileId, subPhoto: null }
              ]
            });
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const contentUpload = {
        action: serverUrl + httpUrl.imageUpload + false,
        multiple: false,
        withCredentials: true,
        className: "upload-list-inline",
        FileList: false,
        beforeUpload: (file) => {
          // console.log(`content before = ${JSON.stringify(file, null, 4)}`);
          if (file.size > _const.imageSize) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 크기를 확인해주세요.</span>
            });
            return false; // 파일 선택시 바로 업로드 하지 않고 후에 한꺼번에 전송하기 위함
          }
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "파일 업로드 실패",
              content: <span>파일 형식을 확인해주세요.</span>
            });
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`content success = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            this.setState({
              contentImages: [
                ...contentImages,
                { idx: 0, photo: file.data.fileId, subPhoto: null }
              ]
            });
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <div>
          <FormItem label={<span>{stringTitle.mainImage}&nbsp;</span>}>
            {getFieldDecorator("mainImage", {
              rules: [
                {
                  required: true,
                  message: "썸네일이미지를 추가하세요."
                }
              ]
            })(
              <Upload {...props}>
                {mainImage ? (
                  <BasicImage
                    image={mainImage.subPhoto}
                    src={serverUrl + httpUrl.getImage + mainImage.subPhoto}
                    style={{ width: "150px", height: "150px" }}
                  />
                ) : (
                  <div>
                    <Icon type={"plus"} />
                    <div className="ant-upload-text">{stringTitle.add}</div>
                  </div>
                )}
              </Upload>
            )}
          </FormItem>
          <FormItem label={<span>{stringTitle.titleImage}&nbsp;</span>}>
            {getFieldDecorator("titleImage", {
              rules: [
                {
                  required: true,
                  message: "대표이미지를 추가하세요."
                }
              ]
            })(
              <div>
                <Upload {...titleUpload} fileList={false}>
                  <Button>
                    <Icon type="upload" /> {stringTitle.titleImage}{" "}
                    {stringTitle.add}
                  </Button>
                </Upload>
                <div
                  className="flex-row flex-center"
                  style={{ overflow: "auto", maxWidth: 600, paddingBottom: 10 }}
                >
                  {titleImages.length !== 0 &&
                    titleImages.map((item, index) => (
                      <GoodsImage
                        key={index}
                        index={index}
                        item={item}
                        list={titleImages}
                        type="title"
                        onDeleteImage={this.onDeleteImage}
                        onChangeRankImage={this.onChangeRankImage}
                      />
                    ))}
                </div>
              </div>
            )}
          </FormItem>
          <FormItem label={<span>{stringTitle.contentImage}&nbsp;</span>}>
            {getFieldDecorator("contentImage", {
              rules: [
                {
                  required: true,
                  message: "상세이미지를 추가하세요."
                }
              ]
            })(
              <div>
                <Upload {...contentUpload} fileList={false}>
                  <Button>
                    <Icon type="upload" /> {stringTitle.contentImage}{" "}
                    {stringTitle.add}
                  </Button>
                </Upload>
                <div
                  className="flex-row flex-center"
                  style={{ overflow: "auto", maxWidth: 600, paddingBottom: 10 }}
                >
                  {contentImages.length !== 0 &&
                    contentImages.map((item, index) => (
                      <GoodsImage
                        key={index}
                        index={index}
                        item={item}
                        list={contentImages}
                        type="content"
                        onDeleteImage={this.onDeleteImage}
                        onChangeRankImage={this.onChangeRankImage}
                      />
                    ))}
                </div>
              </div>
            )}
          </FormItem>
        </div>
      );
    }

    // 이미지 랭크 수정
    onChangeRankImage = (value, index, list, type) => {
      if (index !== -1)
        list[index] = {
          ...list[index],
          rank: value
        };
      // console.log(`list = ${JSON.stringify(list, null, 4)}`);
      if (type === "title") this.setState({ titleImages: list });
      if (type === "content") this.setState({ contentImages: list });
    };

    // 이미지 삭제
    onDeleteImage = (data, index, list, type) => {
      if (type === "title") {
        list.splice(index, 1);
        this.setState({ titleImagea: list });
      }
      if (type === "content") {
        list.splice(index, 1);
        this.setState({ contentImages: list });
      }
    };
  }
);

export default GoodsModal;
