import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Input, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { amountDivideFormat, numberFormat } from "../../util";

import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

class PointBalance extends Component {
  state = {
    pointList: [],

    list: [],
    pagination: { current: 0, total: 0 },
    pointType: 0,
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getPointBalanceList({ pageNum: pager.current });
  };

  // 검색
  onSearch = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 포인트 타입 검색
  handleChangePointType = (value) => {
    this.setState({
      pointType: value
    });
  };

  getPointBalanceList = (params = {}) => {
    const { pointType, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    // console.log(`point = ${JSON.stringify(params.pointType, null, 4)}`);

    httpGet(
      httpUrl.getPointBalanceList,
      [10, pageNum, pointType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`point balance = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    const pointList = reactLocalStorage.getObject("pointList");
    this.setState({
      pointType: pointList[0].pointType
    });
    this.getPointBalanceList();
  }

  render() {
    const { list, pagination } = this.state;
    const columns = [
      {
        title: stringTitle.id,
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: stringTitle.name,
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: stringTitle.point,
        dataIndex: "balance",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      }
    ];

    return (
      <div>
        <div className="title">포인트 보유 목록</div>
        <div className="flex-row flex-center flex-end m-b-10">
          {/* <PointSelectBox
            pointList={pointList}
            value={pointType}
            onChange={this.handleChangePointType}
          /> */}
          <Input
            style={{ width: "250px", marginRight: 10, marginLeft: 10 }}
            placeholder="아이디 / 이름"
            allowClear
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.getPointBalanceList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PointBalance;
