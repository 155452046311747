import { fetchStart, fetchSuccess, fetchRejected } from "../actions/asyncFetch";
import { httpGet, httpUrl } from "../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";

export const getPointList = (params = {}) => {
  return (dispatch) => {
    httpGet(httpUrl.getPointList, [], {})
      .then((res) => {
        // console.log(`pointList = ${JSON.stringify(res, null, 4)}`);
        reactLocalStorage.setObject("pointList", res.data);
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getLogisticsList = (params = {}) => {
  return (dispatch) => {
    httpGet(httpUrl.getLogisticsList, [], {})
      .then((res) => {
        // console.log(`logisticsList = ${JSON.stringify(res, null, 4)}`);
        reactLocalStorage.setObject("logisticsList", res.data);
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchRejected(error));
      });
  };
};
