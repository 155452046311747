import React, { Component } from "react";

import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat } from "../../util";

import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

import UserInfo from "../user/UserInfo";

class RecommendUserSearchModal extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    type: "FRANCHISE",
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getRecommendUserList({ pageNum: pager.current });
  };

  // 타입 변경
  onHandleChangeType = (value) => {
    this.setState({
      type: value
    });
  };

  getRecommendUserList = (params = {}) => {
    const { type } = this.props;
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getRecommendUserList, [10, pageNum, type, searchText], {})
      .then((res) => {
        // console.log(`res -> ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({ list: res.data.list, pagination });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getRecommendUserList();
  }

  render() {
    const { visible, onCancel, onSelect } = this.props;
    const { list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            type="user"
            userData={row.user}
          />
        )
      },
      {
        title: stringTitle.manager,
        dataIndex: "manager.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.manager} />
      },
      {
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              onSelect(row);
              onCancel();
            }}
          >
            {stringTitle.select}
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title={stringTitle.user}
        footer={false}
        style={{ minWidth: 800 }}
      >
        <div className="flex-row flex-center flex-end m-b-10">
          {/* <lable style={{ marginRight: 10 }}>
            {stringTitle.searchRequire}&nbsp;
            <SelectBox
              value={string.recommendUserTypeString[type]}
              code={string.recommendUserTypeString}
              codeString={string.recommendUserTypeString}
              onChange={this.onHandleChangeType}
            />
          </lable> */}
          <Input
            placeholder="회원의 아이디를 입력하세요."
            style={{ marginRight: 10, width: 300 }}
            allowClear
            onChange={(e) => {
              this.setState({
                searchText: e.target.value
              });
            }}
          />
          <Button
            onClick={() => {
              this.getRecommendUserList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default RecommendUserSearchModal;
