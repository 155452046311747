import React, { Component } from "react";

import {
  Button,
  Checkbox,
  Descriptions,
  Modal,
  Icon,
  Input,
  Select
} from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showUpdateConfirm } from "../common/AlertInfoModal";

const Option = Select.Option;
const Ditems = Descriptions.Item;
class GoodsRequireInfo extends Component {
  state = {
    tempList: [],
    tempIdx: "",

    list: [],
    prevList: [],
    changeInfo: [],
    deleteInfo: []
  };

  // 카테고리 옵션 변경
  onChangeCategoryModal = (value) => {
    this.setState({
      tempIdx: value
    });
    this.getGoodsRequireInfoTempTitleList({ infoIdx: value });

    // 옵션 변경시 기존에 있던 리스트 삭제 내용에 추가
    if (this.props.type === "edit") {
      let arr = [];
      this.state.list.forEach((item) => {
        arr.push(item.idx);
      });
      // console.log(`arr = ${JSON.stringify(arr, null, 4)}`);
      this.setState({
        deleteInfo: arr
      });
    }
  };

  // 타이틀 변경 시
  onChangeTitleModal = (value, data, key) => {
    const { list, changeInfo } = this.state;

    list.forEach((item, index) => {
      if (index === key) {
        item.title = value;
        item.title_content = value;
      }
    });

    let changeData = {
      ...data,
      addIdx: key,
      title: { idx: data.titleIdx, content: value },
      content: { idx: data.contentIdx, content: data.content_content }
    };

    // console.log(`list = ${JSON.stringify(list, null, 4)}`);
    if (changeInfo.length === 0) changeInfo.push(changeData);
    else
      changeInfo.forEach((item, index) => {
        if (item.addIdx === changeData.addIdx) {
          changeInfo.splice(index, 1);
          // 앞에 조건에 의해 삭제 되서 배열의 값이 없는 경우
          if (changeInfo.length === 0) changeInfo.push(changeData);
        } else changeInfo.push(changeData);
      });
    // console.log(`title changeInfo = ${JSON.stringify(changeInfo, null, 4)}`);

    this.setState({
      list,
      changeInfo
    });
  };

  // 수정 시
  onChangeUpdateModal = (value, data, key, flag) => {
    const { list, changeInfo } = this.state;

    if (key !== -1)
      list[key] = {
        ...list[key],
        content: {
          content: value
        },
        content_content: value
      };

    let changeData = {
      ...data,
      title: { idx: data.titleIdx, content: data.title_content },
      content: { idx: data.contentIdx, content: value }
    };

    let chageFlag = true;
    // 변경 되는 데이터 추가 작업
    if (changeInfo.length === 0) {
      chageFlag = false;
      changeInfo.push(changeData);
    }
    if (changeInfo.length !== 0) {
      // 값이 중복 추가되는거 잡기 위해서 설정
      if (chageFlag) {
        changeInfo.forEach((item, index) => {
          if (item.title_content === changeData.title_content) {
            changeInfo.splice(index, 1);
            // 바뀐 옵션이 앞에 조건에 의해 삭제 되서 배열의 값이 없는 경우
            if (changeInfo.length === 0) changeInfo.push(changeData);
          }
        });
        changeInfo.push(changeData);
      }
    }

    // console.log(`-- changeInfo = ${JSON.stringify(changeInfo, null, 4)}`);
    // console.log(`list = ${JSON.stringify(list, null, 4)}`);
    this.setState({
      list,
      changeInfo
    });
  };

  // 삭제 시
  onChangeDeleteModal = (data, index) => {
    const { list, deleteInfo, changeInfo } = this.state;

    list.splice(index, 1);
    changeInfo.forEach(
      (item, index) =>
        item.title.content === data.title_content && changeInfo.splice(index, 1)
    );

    if (data.idx !== 0) {
      deleteInfo.push(data.idx);
    }
    this.setState({ list, deleteInfo, changeInfo });
  };

  onUpdate = () => {
    let data = {
      refIdx: this.props.idx,
      info: this.state.changeInfo,
      deleteInfo: this.state.deleteInfo
    };
    // console.log(`deleteData = ${JSON.stringify(data, null, 4)}`);

    showUpdateConfirm(
      stringTitle.updateQuest,
      httpUrl.goodsInfoUpdate,
      data,
      this.getGoodsRequireInfoTempList
    );
  };

  // 생성 시
  onChangeModal = (value, type) => {
    const { list } = this.state;

    list.forEach((item) => {
      if (item.idx === type.idx) {
        item.content_content = value;
      }
    });

    // console.log(`list = ${JSON.stringify(list, null, 4)}`);
    this.setState({
      list
    });
  };

  onTextAll = (e) => {
    const { list } = this.state;

    if (e.target.checked)
      list.forEach(
        (item) => (item.content_content = stringTitle.requireInfoText)
      );

    this.setState({ list });
    if (this.props.type === "edit") {
      let arr = [];
      list.forEach((item) => {
        arr.push({
          idx: item.idx,
          title: { idx: item.titleIdx || 0, content: item.title_content },
          content: {
            idx: item.contentIdx || 0,
            content: stringTitle.requireInfoText
          }
        });
      });
      this.setState({ changeInfo: arr });
    }
  };

  onCreate = () => {
    const { list, tempIdx } = this.state;
    let arr = [];
    list.forEach((item) => {
      arr.push({
        idx: item.idx,
        title: { idx: 0, content: item.title_content },
        content: { idx: 0, content: item.content_content }
      });
    });
    // console.log(`arr = ${JSON.stringify(arr, null, 4)}`);
    this.props.onOk(arr, tempIdx);
    this.props.onCancel();
  };

  // 상품 필수표기정보
  getGoodsRequireInfoList = (params = {}) => {
    httpGet(httpUrl.getGoodsRequireInfoList, [params.idx], {})
      .then((res) => {
        // console.log(`res => ${JSON.stringify(res, null, 4)}`);
        let arr = [];
        res.data.forEach((item, index) => {
          arr.push({
            idx: item.idx,
            titleIdx: item.title.idx,
            title_content: item.title.content,
            contentIdx: item.content.idx,
            content_content: item.content.content
          });
        });
        this.setState({ list: arr, isModify: false });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 필수표기정보 상세 정보불러오기
  getGoodsRequireInfoTempTitleList = (params = {}) => {
    const { requiredIdx, requiredInfo, type } = this.props;
    let infoIdx = params.infoIdx || 0;
    // console.log(`infoIdx = ${JSON.stringify(infoIdx, null, 4)}`);

    httpGet(httpUrl.getGoodsRequireInfoTempList, [infoIdx], {})
      .then((res) => {
        // console.log(`titleTemp = ${JSON.stringify(res, null, 4)}`);
        let arr = [];
        if (type === "create") {
          // 필수 표기 정보 생성 시 옵션 정보 처음 불러올 경우
          res.data.forEach((item) => {
            arr.push({
              idx: item.idx,
              title_content: item.infoName,
              content_content: ""
            });
          });

          // 필수 표기 정보 생성 시 옵션 정보 수정 할 경우
          if (requiredIdx === infoIdx) {
            arr = [];
            requiredInfo.forEach((data) => {
              res.data.forEach((item) => {
                if (item.infoName === data.title.content)
                  arr.push({
                    idx: item.idx,
                    title_content: data.title.content,
                    content_content: data.content.content
                  });
              });
              if (data.idx === 0) {
                arr.push({
                  idx: data.idx,
                  title_content: data.title.content,
                  content_content: data.content.content
                });
              }
            });
            // console.log(`info arr = ${JSON.stringify(arr, null, 4)}`);
          }
        }
        // 필수 표기 정보 수정 시 옵션 정보 처음 불러올 경우
        if (type === "edit") {
          let info = [];
          res.data.forEach((item) => {
            arr.push({
              idx: 0,
              title_content: item.infoName,
              content_content: ""
            });
            info.push({
              idx: 0,
              title: { idx: 0, content: item.infoName },
              content: { idx: 0, content: "" }
            });
          });
          // console.log(`changeInfo= ${JSON.stringify(changeInfo, null, 4)}`);
          this.setState({
            changeInfo: info
          });
        }
        this.setState({ list: arr });
        // console.log(`set arr = ${JSON.stringify(arr, null, 4)}`);
      })
      .catch((error) => {
        // ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 필수표기정보 옵션 선택 불러오기
  getGoodsRequireInfoTempList = (params = {}) => {
    const { type, requiredIdx, idx } = this.props;
    if (params.refresh) this.setState({ changeInfo: [], deleteInfo: [] });

    httpGet(httpUrl.getGoodsRequireInfoTempList, [0], {})
      .then((res) => {
        // console.log(`res => ${JSON.stringify(res, null, 4)}`);
        let tempIdx = res.data[0].idx;
        this.setState({ tempList: res.data });

        if (type === "create") {
          if (requiredIdx !== 0) tempIdx = requiredIdx;
          this.setState({ tempIdx });
          this.getGoodsRequireInfoTempTitleList({ infoIdx: tempIdx });
        }
        if (type === "edit") {
          tempIdx = idx;
          this.getGoodsRequireInfoList({ idx: tempIdx });
        }
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);

    this.getGoodsRequireInfoTempList();
  }

  render() {
    const { visible, onCancel, type } = this.props;
    const { tempList, tempIdx, list, isModify } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="필수표기정보"
        footer={false}
        style={{ minWidth: 800 }}
        maskClosable={false}
      >
        <Select
          value={tempIdx}
          onChange={this.onChangeCategoryModal}
          style={{ width: 600, marginBottom: 10 }}
          disabled={type === "edit" && !isModify ? true : false}
        >
          {tempList.map((item) => (
            <Option key={`${item.idx}`} value={item.idx}>
              {item.infoName}
            </Option>
          ))}
        </Select>
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          {list.map((item, index) => (
            <Ditems
              span={2}
              label={
                <span
                  className="flex-row flex-center"
                  style={{ wordWrap: "break-word", maxWidth: 250 }}
                >
                  {item.titleIdx !== 0 ? (
                    item.title_content
                  ) : (
                    <Input
                      onChange={(e) => {
                        this.onChangeTitleModal(e.target.value, item, index);
                      }}
                    />
                  )}
                  {/* 필수정보 필요 없는 내용 삭제 */}
                  {isModify && (
                    <Icon
                      type="delete"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        this.onChangeDeleteModal(item, index);
                      }}
                    />
                  )}
                </span>
              }
              key={index}
            >
              {type === "create" ? (
                <Input
                  value={item.content_content}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, item);
                  }}
                />
              ) : isModify ? (
                <Input
                  value={item.content_content}
                  onChange={(e) => {
                    this.onChangeUpdateModal(e.target.value, item, index, true);
                  }}
                />
              ) : (
                item.content_content
              )}
            </Ditems>
          ))}
        </Descriptions>
        <div className="flex-row flex-center flex-sb m-t-10">
          <div>
            {(type === "create" || isModify) && (
              <Button
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    prevList: list,
                    list: [
                      ...list,
                      {
                        idx: 0,
                        titleIdx: 0,
                        title_content: "",
                        contentIdx: 0,
                        content_content: "",
                        addIdx: list.length
                      }
                    ]
                  });
                }}
              >
                {stringTitle.add}
              </Button>
            )}
            {(type === "create" || isModify) && (
              <Checkbox onChange={this.onTextAll}>
                {stringTitle.requireInfoText}
              </Checkbox>
            )}
          </div>
          {type === "create" && (
            <Button
              className="flex-end"
              onClick={() => {
                this.onCreate();
              }}
            >
              {stringTitle.check}
            </Button>
          )}
          {type === "edit" &&
            (!isModify ? (
              <Button
                className="flex-end"
                onClick={() => {
                  this.setState({ isModify: true, prevList: list });
                }}
              >
                {stringTitle.updateFlag}
              </Button>
            ) : (
              <div className="flex-row flex-center flex-end">
                <Button
                  className="m-r-10"
                  onClick={() => {
                    this.onUpdate();
                  }}
                >
                  {stringTitle.update}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      isModify: false,
                      list: this.state.prevList
                    });
                  }}
                >
                  {stringTitle.cancel}
                </Button>
              </div>
            ))}
        </div>
      </Modal>
    );
  }
}

export default GoodsRequireInfo;
