import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  Button,
  DatePicker,
  Descriptions,
  Input,
  Modal,
  Table,
  Tabs
} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpUrl, httpGet } from "../../api/httpClient";

import {
  amountDivideFormat,
  checkData,
  endDateFormat,
  formatDate,
  numberFormat,
  phoneFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showUpdateCloseRefreshConfirm } from "../common/AlertInfoModal";

import UserInfo from "./UserInfo";
import OrderTtitle from "../order/OrderTtitle";
import OrderDeliverTitle from "../order/OrderDeliverTitle";

const { TabPane } = Tabs;
const Ditems = Descriptions.Item;
const { RangePicker } = DatePicker;
class UserDetail extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let pointList = reactLocalStorage.getObject("pointList");
    this.state = {
      isModify: false,
      userData: {
        user: {},
        recommender: {},
        manager: {},
        franchise: {},
        service: [],
        account: null
      },

      orderList: [],
      chargeList: [],
      purchaseList: [],
      recommendList: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      pointType: pointList[0].pointType,
      status: "ALL",
      billingType: "ALL",
      type: "ALL",
      searchText: "",
      tab: 1
    };
  }

  // 값 변경
  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.updateInfo);

    if (type === "name")
      data = { ...data, user: { ...data.user, name: value } };
    else data[type] = value;

    this.setState({
      updateInfo: data
    });
  };

  // 회원정보 수정
  onUpdate = () => {
    const { updateInfo } = this.state;
    let data = {
      idx: updateInfo.user.idx,
      name: updateInfo.user.name,
      phone: updateInfo.phone
    };
    // console.log(`data = ${JSON.stringify(data, null, 4)}`);
    showUpdateCloseRefreshConfirm(
      `${updateInfo.user.id}의 회원정보를 ${stringTitle.updateQuest}`,
      httpUrl.userUpdate,
      data,
      this.props.refresh,
      this.props.onCancel
    );
  };

  // 회원 정보 api
  getUserDetail = () => {
    httpGet(httpUrl.userDetail, [this.props.userIdx], {})
      .then((res) => {
        // console.log(`user detail res = ${JSON.stringify(res, null, 4)}`);
        this.setState({ userData: res.data });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };
  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getUserDetail();
    this.getUserOrderList();
  }

  render() {
    const { visible, onCancel, page } = this.props;
    const { isModify, userData, updateInfo } = this.state;

    return (
      <Modal
        visible={visible}
        title={`회원 정보`}
        onCancel={onCancel}
        footer={null}
        maskClosable={false}
        style={{ minWidth: "1400px" }}
      >
        {page === "user" && (
          <div className="flex-row flex-center flex-end m-b-10">
            {!isModify ? (
              <Button
                onClick={() => {
                  this.setState({
                    isModify: true,
                    updateInfo: userData
                  });
                }}
              >
                {stringTitle.updateFlag}
              </Button>
            ) : (
              <div className="flex-row flex-center">
                <Button
                  className="m-r-10"
                  onClick={() => {
                    this.onUpdate();
                  }}
                >
                  {stringTitle.update}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ isModify: false });
                  }}
                >
                  {stringTitle.cancel}
                </Button>
              </div>
            )}
          </div>
        )}
        {/* 회원 정보 */}
        <Descriptions
          className="m-b-10"
          bordered
          key={userData.user.idx}
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Ditems label={stringTitle.joinDate} span={2}>
            {formatDate(userData.createDate)}
          </Ditems>
          <Ditems label={stringTitle.id} span={2}>
            {userData.user.id}
          </Ditems>
          <Ditems label={stringTitle.name}>
            {!isModify ? (
              checkData(userData.user.name)
            ) : (
              <Input
                value={updateInfo.user.name}
                style={{ width: 200 }}
                onChange={(e) => this.onChangeModal(e.target.value, "name")}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.recommendCode}>
            {userData.user.recommendCode}
          </Ditems>
          <Ditems label={stringTitle.mobile}>
            {!isModify ? (
              checkData(phoneFormat(userData.phone))
            ) : (
              <Input
                value={updateInfo.phone}
                style={{ width: 200 }}
                maxLength={11}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9]/g, "");
                  this.onChangeModal(value, "phone");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.recommender}>
            {userData.recommender.name === null ? (
              userData.recommender.recommendCode
            ) : (
              <UserInfo
                userData={userData.recommender}
                style={{ textAlign: "left" }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.point}>
            {numberFormat(amountDivideFormat(userData.pointBalance))}
          </Ditems>
          <Ditems label={stringTitle.serviceName}>
            {userData.service.map((item, index) => (
              <div key={index}>
                {item.name} : {item.count}
                <br />
              </div>
            ))}
          </Ditems>
          <Ditems label={stringTitle.manager}>
            <UserInfo
              userData={userData.manager}
              style={{ textAlign: "left" }}
            />
          </Ditems>
          <Ditems label={stringTitle.franchise}>
            <UserInfo
              userData={userData.franchise}
              style={{ textAlign: "left" }}
            />
          </Ditems>
          {userData.account !== null && (
            <Ditems label={stringTitle.bankAccount} span={2}>
              {stringTitle.name} : <b> {userData.account.name}</b>
              <br />
              {userData.account.bank} {userData.account.bankAccount}
            </Ditems>
          )}
        </Descriptions>
        <div style={{ clear: "both" }} />
        <Tabs
          onChange={(key) => {
            this.onChangeTabs(key);
          }}
        >
          <TabPane tab={stringTitle.order} key="1">
            {this.renderOrder()}
          </TabPane>
          <TabPane tab={stringTitle.point + stringTitle.charge} key="2">
            {this.renderPointCharge()}
          </TabPane>
          <TabPane tab={stringTitle.point + stringTitle.use} key="3">
            {this.renderPointPurchase()}
          </TabPane>
          <TabPane tab={stringTitle.friend} key="4">
            {this.renderRecommender()}
          </TabPane>
        </Tabs>
      </Modal>
    );
  }

  onChangeTabs = (key) => {
    let date = new Date();
    this.setState(
      {
        pagination: { current: 0, total: 0 },
        startDate: startMonthBeforeFormat(date),
        endDate: endDateFormat(date),
        pointType: 0,
        status: "ALL",
        type: "ALL",
        tab: key
      },
      () => {
        if (parseInt(key) === 1) this.getUserOrderList();
        if (parseInt(key) === 2) this.getUserPointChargeList();
        if (parseInt(key) === 3) this.getUserPointPurchaseList();
        if (parseInt(key) === 4) this.getUserRecommenderList();
      }
    );
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    const { tab } = this.state;
    if (parseInt(tab) === 1) this.getUserOrderList({ pageNum: pager.current });
    if (parseInt(tab) === 2)
      this.getUserPointChargeList({ pageNum: pager.current });
    if (parseInt(tab) === 3)
      this.getUserPointPurchaseList({ pageNum: pager.current });
    if (parseInt(tab) === 4)
      this.getUserRecommenderList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 포인트 타입 검색
  handleChangePointType = (value) => {
    this.setState({
      pointType: value
    });
  };

  // 주문 상태 변경
  onHandleChangeStatus = (value) => {
    this.setState({
      status: value
    });
  };

  // 결제 상태 변경
  onHandleChangebillingType = (value) => {
    this.setState({
      billingType: value
    });
  };

  // 타입 변경
  onHandleChangeType = (value) => {
    this.setState({
      type: value
    });
  };

  // 회원 주문 정보 api
  getUserOrderList = (params = {}) => {
    const { startDate, endDate, pointType, status, billingType } = this.state;
    let pageNum = params.pageNum || 1;
    let idx = this.props.userIdx;

    httpGet(
      httpUrl.getUserOrderList,
      [10, pageNum, idx, startDate, endDate, pointType, status, billingType],
      {}
    )
      .then((res) => {
        // console.log(`user order = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          orderList: res.data.list === null ? [] : res.data.list,
          billingPoint: res.data.billingPoint,
          billingPrice: res.data.billingPrice,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  renderOrder() {
    const {
      billingPoint,
      billingPrice,
      startDate,
      endDate,
      status,
      billingType,
      orderList,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 250,
        title: stringTitle.goodsName,
        dataIndex: "goodsTitle",
        className: "text-center",
        render: (data) => <div className="text-wordWrap">{data}</div>
      },
      {
        title: stringTitle.optionName,
        dataIndex: "optionTitle",
        className: "text-center"
      },
      {
        title: stringTitle.quantity,
        dataIndex: "quantity",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.billingType,
        dataIndex: "billingType",
        className: "text-center",
        render: (data) => <div>{string.billingTypeString[data]}</div>
      },
      {
        title: stringTitle.price,
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data * row.quantity)}</div>
      },
      {
        title: stringTitle.point,
        dataIndex: "point",
        className: "text-center",
        render: (data, row) => (
          <div>{numberFormat(amountDivideFormat(data) * row.quantity)}</div>
        )
      },
      {
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: stringTitle.logistics,
        dataIndex: "logistics",
        className: "text-center",
        render: (data, row) => (
          <OrderDeliverTitle page="user" data={row} disabled={true} />
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.orderStatus[data]}</div>
      }
    ];

    return (
      <div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>
            <b className="m-r-10">
              {stringTitle.billingPrice} : {numberFormat(billingPrice)}
            </b>
            <br />
            <b className="m-r-10">
              {stringTitle.billingPoint} : {numberFormat(billingPoint)}
            </b>
          </div>
          <div className="flex-row flex-center">
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            {/* <label className="m-r-10">
            {stringTitle.point}&nbsp;
            <PointSelectBox
              pointList={pointList}
              value={pointType}
              onChange={this.handleChangePointType}
            />
          </label> */}
            <label className="m-r-10">
              {stringTitle.status}&nbsp;
              <SelectBox
                style={{ width: "150px" }}
                value={string.orderGoodsStatusString[status]}
                code={string.orderStatus}
                codeString={string.orderGoodsStatusString}
                onChange={this.onHandleChangeStatus}
              />
            </label>
            <label>
              {stringTitle.billingType}&nbsp;
              <SelectBox
                style={{ width: "150px", marginRight: 10 }}
                value={string.billingTypeString[billingType]}
                code={string.billingType}
                codeString={string.billingTypeString}
                onChange={this.onHandleChangebillingType}
              />
            </label>
            <Input
              style={{ width: "200px", marginRight: 10 }}
              placeholder="상품명"
              allowClear
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getUserOrderList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={orderList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }

  // 회원 포인트 정보 api
  getUserPointChargeList = (params = {}) => {
    const { startDate, endDate, pointType, type } = this.state;
    let pageNum = params.pageNum || 1;
    let idx = this.props.userIdx;

    httpGet(
      httpUrl.getUserPointChargeList,
      [10, pageNum, idx, startDate, endDate, pointType, type],
      {}
    )
      .then((res) => {
        // console.log(`user point charge = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          chargeList: res.data.list === null ? [] : res.data.list,
          pagination,
          totalAmount: res.data.totalAmount
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  renderPointCharge() {
    const { totalAmount, startDate, endDate, type, chargeList, pagination } =
      this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => string.pointTypeString[data]
      },
      {
        title: stringTitle.point,
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) =>
          row.type !== undefined && row.type.includes("RETURN") ? (
            <div> -{numberFormat(amountDivideFormat(data))}</div>
          ) : (
            <div>{numberFormat(amountDivideFormat(data))}</div>
          )
      }
    ];

    return (
      <div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <b>총 비용 : {numberFormat(totalAmount)}</b>
          <div className="flex-row flex-center">
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            {/* <label className="m-r-10">
            {stringTitle.point}&nbsp;
            <PointSelectBox
              pointList={pointList}
              value={pointType}
              onChange={this.handleChangePointType}
            />
          </label> */}
            <label className="m-r-10">
              {stringTitle.type}&nbsp;
              <SelectBox
                style={{ width: 250 }}
                code={string.pointChargeTypeString}
                value={string.pointChargeTypeString[type]}
                codeString={string.pointChargeTypeString}
                onChange={this.onHandleChangeType}
              />
            </label>
            <Input
              style={{ width: "250px", marginRight: 10 }}
              placeholder="상품명"
              allowClear
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getUserPointChargeList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={chargeList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }

  // 회원 포인트 정보 api
  getUserPointPurchaseList = (params = {}) => {
    const { startDate, endDate, pointType, type } = this.state;
    let pageNum = params.pageNum || 1;
    let idx = this.props.userIdx;

    httpGet(
      httpUrl.getUserPointPurchaseList,
      [10, pageNum, idx, startDate, endDate, pointType, type],
      {}
    )
      .then((res) => {
        // console.log(`user point purchase = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          purchaseList: res.data.list === null ? [] : res.data.list,
          totalAmount: res.data.totalAmount,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  renderPointPurchase() {
    const { totalAmount, startDate, endDate, type, purchaseList, pagination } =
      this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data, row) => (
          <OrderTtitle
            titleData={row}
            onClick={() => {
              this.setState({ visible: true, selectedData: row });
            }}
          />
        )
      },
      {
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => string.pointPurchageTypeString[data]
      },
      {
        title: stringTitle.point,
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      }
    ];

    return (
      <div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <b>총 비용 : {numberFormat(totalAmount)}</b>
          <div>
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            {/* <label className="m-r-10">
            {stringTitle.point}&nbsp;
            <PointSelectBox
              pointList={pointList}
              value={pointType}
              onChange={this.handleChangePointType}
            />
          </label> */}
            <label className="m-r-10">
              {stringTitle.type}&nbsp;
              <SelectBox
                style={{ width: "150px" }}
                code={string.pointPurchageType}
                value={string.pointPurchageTypeSearch[type]}
                codeString={string.pointPurchageTypeSearch}
                onChange={this.onHandleChangeType}
              />
            </label>
            <Input
              style={{ width: "250px", marginRight: 10 }}
              placeholder="상품명"
              allowClear
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getUserPointPurchaseList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.refIdx + record.type}
          columns={columns}
          dataSource={purchaseList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }

  // 추천인 정보
  getUserRecommenderList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;
    let idx = this.props.userIdx;

    httpGet(httpUrl.getUserRecommenderList, [10, pageNum, idx, searchText], {})
      .then((res) => {
        // console.log(`user recommender = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          recommendList: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  renderRecommender() {
    const { recommendList, pagination } = this.state;
    const columns = [
      {
        key: "user.idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            type="user"
            userData={row.user}
          />
        )
      },
      {
        title: stringTitle.recommender,
        dataIndex: "recommender.recommendCode",
        className: "text-center",
        render: (data, row) =>
          row.recommender.name === null ? (
            data
          ) : (
            <UserInfo userData={row.recommender} />
          )
      },
      {
        title: stringTitle.manager,
        dataIndex: "manager.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.manager} />
      },
      {
        title: stringTitle.franchise,
        dataIndex: "franchise.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.franchise} />
      }
    ];
    return (
      <div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Input
            allowClear
            placeholder={stringTitle.id + " / " + stringTitle.name}
            style={{ width: 200, marginRight: 10 }}
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.getUserRecommenderList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={recommendList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default UserDetail;
