import React, { Component } from "react";

import { Table, Button, Input } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import UserInfo from "../../components/user/UserInfo";
import UserDetail from "../../components/user/UserDetail";
import RecommendDetailList from "../../components/recommend/RecommendDetailList";
import RecommendAdjustmentLogModal from "../../components/recommend/RecommendAdjustmentLogModal";
import RewardModal from "../../components/recommend/RewardModal";

class RecommendList extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    type: "ALL",
    searchText: "",

    detailFlag: false,
    rewardFlag: false,
    adjustmentFlag: false,
    visible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getRecommendList({ pageNum: pager.current });
  };

  // 정렬 대상 설정
  typeHandleChange = (value) => {
    this.setState({
      type: value
    });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  getRecommendList = (params = {}) => {
    const { type, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(httpUrl.getRecommendList, [10, pageNum, type, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  componentDidMount() {
    this.getRecommendList();
  }

  render() {
    const {
      visible,
      userData,
      idx,
      type,
      rewardFlag,
      selectData,
      adjustmentFlag,
      detailFlag,
      list,
      pagination
    } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 300,
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            pointer="pointer"
            type="user"
            userData={row.user}
            onClick={() => {
              this.setState({
                idx: row.user.idx,
                userData: row,
                visible: true
              });
            }}
          />
        )
      },
      {
        title: stringTitle.bankAccount,
        dataIndex: "account",
        className: "text-center",
        render: (data) =>
          data === null ? (
            "-"
          ) : (
            <div>
              {data.name}
              <br />
              {data.bank} {data.bankAccount}
            </div>
          )
      },
      {
        title: `${stringTitle.recommender} / ${stringTitle.manager}`,
        dataIndex: "recommender.recommendCode",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.recommender.name === null
              ? row.recommender.recommendCode
              : row.recommender.name}{" "}
            / {row.manager.name}
          </div>
        )
      },
      // {
      //   title: stringTitle.recommender,
      //   dataIndex: "recommender.recommendCode",
      //   className: "text-center",
      //   render: (data, row) =>
      //     row.recommender.name === null ? (
      //       data
      //     ) : (
      //       <UserInfo userData={row.recommender} />
      //     )
      // },
      // {
      //   title: stringTitle.point,
      //   dataIndex: "pointBalance",
      //   className: "text-center",
      //   render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      // },
      // {
      //   title: stringTitle.serviceName,
      //   dataIndex: "service",
      //   className: "text-center",
      //   render: (data) =>
      //     data.map((item, index) => (
      //       <div key={index}>
      //         {item.name} : {item.count}
      //         <br />
      //       </div>
      //     ))
      // },
      // {
      //   title: stringTitle.manager,
      //   dataIndex: "manager.id",
      //   className: "text-center",
      //   render: (data, row) =>
      //     row.user.level !== 10 ? <UserInfo userData={row.manager} /> : "-"
      // },
      {
        title: stringTitle.totalReward,
        dataIndex: "totalReward",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.totalAdjustment,
        dataIndex: "totalAdjustment",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.notAdjustment,
        dataIndex: "notAdjustment",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.preRecReward,
        dataIndex: "recReward",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data + row.orderReward)}</div>
      },
      {
        dataIndex: "reward",
        className: "text-center",
        render: (data, row) => (
          <Button
            disabled={row.notAdjustment === 0 ? true : false}
            onClick={() => {
              this.setState({ rewardFlag: true, selectData: row });
            }}
          >
            {stringTitle.rewardFlag}
          </Button>
        )
      },
      {
        dataIndex: "adjustment",
        className: "text-center",
        render: (data, row) => (
          <Button
            disabled={row.totalAdjustment === 0 ? true : false}
            onClick={() => {
              this.setState({ adjustmentFlag: true, idx: row.user.idx });
            }}
          >
            {stringTitle.adjustmentFlag}
          </Button>
        )
      }
      // {
      //   dataIndex: "friend",
      //   className: "text-center",
      //   render: (data, row) => (
      //     <Button
      //       onClick={() => {
      //         this.setState({ detailFlag: true, idx: row.user.idx });
      //       }}
      //     >
      //       친구목록
      //     </Button>
      //   )
      // }
    ];

    return (
      <div>
        {/* 회원정보 */}
        {visible && (
          <UserDetail
            visible={visible}
            userIdx={idx}
            userData={userData}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
          />
        )}
        {/* 정산 */}
        {rewardFlag && (
          <RewardModal
            visible={rewardFlag}
            rewardInfo={selectData}
            onCancel={() => {
              this.setState({ rewardFlag: false });
            }}
            refresh={this.getRecommendList}
          />
        )}
        {/* 정산목록 */}
        {adjustmentFlag && (
          <RecommendAdjustmentLogModal
            visible={adjustmentFlag}
            idx={idx}
            onCancel={() => {
              this.setState({ adjustmentFlag: false });
            }}
          />
        )}
        {/* 친구목록 */}
        {detailFlag && (
          <RecommendDetailList
            visible={detailFlag}
            idx={idx}
            onCancel={() => {
              this.setState({ detailFlag: false });
            }}
          />
        )}
        <div className="title">대리점 / 총판 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label>
            {stringTitle.searchRequire}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.recommendTypeString[type]}
              code={string.recommendTypeCode}
              codeString={string.recommendTypeString}
              onChange={this.typeHandleChange}
            />
          </label>
          <Input
            placeholder="검색어를 입력하세요."
            style={{ marginRight: 10, width: 200 }}
            onChange={(e) => {
              this.searchTextHandelChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getRecommendList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default RecommendList;
