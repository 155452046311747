import React, { Component } from "react";

import { Button, Table, Modal } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import CategoryModal from "./CategoryModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showDeleteIdxConfirm } from "../common/AlertInfoModal";

class SelectCategoryList extends Component {
  state = {
    list: [],
    createFlag: false,
    clickFlag: false
  };

  // 삭제
  onDelete = (params) => {
    let idx = params.idx;
    // console.log(`idx = ${JSON.stringify(idx, null, 4)}`);
    showDeleteIdxConfirm(
      stringTitle.deleteQuest,
      httpUrl.categoryDelete,
      idx,
      this.getCategoryParentIdxList
    );
  };

  getCategoryParentIdxList = (params) => {
    httpGet(httpUrl.getCategoryParentIdxList, [this.props.parentIdx], {})
      .then((res) => {
        // console.log(`categoryList = ${JSON.stringify(res, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getCategoryParentIdxList();
  }

  render() {
    const { page, visible, onCancel, parentIdx } = this.props;
    const { list, createFlag, type, selectData, clickFlag, selectIdx } =
      this.state;
    const columns = [
      {
        key: "rank",
        title: stringTitle.rank,
        dataIndex: "rank",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.categoryName,
        dataIndex: "categoryName",
        className: "text-center"
      },
      !page
        ? {
            title: stringTitle.categoryDepth,
            dataIndex: "child",
            className: "text-center",
            render: (data, row) => (
              <Button
                // disabled={data.length === 0 ? true : false}
                onClick={() => {
                  this.setState({
                    selectIdx: row.idx,
                    clickFlag: true
                  });
                }}
              >
                {stringTitle.viewDetail}
              </Button>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      {
        title: "",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  type: "update",
                  createFlag: true,
                  selectData: row
                });
              }}
            >
              {stringTitle.update}
            </Button>
            <Button
              onClick={() => {
                this.onDelete({ idx: row.idx });
              }}
            >
              {stringTitle.delete}
            </Button>
          </div>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={stringTitle.categoryDepth}
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}
        footer={false}
        maskClosable={false}
      >
        {clickFlag && (
          <SelectCategoryList
            page="detail"
            visible={clickFlag}
            parentIdx={selectIdx}
            onCancel={() => {
              this.setState({ clickFlag: false });
            }}
          />
        )}
        {createFlag && (
          <CategoryModal
            type={type}
            visible={createFlag}
            parentIdx={parentIdx}
            selectData={selectData}
            refresh={this.getCategoryParentIdxList}
            onCancel={() => {
              this.setState({ createFlag: false });
            }}
          />
        )}
        <Button
          className="m-b-10"
          onClick={() => {
            this.setState({ type: "create", createFlag: true });
          }}
        >
          {stringTitle.create}
        </Button>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default SelectCategoryList;
