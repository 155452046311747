import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Form, Input, Modal, Select } from "antd";

import { httpUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import { showDeliveryConfirm } from "../common/AlertInfoModal";

const FormItem = Form.Item;
const Option = Select.Option;
const OrderDeliveryModal = Form.create()(
  class extends Component {
    state = {};

    onRegistry = (e) => {
      const { form, orderData } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          let data = { idx: orderData.idx, ...values };
          // console.log(`data = ${JSON.stringify(data, null, 4)}`);
          showDeliveryConfirm(
            `운송장을 ${
              orderData.logistics === null
                ? stringTitle.registQuest
                : stringTitle.modifyQuest
            }`,
            httpUrl.orderGoodsDeliveryUpdate,
            data,
            orderData,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    componentDidMount() {
      const { orderData, form } = this.props;

      if (orderData.logistics !== null) {
        form.setFieldsValue({
          logistics: orderData.logistics,
          invoice: orderData.invoice
        });
      }
    }

    render() {
      const { visible, form, onCancel, orderData } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={stringTitle.invoice}
          okText={
            orderData.logistics === null
              ? stringTitle.regist
              : stringTitle.modify
          }
          cancelText={stringTitle.cancel}
          onOk={this.onRegistry}
          onCancel={onCancel}
          style={{ minWidth: "600px" }}
          maskClosable={false}
        >
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            <FormItem label={<span>{stringTitle.logistics}&nbsp;</span>}>
              {getFieldDecorator("logistics", {
                rules: [
                  {
                    required: false,
                    message: "택배사를 선택해주세요!"
                  }
                ]
              })(
                <Select
                  showSearch
                  style={{ width: "280px" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.renderOption()}
                </Select>
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.invoice}&nbsp;</span>}>
              {getFieldDecorator("invoice", {
                rules: [
                  {
                    required: false,
                    message: "운송장번호를 입력해주세요!"
                  }
                ]
              })(<Input allowClear style={{ width: "280px" }} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }

    renderOption = () => {
      const logisticsList = reactLocalStorage.getObject("logisticsList");
      let result = [];

      for (let i = 0; i < logisticsList.length; i++) {
        result.push(
          <Option value={logisticsList[i].code} key={`${logisticsList[i]}${i}`}>
            {logisticsList[i].name}
          </Option>
        );
      }

      return result;
    };
  }
);

export default OrderDeliveryModal;
