import React, { Component } from "react";

import { Select } from "antd";

const Option = Select.Option;
export default class PointSelectBox extends Component {
  state = {};

  renderOption = () => {
    const { pointList } = this.props;
    let result = [];

    for (let i = 0; i < pointList.length; i++) {
      result.push(
        <Option value={pointList[i].pointType} key={`${pointList[i]}${i}`}>
          {pointList[i].symbol}
        </Option>
      );
    }

    return result;
  };

  render() {
    const { size, mode, style, placeholder, value, disabled, onChange } =
      this.props;
    return (
      <Select
        {...this.props}
        size={size}
        mode={mode}
        style={{ width: "150px", ...style }}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={(value) => {
          onChange(value, "pointType");
        }}
      >
        {this.renderOption()}
      </Select>
    );
  }
}
