import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";

import {
  AdjustmentList,
  AdjustmentStatList,
  Admin,
  AdminLoginLog,
  AdministratorAudit,
  BannerList,
  Faq,
  GoodsCategoryList,
  GoodsKeyword,
  GoodsList,
  MemberList,
  MyInfo,
  Notice,
  OrderGoodsList,
  OrderList,
  OrderPointList,
  OrderRequestList,
  PlatformRequestList,
  PlatformServiceList,
  PointBalance,
  PointCharge,
  PointPurchase,
  RecommendAccountRequstList,
  RecommendLevelUpLog,
  RecommendList,
  RewardList,
  SellerList,
  Service,
  UserAudit
} from "../pages";

export default class Content extends Component {
  render() {
    return (
      <Layout.Content style={{ margin: "24px 16px 0" }}>
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: "82vh",
            minWidth: "1280px"
          }}
        >
          <Switch>
            {/* 상품관리 */}
            <Route path="/goods/goods" component={GoodsList} />
            <Route path="/goods/category" component={GoodsCategoryList} />
            <Route path="/goods/keyword" component={GoodsKeyword} />
            {/* 판매자관리 */}
            <Route path="/seller" component={SellerList} />
            {/* 회원관리 */}
            <Route path="/user/memberList" component={MemberList} />
            {/* 사업자관리 */}
            <Route path="/recommend/list" component={RecommendList} />
            <Route
              path="/recommend/levelUpLog"
              component={RecommendLevelUpLog}
            />
            <Route path="/recommend/reward" component={RewardList} />
            <Route path="/recommend/adjustment" component={AdjustmentList} />
            <Route
              path="/recommend/stat/adjustment"
              component={AdjustmentStatList}
            />
            <Route
              path="/recommend/accountRequest"
              component={RecommendAccountRequstList}
            />
            {/* 포인트관리 */}
            <Route path="/point/balance" component={PointBalance} />
            <Route path="/point/charge" component={PointCharge} />
            <Route path="/point/purchase" component={PointPurchase} />
            {/* 주문관리 */}
            <Route path="/order/order" component={OrderList} />
            <Route path="/order/goods" component={OrderGoodsList} />
            <Route path="/order/request" component={OrderRequestList} />
            <Route path="/order/pointGoods" component={OrderPointList} />
            {/* 플랫폼관리 */}
            <Route path="/platform/service" component={PlatformServiceList} />
            <Route path="/platform/request" component={PlatformRequestList} />
            {/* 배너관리 */}
            <Route path="/banner" component={BannerList} />
            {/* 게시글관리 */}
            <Route path="/community/notice" component={Notice} />
            <Route path="/community/faq" component={Faq} />
            {/* 감사관리 */}
            <Route path="/audit/admin" component={AdministratorAudit} />
            <Route path="/audit/user" component={UserAudit} />
            {/* 사용자관리 */}
            <Route path="/admin/loginLog" component={AdminLoginLog} />
            {/* 시스템관리 */}
            <Route path="/system/admin" component={Admin} />
            <Route path="/system/service" component={Service} />
            {/* 정보수정 */}
            <Route path="/mypage/myinfo" component={MyInfo} />
          </Switch>
        </div>
      </Layout.Content>
    );
  }
}
