import stringTitle from "./stringTitle";

const string = {
  // 상품
  goodsSearchTypeCode: ["ALL", "SELLER", "TITLE"],
  goodsSearchTypeString: {
    ALL: stringTitle.all,
    SELLER: stringTitle.seller,
    TITLE: stringTitle.title
  },
  goodsOrderByCode: ["IDX", "RANK"],
  goodsOrderByString: {
    IDX: stringTitle.idx,
    RANK: stringTitle.rank
  },
  keywordCode: ["WORD", "CNT"],
  keywordString: {
    WORD: stringTitle.word,
    CNT: stringTitle.cnt
  },

  // 친구
  recommendTypeCode: ["ALL", "MANAGER", "FRANCHISE"],
  recommendTypeString: {
    ALL: stringTitle.all,
    MANAGER: stringTitle.manager,
    FRANCHISE: stringTitle.franchise
  },
  recommendUserTypeString: {
    MANAGER: stringTitle.manager,
    FRANCHISE: stringTitle.franchise
  },
  recommenderAccountString: {
    ALL: stringTitle.all,
    REQUEST: stringTitle.request,
    REJECT: stringTitle.reject,
    ACCEPT: stringTitle.accept
  },

  // 판매자
  sellerSearchTypeCode: ["ALL", "NAME", "NUM", "MOB_NUM"],
  sellerSearchTypeString: {
    ALL: stringTitle.all,
    NAME: stringTitle.businessName,
    NUM: stringTitle.businessNum,
    MOB_NUM: stringTitle.mobNum
  },

  // 사업자
  rewardType: {
    DIRECT_RECRUIT: stringTitle.levelup_franchise,
    DIRECT_RECRUIT_ALL: stringTitle.levelup_manager,
    INDIRECT_RECRUIT: stringTitle.levelup_manager,
    FRANCHISE_REC: stringTitle.goods_franchise_rec,
    MANAGER: stringTitle.goods_manager,
    FRANCHISE: stringTitle.goods_franchise,
    MANAGER_ALL: stringTitle.goods_manager
  },
  rewardTypeString: {
    ALL: stringTitle.all,
    MANAGER: stringTitle.manager,
    FRANCHISE: stringTitle.franchise
  },

  // 포인트
  pointTypeCode: ["ALL", "AIRDROP"],
  pointTypeString: {
    ALL: stringTitle.all,
    AIRDROP: stringTitle.airdrop,
    CHARGE: stringTitle.point_charge,
    CHARGE_REC: stringTitle.point_charge_rec,
    SIGN_UP_EVENT: stringTitle.point_sign_up,
    SIGN_UP_EVENT_REC: stringTitle.point_sign_up_rec,
    ORDER_PLATFORM_SERVICE: stringTitle.point_order_platform,
    ORDER: stringTitle.point_order,
    ORDER_REC: stringTitle.point_order_rec,
    RETURN_POINT: stringTitle.point_return, // 포인트 회수
    RETURN_POINT_REC: stringTitle.point_return_rec // 추천인 포인트 회수
  },
  pointChargeTypeString: {
    ALL: stringTitle.all,
    AIRDROP: stringTitle.airdrop,
    SIGN_UP_EVENT: stringTitle.point_sign_up,
    SIGN_UP_EVENT_REC: stringTitle.point_sign_up_rec,
    ORDER: stringTitle.point_order,
    ORDER_REC: stringTitle.point_order_rec,
    RETURN_POINT: stringTitle.point_return, // 포인트 회수
    RETURN_POINT_REC: stringTitle.point_return_rec // 추천인 포인트 회수
  },
  pointPurchageType: [
    "ALL",
    "ORDER",
    "RETURN_ORDER",
    "ORDER_PLATFORM_SERVICE",
    "RETURN_PLATFORM_SERVICE"
  ],
  pointPurchageTypeString: {
    ALL: stringTitle.all,
    ORDER: stringTitle.order,
    RETURN_ORDER: stringTitle.retrun,
    RETURN_ORDER_GOODS: stringTitle.retrun,
    ORDER_PLATFORM_SERVICE: stringTitle.point_order_platform,
    RETURN_PLATFORM_SERVICE: stringTitle.point_return_platform
  },
  pointPurchageTypeSearch: {
    ALL: stringTitle.all,
    ORDER: stringTitle.order,
    RETURN_ORDER: stringTitle.retrun,
    ORDER_PLATFORM_SERVICE: stringTitle.point_order_platform,
    RETURN_PLATFORM_SERVICE: stringTitle.point_return_platform
  },

  // 주문
  orderStatusCode: [
    "ALL",
    "PAY_READY",
    "PAY_COMPLETED",
    "PAY_CANCELED",
    "ORDER_COMPLETED",
    "ORDER_READY",
    "ORDER_COMPLETED",
    "PREPARATION",
    "SHIPPING",
    "DELIVERY_COMPLETED",
    "ORDER_CONFIRM",
    "CANCEL_REQUESTED",
    "CANCEL_COMPLETED",
    "RETURN_REQUESTED",
    "RETURN_ING",
    "RETURN_COMPLETED",
    "REPLACE_REQUESTED",
    "REPLACE_ING",
    "REPLACE_COMPLETED"
  ],
  orderStatus: {
    ALL: stringTitle.all,
    PAY_READY: stringTitle.pay_ready,
    PAY_COMPLETED: stringTitle.pay_completed,
    PAY_CANCELED: stringTitle.pay_cancel,
    ORDER_READY: stringTitle.order_ready,
    ORDER_COMPLETED: stringTitle.order_completed,
    PREPARATION: stringTitle.preparation,
    SHIPPING: stringTitle.shipping,
    DELIVERY_COMPLETED: stringTitle.delivery_completed,
    ORDER_CONFIRM: stringTitle.order_confirm,
    CANCEL_REQUESTED: stringTitle.cancel_request,
    CANCEL_COMPLETED: stringTitle.cancel_completed,
    PARTIAL_CANCEL_REQUESTED: stringTitle.partial_cancel_request,
    PARTIAL_CANCEL_COMPLETED: stringTitle.partial_cancel_completed,
    RETURN_REQUESTED: stringTitle.return_request,
    RETURN_ING: stringTitle.return_ing,
    RETURN_COMPLETED: stringTitle.return_completed,
    REPLACE_REQUESTED: stringTitle.replace_request,
    REPLACE_ING: stringTitle.replace_ing,
    REPLACE_COMPLETED: stringTitle.replace_completed
  },
  orderStatusString: {
    ALL: stringTitle.all,
    PAY_READY: stringTitle.pay_ready,
    ORDER_COMPLETED: stringTitle.order_completed,
    CANCEL_REQUESTED: stringTitle.cancel_request,
    CANCEL_COMPLETED: stringTitle.cancel_completed,
    PARTIAL_CANCEL_REQUESTED: stringTitle.partial_cancel_request,
    PARTIAL_CANCEL_COMPLETED: stringTitle.partial_cancel_completed
  },
  orderGoodsStatusString: {
    ALL: stringTitle.all,
    ORDER_READY: stringTitle.order_ready,
    ORDER_COMPLETED: stringTitle.order_completed,
    PREPARATION: stringTitle.preparation,
    SHIPPING: stringTitle.shipping,
    DELIVERY_COMPLETED: stringTitle.delivery_completed,
    ORDER_CONFIRM: stringTitle.order_confirm,
    CANCEL_REQUESTED: stringTitle.cancel_request,
    CANCEL_COMPLETED: stringTitle.cancel_completed,
    RETURN_REQUESTED: stringTitle.return_request,
    RETURN_ING: stringTitle.return_ing,
    RETURN_COMPLETED: stringTitle.return_completed,
    REPLACE_REQUESTED: stringTitle.replace_request,
    REPLACE_ING: stringTitle.replace_ing,
    REPLACE_COMPLETED: stringTitle.replace_completed
  },
  billingType: ["CARD", "VIRTUAL_ACCOUNT", "ACCOUNT_TRANSFER"],
  billingTypeString: {
    ALL: stringTitle.all,
    CARD: stringTitle.card,
    VIRTUAL_ACCOUNT: stringTitle.virtual_account,
    ACCOUNT_TRANSFER: stringTitle.transfer_account
  },
  orderSearchTypeCode: [],
  orderSearchTypeString: {
    TITLE: stringTitle.title,
    USER: stringTitle.orderUser,
    ORDER_ID: stringTitle.orderId
  },
  orderRequestString: {
    ALL: stringTitle.all,
    REQUESTED: stringTitle.request,
    ING: stringTitle.ing,
    COMPLETED: stringTitle.complete
  },
  orderPointStatusString: {
    ALL: stringTitle.all,
    ORDER_READY: stringTitle.order_ready,
    DELIVERY_COMPLETED: stringTitle.delivery_completed,
    ORDER_CONFIRM: stringTitle.order_confirm,
    CANCEL_REQUESTED: stringTitle.cancel_request,
    CANCEL_COMPLETED: stringTitle.cancel_completed,
    RETURN_REQUESTED: stringTitle.return_request,
    RETURN_COMPLETED: stringTitle.return_completed
  },
  orderPointSearchTypeString: {
    USER: stringTitle.orderUser,
    ORDER_ID: stringTitle.orderId
  },

  // 플랫폼
  platformRequstStatus: [],
  platformRequstStatusString: {
    ALL: stringTitle.all,
    REQUESTED: stringTitle.request,
    COMPLETED: stringTitle.complete,
    CANCELED: stringTitle.cancel
  },

  // 배너
  bannerTypeString: {
    GOODS: stringTitle.goods,
    PLATFORM_SERVICE: stringTitle.serviceName,
    URL: stringTitle.url
  },
  bannerString: {
    ALL: stringTitle.all,
    GOODS: stringTitle.goods,
    PLATFORM_SERVICE: stringTitle.serviceName,
    URL: stringTitle.url
  },

  // 공지사항
  noticeCode: ["ALL", "NOTE", "EVNT", "NEWS"],
  noticeString: {
    ALL: stringTitle.all,
    NOTE: stringTitle.note,
    EVNT: stringTitle.evnt,
    NEWS: stringTitle.news
  },
  noticeSelectCode: ["NOTE", "EVNT", "NEWS"],
  noticeSelect: {
    NOTE: stringTitle.note,
    EVNT: stringTitle.evnt,
    NEWS: stringTitle.news
  },

  // 감사
  auditCode: ["ALL", "SUCCESS", "FAIL"],
  auditString: {
    ALL: stringTitle.all,
    SUCCESS: stringTitle.success,
    FAIL: stringTitle.fail
  },

  // 정렬
  orderByTypeCode: ["ASC", "DESC"],
  orderByTypeString: {
    ASC: stringTitle.asc,
    DESC: stringTitle.desc
  },

  // toggle
  toggleCode: [0, 1],
  deleteString: {
    0: "-",
    1: stringTitle.delete
  },
  postFlagString: {
    0: stringTitle.unPost,
    1: stringTitle.post
  },
  adminString: {
    0: stringTitle.generalManager,
    1: stringTitle.superAdmin
  },
  authString: {
    0: stringTitle.impossible,
    1: stringTitle.possible
  },
  loginString: {
    0: stringTitle.fail,
    1: stringTitle.success
  }
};

export default string;
