import React, { Component } from "react";

class OrderTtitle extends Component {
  render() {
    const { onClick, titleData } = this.props;

    let data = titleData.title;
    if (titleData.quantity - 1 > 0)
      data = `${titleData.title} 외 ${titleData.quantity - 1}건`;

    // 포인트 사용 내역
    if (
      titleData.type !== undefined &&
      !titleData.type.includes("PLATFORM_SERVICE") &&
      titleData.quantity - 1 > 0
    )
      data = `${titleData.title} 외 ${titleData.quantity - 1}건`;

    return (
      <div className="text-left" onClick={onClick}>
        {data}
      </div>
    );
  }
}

export default OrderTtitle;
