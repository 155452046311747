import React, { Component } from "react";
import { Button } from "antd";
import stringTitle from "../../stringTitle";

class OrderStatusTitle extends Component {
  render() {
    const { status } = this.props;
    return (
      <Button {...this.props}>
        {status === "PAY_READY" && stringTitle.pay_completed}
        {status === "PAY_COMPLETED" && stringTitle.order_ready}
        {status === "ORDER_READY" && stringTitle.order_completed}
        {status === "ORDER_COMPLETED" && stringTitle.preparation}
        {status === "PREPARATION" && stringTitle.shipping}
        {status === "SHIPPING" && stringTitle.delivery_completed}
        {status === "CANCEL_REQUESTED" && stringTitle.cancel_completed}
        {status === "RETURN_REQUESTED" && stringTitle.return_ing}
        {status === "RETURN_ING" && stringTitle.return_completed}
        {status === "REPLACE_REQUESTED" && stringTitle.replace_ing}
        {status === "REPLACE_ING" && stringTitle.replace_completed}
      </Button>
    );
  }
}

export default OrderStatusTitle;
