import React, { Component } from "react";

import { Button, DatePicker, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";

import {
  checkData,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import UserInfo from "../../components/user/UserInfo";

const { RangePicker } = DatePicker;
class AdjustmentList extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date)
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getAdjustmentList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  getAdjustmentList = (params = {}) => {
    const { startDate, endDate } = this.state;

    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getAdjustmentList, [10, pageNum, startDate, endDate], {})
      .then((res) => {
        // console.log(`adjustment data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getAdjustmentList();
  }

  render() {
    const { startDate, endDate, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.adjustmentDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user",
        className: "text-center",
        render: (data) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            type="user"
            userData={data}
          />
        )
      },
      {
        title: stringTitle.admin,
        dataIndex: "admin",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: stringTitle.rewardMoney,
        dataIndex: "price",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      }
    ];

    return (
      <div>
        <div className="title">정산 내역 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            {stringTitle.searchDate}&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={true}
            />
          </label>
          <Button
            onClick={() => {
              this.getAdjustmentList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default AdjustmentList;
