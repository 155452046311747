import React, { Component } from "react";

import { Modal, Form, Input } from "antd";

import { httpUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import { showCreateConfirm } from "../common/AlertInfoModal";

const FormItem = Form.Item;
const SellerModal = Form.create()(
  class extends Component {
    state = {};

    onSubmit = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`values = ${JSON.stringify(values, null, 4)}`);
        if (!err)
          showCreateConfirm(
            stringTitle.createQuest,
            httpUrl.sellerCreate,
            values,
            this.props.refresh,
            this.props.onCancel
          );
      });
    };

    componentDidMount() {
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    }

    render() {
      const { visible, onCancel, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={<span> 판매자 생성</span>}
          okText="확인"
          cancelText="취소"
          onOk={this.onSubmit}
          onCancel={onCancel}
          style={{ minWidth: "800px" }}
          maskClosable={false}
        >
          <Form {...formItemLayout} onSubmit={this.onSubmit}>
            <FormItem label={<span>{stringTitle.businessName}&nbsp;</span>}>
              {getFieldDecorator("businessName", {
                rules: [
                  {
                    required: true
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.owner}&nbsp;</span>}>
              {getFieldDecorator("owner", {
                rules: [
                  {
                    required: true
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>사업자번호&nbsp;</span>}>
              {getFieldDecorator("businessNum", {
                rules: [
                  {
                    required: true
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.address}&nbsp;</span>}>
              {getFieldDecorator("locationAddress", {
                rules: [
                  {
                    required: true
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.mobNum}&nbsp;</span>}>
              {getFieldDecorator("mobNum", {
                rules: [
                  {
                    required: true
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.email}&nbsp;</span>}>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true
                  }
                ]
              })(<Input placeholder="ex) hong@gmail.com" />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.tel}&nbsp;</span>}>
              {getFieldDecorator("tel", {
                rules: [
                  {
                    required: true
                  }
                  // {
                  //   validator: (rule, value, cb) => {
                  //     console.log(value);
                  //     if (!value.replace(/[^0-9]/g, ""))
                  //       cb("숫자만 입력해주세요");
                  //     cb();
                  //   }
                  // }
                ]
              })(<Input placeholder="ex) 0212341234" />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default SellerModal;
