import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Button } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { checkData, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showUpdateConfirm } from "../../components/common/AlertInfoModal";

class Service extends Component {
  state = {
    userInfo: reactLocalStorage.getObject("adminUser"),
    serviceData: [],
    selectedRow: "",
    selectData: {},
    prevData: {}
  };

  // 값 변경
  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else data[type] = value;

    // console.log(`data = ${JSON.stringify(data, null, 4)}`);
    this.setState({
      selectData: data
    });
  };

  // 수정
  onUpdate = () => {
    showUpdateConfirm(
      stringTitle.updateQuest,
      httpUrl.serviceUpdate,
      this.state.selectData,
      this.serviceList
    );
  };

  serviceList() {
    httpGet(httpUrl.serviceList, [], {})
      .then((result) => {
        // console.log(result.data);
        this.setState({
          serviceData: result.data,
          selectedRow: "",
          selectData: {},
          prevData: {}
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  }

  componentDidMount() {
    this.serviceList();
  }

  render() {
    const { serviceData, selectData, prevData, selectedRow } = this.state;

    const serviceColumns = [
      {
        width: 120,
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        // width: 80,
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center"
      },
      {
        title: stringTitle.service,
        dataIndex: "service",
        className: "text-center"
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                style={{ maxWidth: 200 }}
                value={selectData.status}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "status");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: stringTitle.memo,
        dataIndex: "memo",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              checkData(data)
            ) : (
              <Input.TextArea
                rows={4}
                allowClear
                style={{ maxWidth: 500 }}
                value={selectData.memo}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "memo");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "",
        dataIndex: "update",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={(value) => {
                  this.onChangeModal(row.idx, "idx", index);
                  this.setState({
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}
              >
                {stringTitle.updateFlag}
              </Button>
            )}
            {selectedRow === index && (
              <div className="flex-row flex-center">
                <Button style={{ marginRight: "10px" }} onClick={this.onUpdate}>
                  {stringTitle.update}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                >
                  {stringTitle.cancel}
                </Button>
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">
          {stringTitle.service} {stringTitle.management}
        </div>
        <div className="wrapper mt-35 width-1240" />
        {/* {userInfo.superAdmin === 1 && (
          <div style={{ float: "left" }}>
            <Button
              onClick={() => {
                this.setState({ registryModalVisible: true });
                this.coinAuthList(0);
              }}
            >
              생성
            </Button>
          </div>
        )} */}
        <Table
          id="admin-table"
          rowKey={(record) => record.idx}
          columns={serviceColumns}
          dataSource={serviceData}
          pagination={false}
        />
      </div>
    );
  }
}

export default Service;
