import React, { Component } from "react";

import { Button, Form, Modal } from "antd";
import { httpUrl } from "../../api/httpClient";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { showLevelConfirm } from "../common/AlertInfoModal";

import RecommendUserSearchModal from "./RecommendUserSearchModal";

const FormItem = Form.Item;
const RecommendLevelUpModal = Form.create()(
  class extends Component {
    state = {
      userVisible: false,
      userSelect: { user: {} }
    };

    onSumbit = (e) => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          // console.log(`values = ${JSON.stringify(values, null, 4)}`);

          showLevelConfirm(
            stringTitle.levelQuest,
            httpUrl.recommendLevelup,
            values,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    componentDidMount() {
      this.props.form.setFieldsValue({ type: "FRANCHISE" });
    }

    render() {
      const { userVisible, userSelect } = this.state;
      const { form, visible, onCancel } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={stringTitle.levelUp}
          onCancel={onCancel}
          onOk={this.onSumbit}
          okText={stringTitle.levelUp}
          cancelText={stringTitle.cancel}
          style={{ minWidth: "600px" }}
          maskClosable={false}
        >
          {userVisible && (
            <RecommendUserSearchModal
              visible={userVisible}
              type={form.getFieldsValue().type}
              onCancel={() => this.setState({ userVisible: false })}
              onSelect={(selectData) => {
                // console.log(`user = ${JSON.stringify(selectData, null, 4)}`);
                this.setState({ userSelect: selectData });
                form.setFieldsValue({ idx: selectData.user.idx });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.levelUp}&nbsp;</span>}>
              {getFieldDecorator("type", {
                rules: [
                  {
                    required: true,
                    message: "승격 레벨을 선택해주세요!"
                  }
                ]
              })(
                <SelectBox
                  disabled={true}
                  placeholder="선택해주세요"
                  code={string.recommendUserTypeString}
                  codeString={string.recommendUserTypeString}
                  style={{ width: "200px" }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.user}&nbsp;</span>}>
              {getFieldDecorator("idx", {
                rules: [
                  {
                    required: true,
                    message: "회원을 선택해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      cb(form.setFieldsValue({ amount: parseInt(value) }));
                    }
                  }
                ]
              })(
                userSelect.user.idx !== undefined ? (
                  <div>
                    {userSelect.user.id} / {userSelect.user.name}
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        this.setState({ userVisible: true });
                      }}
                    >
                      {stringTitle.modify}
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      if (form.getFieldsValue().type !== undefined)
                        this.setState({ userVisible: true });
                      else
                        Modal.error({
                          title: "승격 레벨을 선택해주세요"
                        });
                    }}
                  >
                    {stringTitle.user + stringTitle.search}
                  </Button>
                )
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default RecommendLevelUpModal;
