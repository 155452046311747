import React, { Component } from "react";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";

import {
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showModifyConfirm } from "../../components/common/AlertInfoModal";

import OrderStatusTitle from "../../components/order/OrderStatusTitle";
import OrderGoodsHistoryModal from "../../components/order/OrderGoodsHistoryModal";

const { RangePicker } = DatePicker;
class OrderPointList extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      sellerIdx: 0,
      status: "ALL",
      billingType: "ALL",
      searchType: "USER",
      searchText: "",

      historyFlag: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getOrderPointList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 주문 상태 변경
  onHandleChangeStatus = (value) => {
    this.setState({
      status: value
    });
  };

  // 결제 상태 변경
  onHandleChangebillingType = (value) => {
    this.setState({
      billingType: value
    });
  };

  // 검색 조건 변경
  onHandleChangeSearchType = (value) => {
    this.setState({
      searchType: value
    });
  };

  // 주문 상태 변경
  orderPointStatusUpdate = (data, status) => {
    let updateData = {
      idx: data.idx,
      status: status
    };

    showModifyConfirm(
      ` ${status === "CANCEL" ? stringTitle.moneyReturn : ""} ${
        stringTitle.orderId
      } - ${data.orderId}의 주문상태를 변경하시겠습니까?`,
      httpUrl.orderPointStatusUpdate,
      updateData,
      this.getOrderPointList
    );
  };

  // 주문 포인트 관리 목록
  getOrderPointList = (params = {}) => {
    const { startDate, endDate, status, billingType, searchType, searchText } =
      this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getOrderPointList,
      [
        10,
        pageNum,
        startDate,
        endDate,
        status,
        billingType,
        searchType,
        searchText
      ],
      {}
    )
      .then((res) => {
        // console.log(`order point data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getOrderPointList();
  }

  render() {
    const {
      historyFlag,
      orderIdx,
      startDate,
      endDate,
      status,
      billingType,
      searchType,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: stringTitle.orderDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.orderId,
        dataIndex: "orderId",
        className: "text-center"
      },
      {
        title: stringTitle.orderUser,
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data}
            <br /> ({row.name})
          </div>
        )
      },
      {
        title: stringTitle.goodsName,
        dataIndex: "goodsTitle",
        className: "text-center"
        // render: (data) => <div className="text-wordWrap">{data}</div>
      },
      {
        title: stringTitle.optionName,
        dataIndex: "optionTitle",
        className: "text-center"
      },
      {
        title: stringTitle.quantity,
        dataIndex: "quantity",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.payment,
        dataIndex: "price",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.billingType,
        dataIndex: "billingType",
        className: "text-center",
        render: (data) => <div>{string.billingTypeString[data]}</div>
      },
      {
        title: stringTitle.billingPrice,
        dataIndex: "billingPrice",
        className: "text-center",
        render: (data, row) => (
          <div>{numberFormat(row.price * row.quantity)}</div>
        )
      },
      {
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center"
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.orderStatus[data]}</div>
      },
      {
        // title: stringTitle.status + stringTitle.modify,
        dataIndex: "",
        className: "text-center",
        render: (data, row) =>
          /* 주문대기 상태에서만 결제완료가능  */
          (row.status === "ORDER_READY" ||
            row.status === "CANCEL_REQUESTED" ||
            row.status === "RETURN_REQUESTED") && (
            <OrderStatusTitle
              status={row.status}
              onClick={() => {
                this.orderPointStatusUpdate(row, "NAN");
              }}
            />
          )
      }
    ];

    const expandedRowRender = (record) => {
      const bankColumns = [
        {
          title: stringTitle.bank,
          dataIndex: "bank",
          className: "text-center"
        },
        {
          title: stringTitle.bankAccount,
          dataIndex: "bankAccount",
          className: "text-center"
        }
      ];

      return (
        <div>
          <Button
            className="m-r-10"
            onClick={() => {
              this.setState({ historyFlag: true, orderIdx: record.idx });
            }}
          >
            {stringTitle.order_goods_histroy}
          </Button>
          {record.status !== "CANCEL_REQUESTED" &&
            record.status !== "CANCEL_COMPLETED" &&
            record.status !== "RETURN_COMPLETED" && (
              <Button
                className=""
                onClick={() => {
                  this.orderPointStatusUpdate(record, "CANCEL");
                }}
              >
                {stringTitle.order + stringTitle.cancel}
              </Button>
            )}
          {record.billingType === "VIRTUAL_ACCOUNT" &&
            record.returnBankInfo !== null && (
              <div style={{ marginTop: 20 }}>
                <b style={{ fontSize: 18 }}>{stringTitle.returnBankInfo}</b>
                <Table
                  columns={bankColumns}
                  dataSource={[record.returnBankInfo]}
                  pagination={false}
                  style={{ backgroundColor: "#fff" }}
                />
              </div>
            )}
        </div>
      );
    };

    return (
      <div>
        {/* 주문상태변경 히스토리 */}
        {historyFlag && (
          <OrderGoodsHistoryModal
            visible={historyFlag}
            orderIdx={orderIdx}
            onCancel={() => {
              this.setState({ historyFlag: false });
            }}
          />
        )}
        <div className="title">G포인트 구매 내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            {stringTitle.searchDate}&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={true}
            />
          </label>
          <label>
            {stringTitle.status}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.orderPointStatusString[status]}
              code={string.orderPointStatusString}
              codeString={string.orderPointStatusString}
              onChange={this.onHandleChangeStatus}
            />
          </label>
          <label>
            {stringTitle.billingType}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.billingTypeString[billingType]}
              code={string.billingType}
              codeString={string.billingTypeString}
              onChange={this.onHandleChangebillingType}
            />
          </label>
          <label>
            {stringTitle.searchRequire}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.orderPointSearchTypeString[searchType]}
              code={string.orderPointSearchTypeString}
              codeString={string.orderPointSearchTypeString}
              onChange={this.onHandleChangeSearchType}
            />
          </label>
          <Input
            style={{ width: "250px", marginRight: 10 }}
            placeholder={`${stringTitle.orderId} / ${stringTitle.orderUser}`}
            allowClear
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.getOrderPointList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default OrderPointList;
