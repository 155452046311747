import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        // console.log(`response = ${JSON.stringify(response, null, 4)}`);
        resolve(response.data);

        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(`error = ${JSON.stringify(error, null, 4)}`);
        if (error.message.includes("401")) {
          // alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove("adminUser");
          global.location.href = "/";
        }
        // alert(JSON.stringify(error.message));
        reject(error);

        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.get(makeUrl(url, params), data)
  //     .then((response) => {
  //       resolve(response.data);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.put(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.post(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpDownload = (url, params, data) => {
  // return httpExec('GET', makeUrl(url, params), data);
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      responseType: "arraybuffer"
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpUrl = {
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",

  //image
  imageUpload: "/image/upload?main=",
  getImage: "/image/",
  imageEncoding: "/image/imageEncoding",

  // common
  getPointList: "/common/point/list",
  getUserSearchList: "/common/user/list?pageSize=%s&pageNum=%s&searchText=%s",
  getSellerSearchList:
    "/common/seller/list?pageSize=%s&pageNum=%s&searchText=%s",
  getLogisticsList: "/common/logistics/list",

  // goods - 상품
  getGoodsList:
    "/goods/list?pageSize=%s&pageNum=%s&deleted=%s&post=%s&goodsCategoryIdx=%s&orderBy=%s&orderByType=%s&searchType=%s&searchText=%s",
  goodsCreate: "/goods/create",
  goodsUpdate: "/goods/update",
  goodsImageUpdate: "/goods/image/update?idx=%s",
  goodsDelete: "/goods/delete/%s",
  goodsPost: "/goods/post/%s",
  getGoodsOptionList: "/goods/option/list/%s",
  goodsOptionUpdate: "/goods/option/update",
  goodsOptionDelete: "/goods/option/delete/%s",
  getGoodsRequireInfoTempList: "/goods/info/template/%s",
  getGoodsRequireInfoList: "/goods/info/list/%s",
  goodsInfoUpdate: "/goods/info/update",
  goodsInfoDelete: "/goods/info/delete/%s",
  // goods - 카테고리
  getCategoryList: "/goods/category/list",
  getCategoryParentIdxList: "/goods/category/list/%s",
  categoryCreate: "/goods/category/create",
  categoryUpdate: "/goods/category/update",
  categoryDelete: "/goods/category/delete/%s",
  // keyword - 키워드
  getGoodsKeywordList:
    "/goods/keyword/list?pageSize=%s&pageNum=%s&type=%s&orderBy=%s&searchText=%s",
  keywordUpdate: "/goods/keyword/update",
  keywordDelete: "/goods/keyword/delete/%s",

  // seller - 판매자
  getSellerList:
    "/seller/list?pageSize=%s&pageNum=%s&deleted=%s&sellerSearchType=%s&searchText=%s",
  sellerCreate: "/seller/create",
  sellerUpdate: "/seller/update",
  sellerDelete: "/seller/delete/%s",

  // user - 회원
  getUserList: "/user/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  userDetail: "/user/detail/%s",
  userUpdate: "/user/update",
  getUserOrderList:
    "/user/order/log?pageSize=%s&pageNum=%s&idx=%s&startDate=%s&endDate=%s&pointType=%s&status=%s&billingType=%s",
  getUserPointChargeList:
    "/user/point/log/charge?pageSize=%s&pageNum=%s&idx=%s&startDate=%s&endDate=%s&pointType=%s&type=%s",
  getUserPointPurchaseList:
    "/user/point/log/purchase?pageSize=%s&pageNum=%s&idx=%s&startDate=%s&endDate=%s&pointType=%s&type=%s",
  getUserRecommenderList:
    "/user/recommended/list?pageSize=%s&pageNum=%s&idx=%s&searchText=%s",

  // recommend - 친구
  getRecommendList:
    "/recommend/list?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  getRecommendDetailList: "/recommend/recommended/list?idx=%s",
  getRecommendLevelupLog:
    "/recommend/levelUp/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&searchText=%s",
  getRecommendAdjustmentList:
    "/recommend/adjustment/log?pageSize=%s&pageNum=%s&idx=%s&startDate=%s&endDate=%s",
  getRecommendUserList:
    "/recommend/levelUp/user/list?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  rewardAdjustment: "/reward/adjustment",
  recommendLevelup: "/recommend/levelUp",
  getRewardList:
    "/reward/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&type=%s&searchText=%s",
  getAdjustmentList:
    "/reward/adjustment/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  getAdjustmentStatList:
    "/reward/adjustment/stat/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&searchText=%s",
  getRecommendAccountRequstList:
    "/reward/adjustment/account/request/list?pageSize=%s&pageNum=%s&status=%s&searchText=%s",
  recommendAccountUpdate: "/reward/adjustment/account/request",

  // point - 포인트
  getPointBalanceList:
    "/point/balance/list?pageSize=%s&pageNum=%s&pointType=%s&searchText=%s&",
  getPointChargeList:
    "/point/charge/log?pageSize=%s&pageNum=%s&pointType=%s&type=%s&startDate=%s&endDate=%s&searchText=%s",
  pointCharge: "/point/charge",
  pointChargeExcel: "/point/charge/excel",
  getPointPurchaseList:
    "/point/purchase/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&pointType=%s&type=%s&searchText=%s",

  // order - 주문
  getOrderList:
    "/order/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&status=%s&billingType=%s&searchType=%s&searchText=%s",
  getOrderDeatilList:
    "/order/log/detail?pageSize=%s&pageNum=%s&idx=%s&status=%s&searchText=%s",
  orderStatusUpdate: "/order/status/update",
  getOrderGoodsHistory: "/order/goods/history/%s",
  getOrderGoodsList:
    "/order/goods/log?pageSize=%s&pageNum=%s&statusChange=%s&startDate=%s&endDate=%s&sellerIdx=%s&status=%s&billingType=%s&searchType=%s&searchText=%s",
  orderGoodsStatusUpdate: "/order/delivery/status/update",
  orderGoodsDeliveryUpdate: "/order/delivery/invoice/registration",
  getOrderRequestList:
    "/order/goods/change/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&sellerIdx=%s&status=%s&billingType=%s&searchType=%s&searchText=%s",
  getOrderPointList:
    "/order/point/goods/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&status=%s&billingType=%s&searchType=%s&searchText=%s",
  orderPointStatusUpdate: "/order/point/goods/status/update",

  // platform-service 플랫폼
  getPlatformServiceList: "/platform/list",
  getPlatformServiceDetail: "/platform/detail/%s",
  platfromServiceUpdate: "/platform/update",
  getPlatfromRequestList:
    "/platform/request/log?pageSize=%s&pageNum=%s&serviceIdx=%s&status=%s&searchText=%s",
  platformRequestUpdate: "/platform/request/status/update",
  platformRequest: "/platform/request",
  platformRequestExcel: "/platform/request/excel",

  // banner - 배너
  getBannerList: "/banner/list?pageSize=%s&pageNum=%s&type=%s&position=%s",
  getBannerRefList:
    "/banner/ref/list?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  bannerCreate: "/banner/create",
  bannerUpdate: "/banner/update",
  bannerDelete: "/banner/delete/%s",

  // community - 공지
  getNoticeList:
    "/community/notice/list?pageSize=%s&pageNum=%s&deleted=%s&category=%s&searchText=%s",
  noticeCreate: "/community/notice/create",
  noticeUpdate: "/community/notice/update",
  noticeDelete: "/community/notice/delete/%s",
  // community - fna
  getFaqList:
    "/community/faq/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  faqCreate: "/community/faq/create",
  faqUpdate: "/community/faq/update",
  faqDelete: "/community/faq/delete/%s",
  // community - 1:1 문의
  qnaList:
    "/community/qna/list?pageSize=%s&pageNum=%s&categoryIdx=%s&status=%s",
  qnaUpdate: "/community/qna/update",
  qnaCategoryList: "/community/qnaCategory/list?category=%s",
  qnaCategoryUpdate: "/community/qnaCategory/update",
  qnaCategoryDelete: "/community/qnaCategory/delete/%s",

  // aduit - 관리자
  getAdministratorAudit:
    "/audit/admin/list?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  getAdministratorAuditExcel:
    "/audit/admin/list/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  // aduit - 유저
  getUserAudit:
    "/audit/user/list?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%sauditResultType&auditResultType=%s",
  getUserAuditExcel:
    "/audit/user/list/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s&auditResultType=%s",

  // system - 어드민
  adminList: "/system/admin/list?pageSize=%s&pageNum=%s&searchText=%s",
  adminCreate: "/system/admin/create",
  adminUpdate: "/system/admin/update",
  adminDelete: "/system/admin/delete/%s",
  // system - 어드민 메뉴
  getAuthList: "/system/admin/authDetail/%s",
  authUpdate: "/system/admin/authUpdate",
  // system - 판매자
  getAdminIdxSellerList:
    "/system/sellerRef/list?pageSize=%s&pageNum=%s&idx=%s&deleted=%s&sellerSearchType=%s&reverseFlag=%s&searchText=%s",
  adminSellerCreate: "/system/sellerRef/create",
  adminSellerDelete: "/system/sellerRef/delete",
  // system - 로그인이력
  getAdminLoginLog:
    "/system/admin/loginLog?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",

  // system - 서비스
  serviceList: "/system/serviceStatus/list",
  serviceCreate: "/system/serviceStatus/create",
  serviceUpdate: "/system/serviceStatus/update",
  serviceDelete: "/system/serviceStatus/delete/%s",

  // adminUserInfo - 내정보
  adminUserUpdate: "/myPage/update"
};

const imageType = ["image/jpg", "image/jpeg", "image/png"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType
};
