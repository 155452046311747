import React, { Component } from "react";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";

import {
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import UserInfo from "../../components/user/UserInfo";
import UserDetail from "../../components/user/UserDetail";

const { RangePicker } = DatePicker;
class RewardList extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      type: "ALL",
      searchText: "",

      userFlag: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getRewardList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 정렬 대상 설정
  typeHandleChange = (value) => {
    this.setState({
      type: value
    });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  getRewardList = (params = {}) => {
    const { startDate, endDate, type, searchText } = this.state;

    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getRewardList,
      [10, pageNum, startDate, endDate, type, searchText],
      {}
    )
      .then((res) => {
        // console.log(`reward data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getRewardList();
  }

  render() {
    const {
      userFlag,
      userData,
      userIdx,
      startDate,
      endDate,
      type,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.rewardDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        width: 400,
        title: stringTitle.user,
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            pointer="pointer"
            type="user"
            userData={row.user}
            onClick={() => {
              this.setState({
                userIdx: row.user.idx,
                userData: row,
                userFlag: true
              });
            }}
          />
        )
      },
      {
        title: stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data, row) => (
          <div>
            {string.rewardType[data]} (
            {data === "MANAGER" ||
            data === "FRANCHISE_REC" ||
            data === "INDIRECT_RECRUIT"
              ? "5%"
              : data === "FRANCHISE"
              ? "15%"
              : data === "MANAGER_ALL"
              ? "20%"
              : data === "DIRECT_RECRUIT"
              ? "35%"
              : data === "DIRECT_RECRUIT_ALL"
              ? "40%"
              : ""}
            )
          </div>
        )
      },
      // {
      //   title: stringTitle.optionName,
      //   dataIndex: "optionTitle",
      //   className: "text-center",
      //   render: (data) => checkData(data)
      // },
      {
        title: stringTitle.money,
        dataIndex: "price",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      }
    ];

    return (
      <div>
        {/* 회원정보 */}
        {userFlag && (
          <UserDetail
            visible={userFlag}
            userIdx={userIdx}
            userData={userData}
            onCancel={() => {
              this.setState({
                userFlag: false
              });
            }}
          />
        )}
        <div className="title">지급 내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            {stringTitle.searchDate}&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={true}
            />
          </label>
          <label>
            {stringTitle.searchRequire}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.rewardTypeString[type]}
              code={string.rewardTypeString}
              codeString={string.rewardTypeString}
              onChange={this.typeHandleChange}
            />
          </label>
          <Input
            placeholder="ID를 입력하세요."
            style={{ marginRight: 10, width: 200 }}
            onChange={(e) => {
              this.searchTextHandelChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getRewardList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default RewardList;
