export default {
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "58545",
  // isLogoFlag: false,         // 로고 이미지
  imageSize: 1024 * 3 * 1000,
  subCategoryFlag: false, // 서브카테고리 설정
  isMultiLanguage: false, // 게시글 다국어 설정

  /* test */
  // serverProtocol: "http",
  // serverPort: "58546",
  // clientPort: "15002",
  /* prod */
  serverProtocol: "https",
  serverPort: "48545",
  // clientPort: "15001",

  // test
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "test.back.blobs.info",
  // clientId: "ㅡr06yvi25vl",   // naver map client key
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false, // 로고 이미지

  // DRNT wallet
  isFoundationInFee: false,
  isPurchaseInFee: false,
  // serverIp: "devback.realtimechain.org",
  serverIp: "gogumaback.realtimechain.org",  
  // naver map client key
  clientId: "r06yvi25vl",
  clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  isLogoFlag: false // 로고 이미지
  // logoImage: require("../src/img/logo-drnt.png"),
  // logoImageWhite: require("../src/img/logo-drnt-white.png"),
};
