import React, { Component } from "react";

import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { amountDivideFormat, numberFormat } from "../../util";

import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

import UserInfo from "../user/UserInfo";

class RecommendDetailList extends Component {
  state = {
    list: [],
    searchText: ""
  };

  // 검색
  onSearch = () => {
    const { list, searchText, prevList } = this.state;

    if (searchText === "") this.setState({ list: prevList });
    else {
      let arr = [];

      for (let i = 0; i < list.length; i++) {
        if (list[i].user.id.includes(searchText) === true) {
          arr.push(list[i]);
        }
      }
      this.setState({ list: arr });
    }
  };

  getRecommendDetailList = () => {
    httpGet(httpUrl.getRecommendDetailList, [this.props.idx], {})
      .then((res) => {
        // console.log(`res -> ${JSON.stringify(res, null, 4)}`);
        this.setState({ list: res.data.list, prevList: res.data.list });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  componentDidMount() {
    this.getRecommendDetailList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            type="user"
            userData={row.user}
          />
        )
      },
      {
        title: stringTitle.recommender,
        dataIndex: "recommender.recommendCode",
        className: "text-center",
        render: (data, row) =>
          row.recommender.name === null ? (
            data
          ) : (
            <UserInfo userData={row.recommender} />
          )
      },
      {
        title: stringTitle.point,
        dataIndex: "pointBalance",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      },
      {
        title: stringTitle.serviceName,
        dataIndex: "service",
        className: "text-center",
        render: (data) =>
          data.map((item, index) => (
            <div key={index}>
              {item.name} : {item.count}
              <br />
            </div>
          ))
      },
      {
        title: stringTitle.manager,
        dataIndex: "manager.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.manager} />
      },
      {
        title: stringTitle.franchise,
        dataIndex: "franchise.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.franchise} />
      }
    ];

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="목록"
        footer={false}
        style={{ minWidth: 1400 }}
      >
        <div className="flex-row flex-center flex-end m-b-10">
          <Input
            placeholder="회원의 아이디를 입력하세요."
            style={{ marginRight: 10, width: 300 }}
            allowClear
            onChange={(e) => {
              this.setState({
                searchText: e.target.value
              });
            }}
          />
          <Button
            onClick={() => {
              this.onSearch();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default RecommendDetailList;
