import React, { Component } from "react";

import { Button, Input, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import {
  amountDivideFormat,
  checkData,
  formatDate,
  numberFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showModifyConfirm } from "../../components/common/AlertInfoModal";

import PlatformSelectBox from "../../components/common/PlatformSelectBox";
import PlatformRequestModal from "../../components/platform/PlatfromRequestModal";

class PlatformRequestList extends Component {
  state = {
    platformList: [],
    platformIdx: 0,

    list: [],
    pagination: { current: 0, total: 0 },
    status: "ALL",
    searchText: "",

    requestFlag: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getPlatfromRequestList({ pageNum: pager.current });
  };

  // 플랫폼 서비스 변경
  onHandelChangePlatformIdx = (value) => {
    this.setState({
      platformIdx: value
    });
    // this.getPlatfromRequestList({ serviceIdx: value });
  };

  // 상태 변경
  onHandleChangeStatus = (value) => {
    this.setState({
      status: value
    });
  };

  // 주문 상태 변경
  platformRequestUpdate = (data, status) => {
    let updateData = {
      idx: data.idx,
      status: status
    };

    showModifyConfirm(
      ` ${status === "CANCEL" ? stringTitle.moneyReturn : ""} ${
        data.serviceName
      } - ${data.optionTitle} 의 상태를 변경하시겠습니까?`,
      httpUrl.platformRequestUpdate,
      updateData,
      this.getPlatfromRequestList
    );
  };

  // 플랫폼 서비스 요청 목록
  getPlatfromRequestList = (params = {}) => {
    const { platformIdx, status, searchText } = this.state;

    let serviceIdx = params.serviceIdx || platformIdx;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getPlatfromRequestList,
      [10, pageNum, serviceIdx, status, searchText],
      {}
    )
      .then((res) => {
        // console.log(`platform request= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 플랫폼 서비스 목록
  getPlatformServiceList = (params = {}) => {
    httpGet(httpUrl.getPlatformServiceList, [], {})
      .then((res) => {
        // console.log(`list = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          platformList: [
            {
              idx: 0,
              name: stringTitle.all
            },
            ...res.data
          ]
        });
        this.getPlatfromRequestList({});
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getPlatformServiceList();
  }

  render() {
    const { requestFlag, platformList, platformIdx, status, list, pagination } =
      this.state;
    const columns = [
      {
        width: 120,
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        width: 120,
        title: stringTitle.updateDate,
        dataIndex: "updateDate",
        className: "text-center",
        render: (data) => <div>{checkData(formatDate(data))}</div>
      },
      {
        key: "idx",
        title: stringTitle.serviceName,
        dataIndex: "serviceName",
        className: "text-center"
      },
      {
        title: stringTitle.optionName,
        dataIndex: "optionTitle",
        className: "text-center"
      },
      {
        title: stringTitle.user,
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data} ({row.name})
          </div>
        )
      },
      {
        title: stringTitle.point,
        dataIndex: "point",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      },
      {
        title: stringTitle.price,
        dataIndex: "price",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.platformRequstStatusString[data]}</div>
      },
      {
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.status !== "COMPLETED" && row.status !== "CANCELED" && (
              <Button
                className="m-r-10"
                onClick={() => {
                  this.platformRequestUpdate(row, "NAN");
                }}
              >
                {stringTitle.complete}
              </Button>
            )}
            {row.status !== "CANCELED" && (
              <Button
                onClick={() => {
                  this.platformRequestUpdate(row, "CANCEL");
                }}
              >
                {stringTitle.cancel}
              </Button>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {requestFlag && (
          <PlatformRequestModal
            visible={requestFlag}
            onCancel={() => {
              this.setState({ requestFlag: false });
            }}
            refresh={this.getPlatfromRequestList}
          />
        )}
        <div className="title">플랫폼 서비스 신청 내역</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ requestFlag: true });
            }}
          >
            {stringTitle.offer}
          </Button>
          <div>
            <label className="m-r-10">
              {stringTitle.serviceName}&nbsp;
              <PlatformSelectBox
                list={platformList}
                value={platformIdx}
                onChange={this.onHandelChangePlatformIdx}
              />
            </label>
            <label className="m-r-10">
              {stringTitle.status}&nbsp;
              <SelectBox
                value={string.platformRequstStatusString[status]}
                code={string.platformRequstStatusString}
                codeString={string.platformRequstStatusString}
                onChange={this.onHandleChangeStatus}
              />
            </label>
            <Input
              allowClear
              placeholder={stringTitle.user}
              style={{ width: 200, marginRight: 10 }}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getPlatfromRequestList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PlatformRequestList;
