import React, { Component } from "react";

import { Button, Checkbox, Input, Table } from "antd";

import { httpGet, httpUrl, serverUrl } from "../../api/httpClient";
import { amountDivideFormat, numberFormat } from "../../util";

import _const from "../../const";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import {
  showDeletePageNumConfirm,
  showPostConfirm
} from "../../components/common/AlertInfoModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import GoodsDetail from "../../components/goods/GoodsDetail";
import GoodsModal from "../../components/goods/GoodsModal";
import SellerInfo from "../../components/seller/SellerInfo";
import BasicImage from "../../components/common/BasicImage";
import GoodsCategorySelect from "../../components/goods/GoodsCategorySelect";

class GoodsList extends Component {
  state = {
    categoryList: [],
    list: [],
    pagination: { current: 0, total: 0 },
    deleted: false,
    post: false,
    goodsCategoryIdx: 0,
    orderBy: "IDX",
    orderByType: "DESC",
    searchType: "ALL",
    searchText: "",

    topCategory: 0,
    subCategory: 0,
    lastCategory: 0,
    topCategoryList: [],
    subCategoryList: [],
    lastCategoryList: [],

    createFlag: false,
    visible: false,
    optionFlag: false,
    sellerFlag: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getGoodList({ pageNum: pager.current });
  };

  // 카테고리 변경
  categoryHandleChange = (value, type) => {
    const { topCategory, subCategory, lastCategory } = this.state;

    if (type === "top" && topCategory !== value) {
      this.setState({
        goodsCategoryIdx: value,
        topCategory: value,
        subCategory: 0,
        lastCategory: 0,
        subCategoryList: [],
        lastCategoryList: []
      });
      _const.subCategoryFlag &&
        this.getCategoryParentIdxList({ idx: value, type: "sub" });
    }
    if (type === "sub" && subCategory !== value) {
      this.setState({
        goodsCategoryIdx: value,
        subCategory: value,
        lastCategory: 0,
        lastCategoryList: []
      });
      this.getCategoryParentIdxList({ idx: value, type: "last" });
    }
    if (type === "last" && lastCategory !== value) {
      this.setState({
        goodsCategoryIdx: value,
        lastCategory: value
      });
    }
  };

  // 정렬 대상 설정
  orderByHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      orderBy: value
    });
  };

  // 정렬 순서 설정
  orderByTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      orderByType: value
    });
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value
    });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 미게시만 보기 설정
  postHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      post: e.target.checked
    });
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked
    });
  };

  // 삭제
  onDelete = (row) => {
    const { post, deleted, pagination, list } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && !post && row.deleted === 0
        ? list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    showDeletePageNumConfirm(
      `${row.title.content}을 ${
        row.deleted ? stringTitle.restore : stringTitle.delete
      }하시겠습니까?`,
      httpUrl.goodsDelete,
      row,
      this.getGoodList,
      pageNum
    );
  };

  //  게시
  onPostFlag = (row) => {
    showPostConfirm(
      `${row.title.content}을 ${
        row.postFlag ? stringTitle.unPost : stringTitle.post
      }하시겠습니까?`,
      httpUrl.goodsPost,
      row,
      this.getGoodList
    );
  };

  // 상품 리스트
  getGoodList = (params = {}) => {
    const {
      deleted,
      post,
      goodsCategoryIdx,
      orderBy,
      orderByType,
      searchType,
      searchText
    } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getGoodsList,
      [
        10,
        pageNum,
        deleted,
        post,
        goodsCategoryIdx,
        orderBy,
        orderByType,
        searchType,
        searchText
      ],
      {}
    )
      .then((res) => {
        // console.log(`goodsList = ${JSON.stringify(res.data.list, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 카테고리 리스트
  getCategoryParentIdxList = (params = {}) => {
    httpGet(httpUrl.getCategoryParentIdxList, [params.idx], {})
      .then((res) => {
        if (params.type === "top") {
          // console.log(`category top List = ${JSON.stringify(res, null, 4)}`);
          this.setState({
            topCategoryList: [
              { idx: 0, categoryName: stringTitle.all },
              ...res.data
            ]
          });
          this.getGoodList();
        }
        if (params.type === "sub") {
          // console.log(`category sub List = ${JSON.stringify(res, null, 4)}`);
          this.setState({
            subCategoryList: [{ idx: 0, categoryName: "선택안함" }, ...res.data]
          });
        }
        if (params.type === "last") {
          // console.log(`category last List = ${JSON.stringify(res, null, 4)}`);
          this.setState({
            lastCategoryList: [
              { idx: 0, categoryName: "선택안함" },
              ...res.data
            ]
          });
        }
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getCategoryParentIdxList({ idx: 0, type: "top" });
  }

  render() {
    const {
      createFlag,
      visible,
      selectData,
      sellerFlag,
      sellerName,
      topCategory,
      topCategoryList,
      subCategory,
      subCategoryList,
      lastCategory,
      lastCategoryList,
      orderBy,
      orderByType,
      searchType,
      list,
      pagination
    } = this.state;

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.rank,
        dataIndex: "rank",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 100,
        title: stringTitle.category,
        dataIndex: "category",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              wordWrap: "break-word",
              width: 100
            }}
          >
            {!data.child
              ? data.categoryName
              : !data.child[0].child
              ? `${data.categoryName} > ${data.child[0].categoryName}`
              : `${data.categoryName} > ${data.child[0].categoryName} >
                ${data.child[0].child[0].categoryName}`}
          </div>
        )
      },
      {
        title: stringTitle.mainImage,
        dataIndex: "mainImage",
        className: "text-center",
        render: (data) =>
          data !== null ? (
            <BasicImage
              image={data.subPhoto}
              src={serverUrl + httpUrl.getImage + data.subPhoto}
              style={{ width: "100px", height: "100px", marginRight: 10 }}
            />
          ) : (
            <BasicImage
              style={{ maxWidth: "100px", maxHeight: "100px", marginRight: 10 }}
            />
          )
      },
      {
        width: 300,
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center pointer",
        render: (data, row) => (
          <div
            className="flex-center"
            onClick={() => {
              this.setState({ visible: true, selectData: row });
            }}
          >
            {data.content}
          </div>
        )
      },
      {
        title: stringTitle.orgPrice,
        dataIndex: "orgPrice",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.price,
        dataIndex: "price",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.point,
        dataIndex: "point",
        className: "text-center",
        render: (data) => <div>{numberFormat(amountDivideFormat(data))}</div>
      },
      {
        title: stringTitle.deliveryCharge,
        dataIndex: "deliveryCharge",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.stock,
        dataIndex: "stock",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        maxWidth: 200,
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center pointer",
        render: (data) => (
          <div
            onClick={() => {
              this.setState({ sellerFlag: true, sellerName: data });
            }}
          >
            {data}
          </div>
        )
      },
      {
        title: stringTitle.deleted,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== row.deleted) {
                this.onDelete(row);
              }
            }}
          />
        )
      },
      {
        title: stringTitle.postFlag,
        dataIndex: "postFlag",
        className: "text-center",
        render: (data, row, index) => (
          <SelectBox
            value={string.postFlagString[data]}
            code={string.toggleCode}
            codeString={string.postFlagString}
            onChange={(value) => {
              if (parseInt(value) !== row.postFlag) {
                this.onPostFlag(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <div>
        {/* 상품 생성 */}
        {createFlag && (
          <GoodsModal
            visible={createFlag}
            onCancel={() => {
              this.setState({ createFlag: false });
            }}
            refresh={this.getGoodList}
          />
        )}
        {/* 기본정보 */}
        {visible && (
          <GoodsDetail
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            detailData={selectData}
            refresh={this.getGoodList}
          />
        )}
        {/* 판매자 정보 */}
        {sellerFlag && (
          <SellerInfo
            visible={sellerFlag}
            sellerName={sellerName}
            onCancel={() => {
              this.setState({ sellerFlag: false });
            }}
          />
        )}
        <div className="title">상품 관리</div>
        {/* 검색조건 */}
        <div className="flex-row flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ createFlag: true });
            }}
          >
            {stringTitle.create}
          </Button>
          <div className="flex-row flex-end flex-center">
            <label className="flex-row flex-center">
              {stringTitle.category}&nbsp;
              <GoodsCategorySelect
                topCategory={topCategory}
                topCategoryList={topCategoryList}
                subCategory={subCategory}
                subCategoryList={subCategoryList}
                lastCategory={lastCategory}
                lastCategoryList={lastCategoryList}
                onChange={this.categoryHandleChange}
              />
            </label>
            <label>
              {stringTitle.orderBy}&nbsp;
              <SelectBox
                style={{ width: "150px", marginRight: 10 }}
                value={string.goodsOrderByString[orderBy]}
                code={string.goodsOrderByCode}
                codeString={string.goodsOrderByString}
                onChange={this.orderByHandleChange}
              />
              <SelectBox
                style={{ width: "150px", marginRight: 10 }}
                value={string.orderByTypeString[orderByType]}
                code={string.orderByTypeCode}
                codeString={string.orderByTypeString}
                onChange={this.orderByTypeHandleChange}
              />
            </label>
            <label>조건&nbsp;</label>
            <Checkbox onChange={this.postHandleChange}>미게시만</Checkbox>
            <Checkbox onChange={this.deletedHandleChange}>
              {stringTitle.deletedInclude}
            </Checkbox>
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.goodsSearchTypeString[searchType]}
              code={string.goodsSearchTypeCode}
              codeString={string.goodsSearchTypeString}
              onChange={this.searchTypeHandleChange}
            />
            <Input
              placeholder="검색어를 입력하세요."
              disabled={searchType === "ALL" ? true : false}
              style={{ marginRight: 10, width: 200 }}
              onChange={(e) => {
                this.searchTextHandelChange(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                this.getGoodList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default GoodsList;
