import React, { Component } from "react";

import { Button, Modal, Input, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { amountDivideFormat, checkData, numberFormat } from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showModifyConfirm } from "../common/AlertInfoModal";

import OrderDeliverTitle from "./OrderDeliverTitle";
import OrderDeliveryModal from "./OrderDeliveryModal";
import OrderStatusTitle from "./OrderStatusTitle";
import OrderGoodsHistoryModal from "./OrderGoodsHistoryModal";

class OrderGoodsModal extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    status: "ALL",
    searchText: "",

    prevRefresh: false,
    deliveryFlag: false,
    historyFlag: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getOrderDeatilList({ pageNum: pager.current });
  };

  // 주문 상태 변경
  onHandleChangeStatus = (value) => {
    this.setState({
      status: value
    });
  };

  // 주문 상태 변경
  goodsOrderStatusUpdate = (data, status) => {
    let updateData = {
      idx: data.idx,
      status: status
    };

    showModifyConfirm(
      ` ${status === "CANCEL" ? stringTitle.moneyReturn : ""} ${
        stringTitle.goodsName
      } - ${data.goodsTitle}의 주문상태를 변경하시겠습니까?`,
      httpUrl.orderGoodsStatusUpdate,
      updateData,
      this.getOrderDeatilList
    );
  };

  getOrderDeatilList = (params = {}) => {
    const { status, searchText } = this.state;
    const { selectedData } = this.props;

    let pageNum = params.pageNum || 1;
    if (params.refresh) {
      pageNum = this.state.pagination.current;
      this.setState({ prevRefresh: true });
    }

    httpGet(
      httpUrl.getOrderDeatilList,
      [10, pageNum, selectedData.idx, status, searchText],
      {}
    )
      .then((res) => {
        // console.log(`order detail= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 취소
  onCancel = () => {
    this.props.onCancel();
    if (this.state.prevRefresh) this.props.refresh({ refresh: true });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getOrderDeatilList();
  }

  render() {
    const { visible } = this.props;
    const {
      deliveryFlag,
      orderData,
      historyFlag,
      orderIdx,
      status,
      list,
      pagination
    } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 200,
        title: stringTitle.goodsName,
        dataIndex: "goodsTitle",
        className: "text-center",
        render: (data) => <div className="text-wordWrap-200">{data}</div>
      },
      {
        title: stringTitle.optionName,
        dataIndex: "optionTitle",
        className: "text-center"
      },
      {
        title: stringTitle.quantity,
        dataIndex: "quantity",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.billingType,
        dataIndex: "billingType",
        className: "text-center",
        render: (data) => <div>{string.billingTypeString[data]}</div>
      },
      {
        title: stringTitle.billingPoint,
        dataIndex: "point",
        className: "text-center",
        render: (data, row) => (
          <div>{numberFormat(amountDivideFormat(data) * row.quantity)}</div>
        )
      },
      {
        title: stringTitle.billingPrice,
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data * row.quantity)}</div>
      },
      {
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: stringTitle.logistics,
        dataIndex: "logistics",
        className: "text-center",
        render: (data, row) => (
          /* 운송장 번호 입력이 가능한 경우 
            주문완료(ORDER_COMPLETED)
            상품준비중(PREPARATION)
            배송중(SHIPPING)
            교환 요청중(REPLACE_REQUESTED) /  교환중 (REPLACE_ING)
          */
          <OrderDeliverTitle
            page="order"
            data={row}
            className={
              row.status === "ORDER_COMPLETED" ||
              row.status === "PREPARATION" ||
              row.status === "SHIPPING" ||
              row.status === "REPLACE_REQUESTED" ||
              row.status === "REPLACE_ING"
                ? "pointer"
                : ""
            }
            disabled={
              row.status === "ORDER_COMPLETED" ||
              row.status === "PREPARATION" ||
              row.status === "SHIPPING" ||
              row.status === "REPLACE_REQUESTED" ||
              row.status === "REPLACE_ING"
                ? false
                : true
            }
            onClick={() => {
              row.status === "ORDER_COMPLETED" ||
              row.status === "PREPARATION" ||
              row.status === "SHIPPING" ||
              row.status === "REPLACE_REQUESTED" ||
              row.status === "REPLACE_ING"
                ? this.setState({ deliveryFlag: true, orderData: row })
                : this.setState({});
            }}
          />
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.orderStatus[data]}</div>
      },
      {
        title: stringTitle.status + stringTitle.modify,
        dataIndex: "",
        className: "text-center",
        render: (data, row) =>
          row.status !== "ORDER_READY" &&
          row.status !== "PREPARATION" &&
          row.status !== "DELIVERY_COMPLETED" &&
          row.status !== "CANCEL_COMPLETED" &&
          row.status !== "RETURN_COMPLETED" &&
          row.status !== "REPLACE_COMPLETED" && (
            <OrderStatusTitle
              status={row.status}
              onClick={() => {
                this.goodsOrderStatusUpdate(row, "NAN");
              }}
            />
          )
      }
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: stringTitle.zipCode,
          dataIndex: "orderAddress.zipCode",
          className: "text-center"
        },
        {
          title: stringTitle.address,
          dataIndex: "orderAddress.address",
          className: "text-center",
          render: (data, row) =>
            row.orderAddress.addressDetail === "" ? (
              data
            ) : (
              <div>
                {data} {row.orderAddress.addressDetail}
              </div>
            )
        },
        {
          title: stringTitle.receiver,
          dataIndex: "orderAddress.receiver",
          className: "text-center"
        },
        {
          title: stringTitle.receiver + stringTitle.mobile,
          dataIndex: "orderAddress.receiverPhone",
          className: "text-center"
        },
        {
          title: stringTitle.memo,
          dataIndex: "orderAddress.memo",
          className: "text-center",
          render: (data) => checkData(data)
        },
        {
          dataIndex: "",
          className: "text-center",
          render: (data, row) => (
            <div className="flex-row flex-center flex-end">
              {row.status !== "CANCEL_REQUESTED" &&
                row.status !== "CANCEL_COMPLETED" &&
                row.status !== "RETURN_COMPLETED" &&
                row.status !== "REPLACE_COMPLETED" &&
                row.status !== "ORDER_CONFIRM" && (
                  /* 주문 취소 불가인 경우 - 취소요청중, 취소완료, 반품완료, 교환완료, 구매확정 */
                  <Button
                    className="m-r-10"
                    onClick={() => {
                      this.goodsOrderStatusUpdate(row, "CANCEL");
                    }}
                  >
                    {stringTitle.order + stringTitle.cancel}
                  </Button>
                )}
              {row.status === "DELIVERY_COMPLETED" && (
                <div className="flex-row">
                  <Button
                    className="m-r-10"
                    onClick={() => {
                      this.goodsOrderStatusUpdate(row, "RETURN");
                    }}
                  >
                    {stringTitle.retrun}
                  </Button>
                  {/* <Button
                    onClick={() => {
                      this.goodsOrderStatusUpdate(row, "REPLACE");
                    }}
                  >
                    {stringTitle.replace}
                  </Button> */}
                </div>
              )}
            </div>
          )
        }
      ];

      const bankColumns = [
        {
          title: stringTitle.bank,
          dataIndex: "bank",
          className: "text-center"
        },
        {
          title: stringTitle.bankAccount,
          dataIndex: "bankAccount",
          className: "text-center"
        }
      ];

      return (
        <div>
          <Button
            className="m-b-10"
            onClick={() => {
              this.setState({ historyFlag: true, orderIdx: record.idx });
            }}
          >
            {stringTitle.order_goods_histroy}
          </Button>
          <Table
            rowKey={(record) => `record: ${record.idx}`}
            columns={dropColumns}
            dataSource={[record]}
            pagination={false}
            style={{ backgroundColor: "#fff" }}
          />
          {record.billingType === "VIRTUAL_ACCOUNT" &&
            record.returnBankInfo !== null && (
              <div style={{ marginTop: 20 }}>
                <b style={{ fontSize: 18 }}>{stringTitle.returnBankInfo}</b>
                <Table
                  rowKey={(record) => `record: ${record.idx}`}
                  columns={bankColumns}
                  dataSource={[record.returnBankInfo]}
                  pagination={false}
                  style={{ backgroundColor: "#fff" }}
                />
              </div>
            )}
        </div>
      );
    };

    return (
      <Modal
        visible={visible}
        title="주문 목록"
        onCancel={this.onCancel}
        style={{ minWidth: "1500px" }}
        maskClosable={false}
        footer={false}
      >
        {/* 운송장 번호 입력 */}
        {deliveryFlag && (
          <OrderDeliveryModal
            visible={deliveryFlag}
            orderData={orderData}
            onCancel={() => {
              this.setState({ deliveryFlag: false });
            }}
            refresh={this.getOrderDeatilList}
          />
        )}
        {/* 주문상태변경 히스토리 */}
        {historyFlag && (
          <OrderGoodsHistoryModal
            visible={historyFlag}
            orderIdx={orderIdx}
            onCancel={() => {
              this.setState({ historyFlag: false });
            }}
          />
        )}
        <div className="flex-row flex-center flex-end m-b-10">
          <label>
            {stringTitle.status}&nbsp;
            <SelectBox
              style={{ width: "150px", marginRight: 10 }}
              value={string.orderGoodsStatusString[status]}
              code={string.orderGoodsStatusString}
              codeString={string.orderGoodsStatusString}
              onChange={this.onHandleChangeStatus}
            />
          </label>
          <Input
            style={{ width: "250px", marginRight: 10 }}
            placeholder="상품명"
            allowClear
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          <Button
            onClick={() => {
              this.getOrderDeatilList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </Modal>
    );
  }
}

export default OrderGoodsModal;
