import { reactLocalStorage } from "reactjs-localstorage";

import { Modal } from "antd";

import stringTitle from "../../stringTitle";

export function showAlert(alertType, refresh, data, type) {
  let title = "";
  if (alertType === "create") title = "생성이 완료되었습니다.";
  if (alertType === "regist") title = "등록이 완료되었습니다.";
  if (alertType === "update") title = "수정이 완료되었습니다.";
  if (alertType === "modify") title = "변경이 완료되었습니다.";
  if (alertType === "delete") title = "삭제가 완료되었습니다.";
  if (alertType === "restore") title = "복구가 완료되었습니다.";
  if (alertType === "add") title = "추가 되었습니다.";
  if (alertType === "charge") title = "충전이 완료되었습니다.";
  if (alertType === "offer") title = "신청이 완료되었습니다.";
  if (alertType === "post") title = "게시 처리가 완료되었습니다.";
  if (alertType === "unPost") title = "미게시 처리가 완료 되었습니다.";

  if (alertType === "UNKNOWN_TYPE")
    title =
      "이미지 형식이 올바르지 않습니다.\njpg, png, bmp 형식의 파일을 올려주세요.";
  if (alertType === "SAVE_FAIL") title = "이미지 저장에 실패하였습니다.";

  if (alertType === "cancel") title = "취소 요청되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ refresh: true });
    }
  });
}

export function showCloseAlert(alertType, refresh, onCancel) {
  let title = "";
  if (alertType === "create") title = "생성이 완료되었습니다.";
  if (alertType === "regist") title = "등록이 완료되었습니다.";
  if (alertType === "update") title = "수정이 완료되었습니다.";
  if (alertType === "modify") title = "변경이 완료되었습니다.";
  if (alertType === "add") title = "추가 되었습니다.";
  if (alertType === "offer") title = "신청이 완료되었습니다.";
  if (alertType === "charge") title = "충전이 완료되었습니다.";
  if (alertType === "level") title = "승격이 완료되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh();
      onCancel();
    }
  });
}

export function showCloseRefreshAlert(alertType, refresh, onCancel) {
  let title = "";
  if (alertType === "create") title = "생성이 완료되었습니다.";
  if (alertType === "regist") title = "등록이 완료되었습니다.";
  if (alertType === "update") title = "수정이 완료되었습니다.";
  if (alertType === "modify") title = "변경이 완료되었습니다.";
  if (alertType === "reward") title = "정산이 완료되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ refresh: true });
      onCancel();
    }
  });
}

export function showIdxAlert(alertType, refresh, idx) {
  let title = "";
  if (alertType === "update") title = "수정이 완료되었습니다.";
  if (alertType === "modify") title = "변경이 완료되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ idx });
    }
  });
}

export function showPageNumAlert(alertType, refresh, pageNum) {
  let title = "";
  if (alertType === "delete") title = "삭제가 완료되었습니다.";
  if (alertType === "restore") title = "복구가 완료되었습니다.";
  if (alertType === "accept") title = "승인처리 되었습니다.";
  if (alertType === "reject") title = "거절처리 되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ pageNum });
    }
  });
}

export function showLoginAlert(alertType, refresh, data, state) {
  let title = "";
  if (alertType === "update") title = "수정이 완료되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      delete data.prePassword;
      refresh({ ...data });
      state();
    }
  });
}

export function showAdminAlert(alertType, refresh, cancel, login, data) {
  let title = "";
  if (alertType === "update") title = "수정이 완료되었습니다.";

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      delete data.password;
      refresh();
      cancel();

      const userInfo = reactLocalStorage.getObject("adminUser");
      if (data.idx === userInfo.idx) {
        let adminData = {
          ...userInfo,
          ...data
        };
        login({ ...adminData });
      }
    }
  });
}
