import React, { Component } from "react";

import { Button, Form, Modal, Icon, Select, Upload } from "antd";

import { httpGet, httpUrl, serverUrl } from "../../api/httpClient";

import { amountDivideFormat, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showOfferConfirm } from "../common/AlertInfoModal";

import UserSearchModal from "../common/UserSearchModal";
import PlatformSelectBox from "../common/PlatformSelectBox";

const FormItem = Form.Item;
const Option = Select.Option;
const PlatformRequestModal = Form.create()(
  class extends Component {
    state = {
      userVisible: false,
      userSelect: {},

      platformList: [],
      platformData: [],
      selectService: {}
    };

    onSumbit = () => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        // console.log(`values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          showOfferConfirm(
            stringTitle.requestQuest,
            httpUrl.platformRequest,
            values,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    onHandelChangeOptionIdx = (value) => {
      let data = this.state.platformData.find((item) => item.idx === value);
      this.setState({ selectService: data });
    };

    onHandelChangePlatformIdx = (value) => {
      this.getPlatformServiceDetail({ idx: value });
    };

    // 플랫폼 상세 불러기오
    getPlatformServiceDetail = (params = {}) => {
      httpGet(httpUrl.getPlatformServiceDetail, [params.idx], {})
        .then((res) => {
          // console.log(`detail = ${JSON.stringify(res.data.options, null, 4)}`);
          this.setState({
            platformData: res.data.options,
            selectService: res.data.options[0]
          });
          this.props.form.setFieldsValue({
            serviceIdx: params.idx,
            optionIdx: res.data.options[0].idx
          });
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    };

    // 플랫폼 서비스 목록
    getPlatformServiceList = (params = {}) => {
      httpGet(httpUrl.getPlatformServiceList, [], {})
        .then((res) => {
          // console.log(`list = ${JSON.stringify(res, null, 4)}`);
          this.setState({
            platformList: res.data
          });
          this.getPlatformServiceDetail({ idx: res.data[0].idx });
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    };

    componentDidMount() {
      this.getPlatformServiceList();
    }

    render() {
      const { userVisible, userSelect, platformList, selectService } =
        this.state;
      const { form, visible, onCancel, refresh } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const uploadProps = {
        action: serverUrl + httpUrl.platformRequestExcel,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (
            global.confirm(`${stringTitle.serviceName}를 신청하시겠습니까?`)
          ) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: `${stringTitle.serviceName} ${stringTitle.result}`,
            content: (
              <div>
                <p>
                  {file.data.failMsg.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 ${stringTitle.serviceName}\n`}
                      {file.data.failMsg.length > 0 &&
                        file.data.failMsg.map((value, index) => (
                          <div key={index}>
                            {value}
                            {index - 1 !== file.data.failMsg.length && <br />}
                          </div>
                        ))}
                    </div>
                  ) : (
                    s
                  )}
                </p>
              </div>
            ),
            onOk() {
              refresh({ refresh: true });
              onCancel();
            },
            okText: "확인"
          });
        },
        onError(err) {
          Modal.error({
            title: "신청 오류",
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <Modal
          visible={visible}
          title="플랫폼 서비스 신청"
          style={{ minWidth: 800 }}
          okText={stringTitle.offer}
          onOk={this.onSumbit}
          cancelText={stringTitle.cancel}
          onCancel={onCancel}
          maskClosable={false}
        >
          {userVisible && (
            <UserSearchModal
              visible={userVisible}
              onCancel={() => this.setState({ userVisible: false })}
              onSelect={(selectData) => {
                // console.log(`user = ${JSON.stringify(selectData, null, 4)}`);
                this.setState({ userSelect: selectData });
                form.setFieldsValue({ userIdx: selectData.idx });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <div
              className="flex-row flex-center justify-center"
              style={{ marginBottom: "10px" }}
            >
              <span style={{ color: "blue" }}>
                * 여러 사람 {stringTitle.offer} 시 파일업로드 사용
              </span>
            </div>
            <div className="flex-row flex-center justify-center m-b-20">
              <a href="/serviceConsultFormat.xlsx" download>
                <Button className="m-r-10">
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  양식 다운로드
                </Button>
              </a>
              <Upload
                {...uploadProps}
                accept=".xlsx, .xls"
                showUploadList={false}
              >
                <Button className="m-r-10">
                  <Icon type="upload" style={{ fontSize: "20px" }} />
                  엑셀 업로드
                </Button>
              </Upload>
            </div>
            <FormItem label={<span>{stringTitle.user}&nbsp;</span>}>
              {getFieldDecorator("userIdx", {
                rules: [
                  {
                    required: true,
                    message: "회원을 선택해주세요!"
                  }
                ]
              })(
                userSelect.idx !== undefined ? (
                  <div>
                    {userSelect.id} / {userSelect.name}
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        this.setState({ userVisible: true });
                      }}
                    >
                      {stringTitle.modify}
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({ userVisible: true });
                    }}
                  >
                    {stringTitle.user + stringTitle.search}
                  </Button>
                )
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.serviceName}&nbsp;</span>}>
              {getFieldDecorator("serviceIdx", {
                rules: [
                  {
                    required: true,
                    message: "플랫폼 서비스를 선택해주세요!"
                  }
                ]
              })(
                <PlatformSelectBox
                  list={platformList}
                  onChange={this.onHandelChangePlatformIdx}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.optionName}&nbsp;</span>}>
              {getFieldDecorator("optionIdx", {
                rules: [
                  {
                    required: true,
                    message: "옵션을 선택해주세요"
                  }
                ]
              })(
                <Select
                  style={{ width: "150px" }}
                  placeholder="옵션을 선택해주세요"
                  onChange={(value) => {
                    this.onHandelChangeOptionIdx(value);
                  }}
                >
                  {this.renderOption()}
                </Select>
              )}
            </FormItem>
            {stringTitle.idx !== undefined && (
              <FormItem label={<span></span>} colon={false}>
                <div>
                  <span>
                    {stringTitle.price} : {numberFormat(selectService.price)}
                  </span>
                  <br />
                  <span>
                    {stringTitle.point} :{" "}
                    {numberFormat(amountDivideFormat(selectService.point))}
                  </span>
                </div>
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }

    renderOption = () => {
      const { platformData } = this.state;
      let result = [];

      for (let i = 0; i < platformData.length; i++) {
        result.push(
          <Option value={platformData[i].idx} key={`${platformData[i]}${i}`}>
            {platformData[i].title.content}
          </Option>
        );
      }

      return result;
    };
  }
);
export default PlatformRequestModal;
