import React, { Component } from "react";

import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "./ErrorAlertModal";

class UserSearchModal extends Component {
  state = {
    list: [],
    pagination: { total: 0, current: 0 },
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getUserSearchList({ pageNum: pager.current });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 회원 정보 리스트
  getUserSearchList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getUserSearchList, [10, pageNum, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getUserSearchList();
  }

  render() {
    const { visible, onCancel, onSelect } = this.props;
    const { list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.id,
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: stringTitle.name,
        dataIndex: "name",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data} ({row.recommendCode})
          </div>
        )
      },
      {
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              onSelect(row);
              onCancel();
            }}
          >
            {stringTitle.select}
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="회원 검색"
        onCancel={onCancel}
        style={{ minWidth: "600px" }}
        maskClosable={false}
        footer={false}
      >
        <div className={"flex-row flex-center flex-end m-b-10"}>
          <Input
            style={{ width: 200, marginRight: 10 }}
            placeholder="아이디 / 이름"
            allowClear
            onChange={(e) => {
              this.searchTextHandelChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getUserSearchList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          onRow={(row) => ({
            // console.log(`row = ${JSON.stringify(row, null, 4)}`);
            onClick: () => {
              onSelect(row);
              onCancel();
            }
          })}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default UserSearchModal;
