import React, { Component } from "react";

import { Button, Descriptions, Modal, Input } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { formatDate, mobileFormat } from "../../util";

import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showUpdateCloseRefreshConfirm } from "../common/AlertInfoModal";

const Ditems = Descriptions.Item;
class SellerInfo extends Component {
  state = {
    sellerData: {},
    isModify: false
  };

  // 값 변경
  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.sellerData);
    data[type] = value;

    this.setState({
      sellerData: data
    });

    // console.log(`data: ${JSON.stringify(data, null, 4)}`);
  };

  // 업데이트
  onUpdate = () => {
    showUpdateCloseRefreshConfirm(
      stringTitle.updateQuest,
      httpUrl.sellerUpdate,
      this.state.sellerData,
      this.props.refresh,
      this.props.onCancel
    );
  };

  // 판매자 정보
  getSellerSearchList = (params = {}) => {
    let searchText = params.searchText;

    httpGet(httpUrl.getSellerSearchList, [10, 1, searchText], {})
      .then((res) => {
        // console.log(`seller = ${JSON.stringify(res.data.list, null, 4)}`);
        this.setState({
          sellerData: res.data.list[0]
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
    if (this.props.page) this.setState({ sellerData: this.props.selectedData });
    else this.getSellerSearchList({ searchText: this.props.sellerName });
  }

  render() {
    const { visible, onCancel, page } = this.props;
    const { sellerData, isModify } = this.state;

    return (
      <Modal
        visible={visible}
        title={<span> 판매자 정보</span>}
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}
        footer={false}
        maskClosable={false}
      >
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Ditems label={stringTitle.createDate} key={sellerData.idx} span={2}>
            {formatDate(sellerData.createDate)}
          </Ditems>
          <Ditems label={stringTitle.businessName}>
            {!isModify ? (
              sellerData.businessName
            ) : (
              <Input
                value={sellerData.businessName}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "businessName");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.businessNum}>
            {!isModify ? (
              sellerData.businessNum
            ) : (
              <Input
                style={{ width: "200px" }}
                value={sellerData.businessNum}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "businessNum");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.owner} span={2}>
            {!isModify ? (
              sellerData.owner
            ) : (
              <Input
                value={sellerData.owner}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "owner");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.address} span={2}>
            {!isModify ? (
              sellerData.locationAddress
            ) : (
              <Input.TextArea
                row={4}
                value={sellerData.locationAddress}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "locationAddress");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.email}>
            {!isModify ? (
              sellerData.email
            ) : (
              <Input
                value={sellerData.email}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "email");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.tel}>
            {!isModify ? (
              mobileFormat(sellerData.tel)
            ) : (
              <Input
                maxLength={10}
                style={{ width: "200px" }}
                value={sellerData.tel}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9]/g, "");
                  this.onChangeModal(value, "tel");
                }}
              />
            )}
          </Ditems>
          <Ditems label={stringTitle.mobNum}>
            {!isModify ? (
              sellerData.mobNum
            ) : (
              <Input
                style={{ width: "200px" }}
                value={sellerData.mobNum}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "mobNum");
                }}
              />
            )}
          </Ditems>
        </Descriptions>
        {page && !isModify && (
          <div className="flex-row m-t-10 flex-end">
            <Button
              onClick={() => {
                this.setState({ isModify: true });
              }}
            >
              {stringTitle.updateFlag}
            </Button>
          </div>
        )}
        {isModify && (
          <div className="flex-row m-t-10 flex-end">
            <Button
              className="m-r-10"
              onClick={() => {
                this.onUpdate();
              }}
            >
              {stringTitle.update}
            </Button>
            <Button
              onClick={() => {
                this.setState({ isModify: false });
              }}
            >
              {stringTitle.cancel}
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

export default SellerInfo;
