import React, { Component } from "react";

import { Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { formatDate } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

class OrderGoodsHistoryModal extends Component {
  state = { list: [] };

  getOrderGoodsHistory = () => {
    httpGet(httpUrl.getOrderGoodsHistory, [this.props.orderIdx], {})
      .then((res) => {
        // console.log(`ordergoods history= ${JSON.stringify(res.data, null, 4)}`);

        this.setState({
          list: res.data
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getOrderGoodsHistory();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list } = this.state;
    const columns = [
      {
        title: stringTitle.requestDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.orderStatus[data]}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title={stringTitle.order_goods_histroy}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        maskClosable={false}
        footer={false}
      >
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.createDate + record.status}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default OrderGoodsHistoryModal;
