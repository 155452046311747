import React, { Component } from "react";

import { Button, Form, Modal, Input, InputNumber } from "antd";

import { httpUrl } from "../../api/httpClient";

import { numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { showAddConfirm } from "../common/AlertInfoModal";

import OptionTags from "./OptionTags";

const FormItem = Form.Item;
const GoodsOptionModal = Form.create()(
  class extends Component {
    state = {
      optionList: []
    };

    //생성시
    onCreate = () => {
      const { optionList } = this.state;

      this.props.onOk(optionList);
      this.props.onCancel();
    };

    // 생성전 옵션들 추가
    optionsAdd = () => {
      const { form, type } = this.props;
      const { optionList } = this.state;

      form.validateFields((err, values) => {
        if (!err) {
          let data = {
            ...values,
            title: {
              idx: 0,
              content: values.title_content
            }
          };
          if (type === "add") data = { ...data, idx: 0 };
          optionList.push(data);
          this.setState({ optionList });
        }
      });
    };

    // 추가 및 수정
    onSubmit = (e) => {
      const { form, selectData, type, refIdx } = this.props;
      const { optionList } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        let idx = 0;
        let titleIdx = 0;
        let data = {};
        if (type === "add") {
          data = {
            refIdx,
            option: optionList
          };
        }
        if (type === "edit") {
          idx = selectData.idx;
          titleIdx = selectData.title.idx;
          data = {
            refIdx,
            option: [
              {
                idx,
                ...values,
                title: {
                  idx: titleIdx,
                  content: values.title_content
                }
              }
            ]
          };
        }

        // console.log(`data = ${JSON.stringify(data, null, 4)}`);
        showAddConfirm(
          `${type === "add" ? stringTitle.addQuest : stringTitle.updateQuest}`,
          httpUrl.goodsOptionUpdate,
          data,
          type,
          this.props.refresh,
          this.props.onCancel
        );
      });
    };

    componentDidMount() {
      // console.log(JSON.stringify(this.props, null, 4));
      const { selectData, form, optionInfo } = this.props;

      if (selectData.idx !== undefined) {
        form.setFieldsValue({
          point: selectData.point,
          price: selectData.price,
          quantity: selectData.quantity,
          title_content: selectData.title_content,
          rank: selectData.rank
        });
      }
      if (optionInfo !== undefined && optionInfo.length !== 0)
        this.setState({ optionList: optionInfo });
    }

    render() {
      const { visible, form, onCancel, type, paymentInfo } = this.props;
      const { getFieldDecorator } = form;
      const { optionList } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={
            <span>
              {stringTitle.option}{" "}
              {type === "create"
                ? stringTitle.create
                : type === "add"
                ? stringTitle.add
                : stringTitle.update}
            </span>
          }
          okText={stringTitle.check}
          cancelText={stringTitle.cancel}
          onOk={type === "create" ? this.onCreate : this.onSubmit}
          onCancel={onCancel}
          style={{ minWidth: "600px" }}
          maskClosable={false}
        >
          <Form {...formItemLayout} onSubmit={this.onSubmit}>
            <FormItem label={<span>&nbsp;</span>} colon={false}>
              <b>{stringTitle.payment} : </b>
              {numberFormat(paymentInfo.payment)} &nbsp;&nbsp;
              <b>{stringTitle.price} : </b> {numberFormat(paymentInfo.price)}
              &nbsp;&nbsp;
              <b>{stringTitle.point} : </b> {numberFormat(paymentInfo.point)}
            </FormItem>
            <FormItem label={<span>{stringTitle.optionName}&nbsp;</span>}>
              {getFieldDecorator("title_content", {
                rules: [
                  {
                    required: true,
                    message: "옵션명 입력하세요."
                  }
                ]
              })(<Input style={{ width: 300 }} />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.price}&nbsp;</span>}>
              {getFieldDecorator("price", {
                rules: [
                  {
                    required: true,
                    message: "현금을 입력하세요."
                  }
                ]
              })(
                <InputNumber
                  style={{ width: "300px" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.point}&nbsp;</span>}>
              {getFieldDecorator("point", {
                rules: [
                  {
                    required: true,
                    message: "포인트를 입력하세요."
                  }
                ]
              })(
                <InputNumber
                  style={{ width: "300px" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.stock}&nbsp;</span>}>
              {getFieldDecorator("quantity", {
                rules: [
                  {
                    required: true,
                    message: "재고을 입력하세요."
                  }
                ]
              })(
                <InputNumber
                  style={{ width: "300px" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.rank}&nbsp;</span>}>
              {getFieldDecorator("rank", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <InputNumber
                  style={{ width: "300px" }}
                  // value={selectData.price}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              )}
            </FormItem>
            {type !== "edit" && (
              <FormItem label={<span>&nbsp;</span>} colon={false}>
                <Button
                  onClick={() => {
                    this.optionsAdd();
                  }}
                >
                  {stringTitle.add}
                </Button>
              </FormItem>
            )}
            {optionList.length !== 0 && (
              <FormItem label={<span>&nbsp;</span>} colon={false}>
                <OptionTags list={optionList} />
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default GoodsOptionModal;
