import React, { Component } from "react";
import { Tag } from "antd";

import stringTitle from "../../stringTitle";
import { numberFormat } from "../../util";

class OptionTags extends Component {
  render() {
    const { list } = this.props;
    return list.map((item, index) => (
      <Tag
        key={index}
        closable
        onClose={() => {
          list.splice(index, 1);
        }}
      >
        {stringTitle.optionName}&nbsp;:&nbsp;{item.title_content}
        &nbsp;/&nbsp;{stringTitle.add}&nbsp;{stringTitle.payment}(
        {stringTitle.price}&nbsp;+&nbsp;{stringTitle.point})&nbsp;:&nbsp;
        {numberFormat(item.price + item.point)}&nbsp;/&nbsp;
        {stringTitle.stock}&nbsp;:&nbsp;{numberFormat(item.quantity)}
      </Tag>
    ));
  }
}

export default OptionTags;
