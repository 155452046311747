import React, { Component } from "react";
import { Icon, InputNumber } from "antd";

import { httpUrl, serverUrl } from "../../api/httpClient";
import BasicImage from "../common/BasicImage";
import stringTitle from "../../stringTitle";

class GoodsImage extends Component {
  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const { index, item, list, type, onDeleteImage, onChangeRankImage } =
      this.props;
    return (
      <div key={index}>
        <div className="flex-row flex-center m-b-10">
          <BasicImage
            image={item.photo}
            src={serverUrl + httpUrl.getImage + item.photo}
            style={{ width: "150px", height: "150px" }}
          />
          <Icon
            type="delete"
            style={{ padding: 5 }}
            onClick={() => {
              onDeleteImage(item, index, list, type);
            }}
          />
        </div>
        <label>
          {stringTitle.rank} :&nbsp;
          <InputNumber
            min={0}
            value={item.rank}
            onChange={(value) => {
              onChangeRankImage(value, index, list, type);
            }}
          />
        </label>
      </div>
    );
  }
}

export default GoodsImage;
