import React, { Component } from "react";
import { Button } from "antd";

import { checkData, checkLogisticsData } from "../../util";
import stringTitle from "../../stringTitle";

class OrderDeliverTitle extends Component {
  render() {
    const { page, onClick, disabled, data } = this.props;
    return data.logistics === null && data.invoice === null ? (
      page === "user" ? (
        "-"
      ) : (
        <Button onClick={onClick} disabled={disabled}>
          {stringTitle.invoice}
        </Button>
      )
    ) : (
      <div {...this.props} onClick={onClick}>
        {checkLogisticsData(data.logistics)} <br /> {checkData(data.invoice)}
      </div>
    );
  }
}

export default OrderDeliverTitle;
