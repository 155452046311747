import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Form, Modal, Icon, InputNumber, Upload } from "antd";

import { httpUrl, serverUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import { amountMultiFormat } from "../../util";
import { showChagreConfirm } from "../common/AlertInfoModal";

import UserSearchModal from "../common/UserSearchModal";
import PointSelectBox from "../common/PointSelectBox";

const FormItem = Form.Item;
const PointChargeModal = Form.create()(
  class extends Component {
    state = {
      userVisible: false,
      userSelect: {}
    };

    onSumbit = (e) => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          let data = {
            ...values,
            amount: amountMultiFormat(values.amount)
          };
          // console.log(`data = ${JSON.stringify(data, null, 4)}`);

          showChagreConfirm(
            stringTitle.chargeQuest,
            httpUrl.pointCharge,
            data,
            this.props.refresh,
            this.props.onCancel
          );
        }
      });
    };

    componentDidMount() {
      const pointList = reactLocalStorage.getObject("pointList");
      this.props.form.setFieldsValue({
        pointType: pointList[0].pointType,
        amount: 0
      });
    }

    render() {
      const pointList = reactLocalStorage.getObject("pointList");
      const { userVisible, userSelect } = this.state;
      const { form, visible, onCancel, refresh } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const uploadProps = {
        action: serverUrl + httpUrl.pointChargeExcel,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (global.confirm("포인트 배포를 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: `${stringTitle.point} ${stringTitle.charge} ${stringTitle.result}`,
            content: (
              <div>
                <p>
                  {file.data.failMsg.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 ${stringTitle.point} ${stringTitle.charge}\n`}
                      {file.data.failMsg.length > 0 &&
                        file.data.failMsg.map((value, index) => (
                          <div key={index}>
                            {value}
                            {index - 1 !== file.data.failMsg.length && <br />}
                          </div>
                        ))}
                    </div>
                  ) : (
                    s
                  )}
                </p>
              </div>
            ),
            onOk() {
              refresh({ refresh: true });
              onCancel();
            },
            okText: "확인"
          });
        },
        onError(err) {
          Modal.error({
            title: `${stringTitle.point} ${stringTitle.result}`,
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <Modal
          visible={visible}
          title="포인트 충전"
          onCancel={onCancel}
          onOk={this.onSumbit}
          okText={stringTitle.charge}
          cancelText={stringTitle.cancel}
          style={{ minWidth: "800px" }}
          maskClosable={false}
        >
          {userVisible && (
            <UserSearchModal
              visible={userVisible}
              onCancel={() => this.setState({ userVisible: false })}
              onSelect={(selectData) => {
                // console.log(`user = ${JSON.stringify(selectData, null, 4)}`);
                this.setState({ userSelect: selectData });
                form.setFieldsValue({ userIdx: selectData.idx });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <div
              className="flex-row flex-center justify-center"
              style={{ marginBottom: "10px" }}
            >
              <span style={{ color: "blue" }}>
                * 여러 사람에게 {stringTitle.point} {stringTitle.charge} 시
                파일업로드 사용
              </span>
            </div>
            <div className="flex-row flex-center justify-center m-b-20">
              <a href="/airdropFormat.xlsx" download>
                <Button className="m-r-10">
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  양식 다운로드
                </Button>
              </a>
              <Upload
                {...uploadProps}
                accept=".xlsx, .xls"
                showUploadList={false}
              >
                <Button className="m-r-10">
                  <Icon type="upload" style={{ fontSize: "20px" }} />
                  엑셀 업로드
                </Button>
              </Upload>
            </div>
            <FormItem label={<span>{stringTitle.point}&nbsp;</span>}>
              {getFieldDecorator("pointType", {
                rules: [
                  {
                    required: true,
                    message: "포인트를 선택해주세요!"
                  }
                ]
              })(
                <PointSelectBox
                  disabled={true}
                  pointList={pointList}
                  placeholder="선택하세요"
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.user}&nbsp;</span>}>
              {getFieldDecorator("userIdx", {
                rules: [
                  {
                    required: true,
                    message: "회원을 선택해주세요!"
                  }
                ]
              })(
                userSelect.idx !== undefined ? (
                  <div>
                    {userSelect.id} / {userSelect.name}
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        this.setState({ userVisible: true });
                      }}
                    >
                      {stringTitle.modify}
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({ userVisible: true });
                    }}
                  >
                    {stringTitle.user + stringTitle.search}
                  </Button>
                )
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.amount}&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "수량을 입력해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      cb(form.setFieldsValue({ amount: parseInt(value) }));
                    }
                  }
                ]
              })(<InputNumber min={0} allowClear style={{ width: "200px" }} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default PointChargeModal;
