import React, { Component } from "react";
import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { checkData, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";

class BannerRefSearchModal extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getBannerRefList({ pageNum: pager.current });
  };

  // 배너 목록 조회
  getBannerRefList = (params = {}) => {
    const { type } = this.props;
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getBannerRefList, [10, pageNum, type, searchText], {})
      .then((res) => {
        // console.log(`banner Ref data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getBannerRefList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data) => checkData(data)
      },
      {
        title: stringTitle.seller,
        dataIndex: "sellerName",
        className: "text-center",
        render: (data, row) => <div>{row.type === "GOODS" ? data : "-"}</div>
      },
      {
        dataIndex: "select",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              onCancel();
              this.props.onOk(row);
            }}
          >
            {stringTitle.select}
          </Button>
        )
      }
    ];

    return (
      <Modal
        style={{ minWidth: "800px" }}
        visible={visible}
        title="목록"
        cancelText={stringTitle.cancel}
        onCancel={onCancel}
        footer={false}
      >
        <div className="flex-row flex-center flex-end m-b-10">
          <Input
            placeholder="검색어를 입력하세요."
            style={{ marginRight: 10, width: 250 }}
            onChange={(e) => {
              this.setState({
                searchText: e.target.value
              });
            }}
          />
          <Button
            onClick={() => {
              this.getBannerRefList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default BannerRefSearchModal;
