import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Checkbox, Input, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { formatDate, mobileFormat, numberFormat } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showDeleteIdxPageNumConfirm } from "../../components/common/AlertInfoModal";

import SellerModal from "../../components/seller/SellerModal";
import SellerInfo from "../../components/seller/SellerInfo";

class SellerList extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    deleted: false,
    sellerSearchType: "ALL",
    searchText: "",

    selectedData: {}
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getSellerList({ pageNum: pager.current });
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`value = ${JSON.stringify(value)}`);
    this.setState({
      sellerSearchType: value
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getSellerList();
      }
    );
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked
    });
  };

  // 삭제 및 복구
  onDelete = (row) => {
    const { deleted, pagination, list } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    showDeleteIdxPageNumConfirm(
      `${row.deleted ? stringTitle.restore : stringTitle.delete}하시겠습니까?`,
      httpUrl.sellerDelete,
      row.idx,
      this.getSellerList,
      pageNum
    );
  };

  getSellerList = (params = {}) => {
    const { deleted, sellerSearchType, searchText } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getSellerList,
      [10, pageNum, deleted, sellerSearchType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`sellerList = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getSellerList();
  }

  render() {
    const userInfo = reactLocalStorage.getObject("adminUser");
    const {
      createFlag,
      updateFlag,
      selectedData,
      sellerSearchType,
      list,
      pagination
    } = this.state;
    const columns = [
      {
        width: 100,
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.businessName,
        dataIndex: "businessName",
        className: "text-center",
        render: (data, row) => (
          <div
            className={userInfo.superAdmin === 1 ? "pointer" : ""}
            onClick={() => {
              userInfo.superAdmin === 1
                ? this.setState({
                    updateFlag: true,
                    selectedData: row
                  })
                : this.setState({});
            }}
          >
            {data}
          </div>
        )
      },
      {
        title: stringTitle.owner,
        dataIndex: "owner",
        className: "text-center"
      },
      {
        title: stringTitle.businessNum,
        dataIndex: "businessNum",
        className: "text-center"
      },
      {
        width: 250,
        title: stringTitle.address,
        dataIndex: "locationAddress",
        className: "text-center",
        render: (data) => <div className="text-wordWrap">{data}</div>
      },
      {
        title: stringTitle.mobNum,
        dataIndex: "mobNum",
        className: "text-center"
      },
      {
        title: stringTitle.email,
        dataIndex: "email",
        className: "text-center",
        render: (data) => <div>{data}</div>
      },
      {
        title: stringTitle.tel,
        dataIndex: "tel",
        className: "text-center",
        render: (data) => <div>{mobileFormat(data)}</div>
      },
      {
        title: stringTitle.deleted,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) =>
          userInfo.superAdmin === 1 ? (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          ) : (
            string.deleteString[data]
          )
      }
    ];

    return (
      <div>
        {/* 판매자 생성 */}
        {createFlag && (
          <SellerModal
            visible={createFlag}
            onCancel={() => {
              this.setState({ createFlag: false });
            }}
            refresh={this.getSellerList}
          />
        )}
        {/* 판매자 정보 수정 */}
        {updateFlag && (
          <SellerInfo
            page="seller"
            visible={updateFlag}
            onCancel={() => {
              this.setState({ updateFlag: false });
            }}
            refresh={this.getSellerList}
            selectedData={selectedData}
          />
        )}
        <div className="title">판매자 관리</div>
        <div
          className={
            userInfo.superAdmin === 1
              ? "flex-row flex-center flex-sb"
              : "flex-row flex-center flex-end"
          }
        >
          {userInfo.superAdmin === 1 && (
            <Button
              onClick={() => {
                this.setState({ createFlag: true });
              }}
            >
              {stringTitle.create}
            </Button>
          )}
          <div>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              {stringTitle.searchRequire}&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>
                {stringTitle.deletedInclude}
              </Checkbox>
            </label>
            <SelectBox
              style={{ width: "160px", marginRight: 10 }}
              value={string.sellerSearchTypeString[sellerSearchType]}
              code={string.sellerSearchTypeCode}
              codeString={string.sellerSearchTypeString}
              onChange={this.searchTypeHandleChange}
            />
            <Input
              placeholder="검색어를 입력하세요."
              disabled={sellerSearchType === "ALL" ? true : false}
              style={{ marginRight: 10, width: 250 }}
              onChange={(e) => {
                this.setState({
                  searchText: e.target.value
                });
              }}
            />
            <Button
              onClick={() => {
                this.getSellerList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both", marginBottom: 10 }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default SellerList;
