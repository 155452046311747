import React, { Component } from "react";

import { Button, Modal, Input, Table, Tag } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "./ErrorAlertModal";
import { showAddPostConfirm, showDeletePageNumConfirm } from "./AlertInfoModal";

const Search = Input.Search;
class SellerSearchModal extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    searchText: "",

    sellerList: []
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const { page } = this.props;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    if (page === "admin")
      this.getAdminIdxSellerList({ pageNum: pager.current });
    else this.getSellerSearchList({ pageNum: pager.current });
  };

  // 검색
  onSearch = (value) => {
    const { page } = this.props;

    this.setState(
      {
        searchText: value
      },
      () => {
        if (page === "admin") this.getAdminIdxSellerList();
        else this.getSellerSearchList();
      }
    );
  };

  // 어드민 판매자 삭제
  onDelete = (data) => {
    const { list, pagination } = this.state;

    let pageNum =
      list.length === 1
        ? pagination.current === 1
          ? pagination.current
          : pagination.current - 1
        : pagination.current;

    let body = {
      sellerIdx: data.idx,
      adminUserIdx: this.props.adminIdx
    };
    showDeletePageNumConfirm(
      `${data.businessName}을 ${stringTitle.deleteQuest}`,
      httpUrl.adminSellerDelete,
      body,
      this.getAdminIdxSellerList,
      pageNum
    );
  };

  // 어드민에서 판매자 추가하는 경우
  onAdminSellerAdd = () => {
    let sellerIdxs = [];
    this.state.sellerList.forEach((item) => {
      sellerIdxs.push(item.idx);
    });

    let data = {
      adminUserIdx: this.props.adminIdx,
      sellerIdxs: sellerIdxs
    };
    // console.log(`body = ${JSON.stringify(body, null, 4)}`);

    showAddPostConfirm(
      `${stringTitle.seller}를 ${stringTitle.addQuest}`,
      httpUrl.adminSellerCreate,
      data,
      "add",
      this.props.refresh,
      this.props.onCancel
    );
  };

  // 어드민 생성 시 판매자 선택
  onSellerAdd = (row) => {
    let arr = [...this.state.sellerList];

    arr.push(row);
    this.setState({ sellerList: arr });
    // console.log(`sellerList = ${JSON.stringify(arr, null, 4)}`);
  };

  // 어드민에서 판매자 선택 경우
  getAdminIdxSellerList = (params = {}) => {
    const { adminIdx, reverseFlag } = this.props;
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getAdminIdxSellerList,
      [10, pageNum, adminIdx, false, "NAME", reverseFlag, searchText],
      {}
    )
      .then((res) => {
        // console.log(`admin seller List = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  // 상품페이지에서 판매자 선택인 경우 || 주문관리에서 판매자 선택하는 경우
  getSellerSearchList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getSellerSearchList, [10, pageNum, searchText], {})
      .then((res) => {
        // console.log(`sellerList = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    const { page } = this.props;

    if (page === "admin") this.getAdminIdxSellerList();
    else this.getSellerSearchList();
  }

  render() {
    const { visible, onCancel, page, type, adminIdx } = this.props;
    const { list, pagination, sellerFlag, sellerList } = this.state;

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.businessName,
        dataIndex: "businessName",
        className: "text-center"
      },
      {
        title: stringTitle.owner,
        dataIndex: "owner",
        className: "text-center"
      },
      {
        title: "",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              if (page === "goods" || page === "order") {
                this.props.onOk(row);
                onCancel();
              } else {
                if (type) {
                  this.onSellerAdd(row);
                } else this.onDelete(row);
              }
            }}
          >
            {type || page === "goods" || page === "order"
              ? stringTitle.select
              : stringTitle.delete}
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="판매자 목록"
        footer={false}
        maskClosable={false}
        style={{ minWidth: 600 }}
      >
        {sellerFlag && (
          <SellerSearchModal
            page="admin"
            type="add"
            visible={sellerFlag}
            adminIdx={adminIdx}
            reverseFlag={true}
            onCancel={() => {
              this.setState({ sellerFlag: false });
            }}
            refresh={this.getAdminIdxSellerList}
          />
        )}
        <div
          className={
            page === "admin"
              ? "flex-row flex-center flex-sb m-b-10"
              : "flex-row flex-center flex-end m-b-10"
          }
        >
          {page === "admin" && (
            <Button
              onClick={() => {
                if (type === "create") {
                  this.props.onOk(sellerList);
                  onCancel();
                }
                if (type === "add") {
                  this.onAdminSellerAdd();
                } else this.setState({ sellerFlag: true });
              }}
            >
              {stringTitle.add}
            </Button>
          )}
          <Search
            placeholder="사업자명"
            enterButton={stringTitle.search}
            allowClear
            onSearch={this.onSearch}
            style={{ width: "250px" }}
          />
        </div>
        <div className="m-b-10">
          {sellerList.map((item, index) => (
            <Tag
              key={index}
              closable
              onClose={() => {
                sellerList.splice(index, 1);
                // this.setState({ sellerList });
              }}
            >
              {stringTitle.businessName} : {item.businessName} /{" "}
              {stringTitle.owner} :{item.owner}
            </Tag>
          ))}
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default SellerSearchModal;
