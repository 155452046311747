import { Modal } from "antd";
import stringTitle from "../../stringTitle";

export function ShowErrorAlert(errorType) {
  // console.log(`errorType = ${JSON.stringify(errorType, null, 4)}`);
  let title = errorType;

  if (errorType === "E10001") title = "일반적인 오류";
  if (errorType === "E10002") title = "파라미터 값 오류";
  if (errorType === "E10003") title = "권한 오류";
  if (errorType === "E10004" || errorType === "COMMON_MAINNET_DISCONNECT")
    title = "서버 오류입니다.";

  if (errorType === "E20001") title = "상태 변경 불가";
  if (errorType === "E20002" || errorType === "CATEGORY_DELETED")
    title = "카테고리가 존재하지 않습니다.";
  if (errorType === "E20003") title = "상품이 삭제되어있습니다.";
  if (errorType === "E20004") title = "판매자가 삭제되어있습니다.";
  if (errorType === "E20005") title = "옵션이 삭제되어있습니다.";
  if (errorType === "E20006" || errorType === "INSUFFICIENT_INFO")
    title = "필수표기정보수 부족합니다.";
  if (errorType === "E20007" || errorType === "INSUFFICIENT_IMAGE")
    title = "이미지수 부족합니다.";

  if (errorType === "E30001" || errorType === "INSUFFICIENT_POINT")
    title = "포인트 잔액 부족합니다.";
  if (errorType === "E30002" || errorType === "INSUFFICIENT_POINT_REC")
    title = "추천인 포인트 잔액 부족합니다.";

  if (errorType === "E40001" || errorType === "PAYMENT_CANCEL_FAIL")
    title = "결제 취소 실패";

  if (errorType === "E60002" || errorType === "WRONG_PASSWORD")
    title = "비밀번호가 틀렸습니다.";
  if (errorType === "E60003" || errorType === "SAME_PASSWORD")
    title = "이전 비밀번호와 같습니다. 다시 입력해주세요";
  if (errorType === "E60004" || errorType === "DUPLICATE")
    title = "중복된 데이터 존재합니다.";

  // Login
  if (errorType === "SYSTEM_ERROR")
    title = "서버 오류입니다. 새로고침해주세요.";
  if (errorType === "WRONG_IPADDRESS") title = "접근이 제한된 IP주소 입니다.";
  if (errorType === "INVALID_LOGIN_INFO")
    title = "아이디나 비밀번호가 잘못되었습니다.";
  if (errorType === "WRONG_OTP")
    title = "OPT를 잘못입력하셨습니다. 다시 입력해주세요.";

  if (errorType.includes("500")) title = "500 ERROR";
  if (errorType.includes("400")) title = "400 ERROR";

  Modal.info({
    title: title,
    okText: stringTitle.ok,
    onOk() {}
  });
}

export function ShowErrorUploadAlert(content) {
  Modal.error({
    title: "파일 업로드 실패",
    content: content
  });
}
