import React, { Component } from "react";

import { Table, Button, Input, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";

import {
  endDateFormat,
  formatDate,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";

import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

import UserInfo from "../../components/user/UserInfo";
import UserDetail from "../../components/user/UserDetail";
import RecommendLevelUpModal from "../../components/recommend/RecommendLevelUpModal";

const { RangePicker } = DatePicker;
class RecommendLevelUpLog extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: { current: 0, total: 0 },
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      searchText: "",

      userFlag: false,
      levelUpFlag: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getRecommendLevelupLog({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  getRecommendLevelupLog = (params = {}) => {
    const { startDate, endDate, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getRecommendLevelupLog,
      [10, pageNum, startDate, endDate, searchText],
      {}
    )
      .then((res) => {
        // console.log(`res -> ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({ list: res.data.list, pagination });
      })
      .catch((error) => {
        ShowErrorAlert(error.message);
      });
  };

  componentDidMount() {
    this.getRecommendLevelupLog();
  }

  render() {
    const {
      userFlag,
      userData,
      userIdx,
      levelUpFlag,
      startDate,
      endDate,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";
    const columns = [
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.user,
        dataIndex: "user.id",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            className="flex-row flex-center justify-center"
            pointer="pointer"
            type="user"
            userData={row.user}
            onClick={() => {
              this.setState({
                userIdx: row.user.idx,
                userData: row,
                userFlag: true
              });
            }}
          />
        )
      },
      {
        title: stringTitle.recommender,
        dataIndex: "recommender.recommendCode",
        className: "text-center",
        render: (data, row) =>
          row.recommender.name === null ? (
            data
          ) : (
            <UserInfo userData={row.recommender} />
          )
      },
      {
        title: stringTitle.manager,
        dataIndex: "manager.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.manager} />
      },
      {
        title: stringTitle.franchise,
        dataIndex: "franchise.id",
        className: "text-center",
        render: (data, row) => <UserInfo userData={row.franchise} />
      }
    ];

    return (
      <div>
        {/* 회원정보 */}
        {userFlag && (
          <UserDetail
            visible={userFlag}
            userIdx={userIdx}
            userData={userData}
            onCancel={() => {
              this.setState({
                userFlag: false
              });
            }}
          />
        )}
        {/* 승격 */}
        {levelUpFlag && (
          <RecommendLevelUpModal
            visible={levelUpFlag}
            onCancel={() => {
              this.setState({ levelUpFlag: false });
            }}
            refresh={this.getRecommendLevelupLog}
          />
        )}
        <div className="title">대리점 / 총판 승격 관리</div>
        <div className="flex-row flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ levelUpFlag: true });
            }}
          >
            {stringTitle.levelUp}
          </Button>
          <div>
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            <Input
              style={{ width: "250px", marginRight: 10 }}
              placeholder={stringTitle.user}
              allowClear
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
            />
            <Button
              onClick={() => {
                this.getRecommendLevelupLog();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.user.idx + record.createDate}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default RecommendLevelUpLog;
